import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignManagement from "../../modules/pages/campaign-management-new";
import {
  CampaignsPortfoliosListAction,
  GetCampaignConfigAction,
  GetProductsListAction,
  CampaignListAction,
  CreateCampaignAction,
  CreateCampaignStrategyAction,
  GetProductTargetingAction,
  StrategiesListAction,
  fakeActionCampaignsManagementAnalytics,
  CreateCampaignTargetingSuggestionsAction,
  GetKeywordsTargetingAction,
  GetBidSuggestionsAction,
  DeleteStrategiesAction,
  UpdateStrategiesAction,
  DeleteCampaignAction,
  UpdateCampaignAction,
  GetAdGroupListAction,
  UpdateAdGroupAction,
} from "../../redux/modules/campaign-management/index.action";
import {
  GetMarketplaceListAction,
  fakeActionMarketplace,
} from "../../redux/modules/marketplace-credentials/index.action";

const mapStateToProps = (state) => ({
  GetPortfoliosListResponse:
    state?.CampaignManagement.GetPortfoliosListResponse,
  GetCampaignConfigResponse:
    state?.CampaignManagement.GetCampaignConfigResponse,
  GetProductsListResponse: state?.CampaignManagement.GetProductsListResponse,
  CreateCampaignsResponse: state?.CampaignManagement.CreateCampaignsResponse,
  ListCampaignsResponse: state?.CampaignManagement.ListCampaignsResponse,
  ListStrategiesResponse: state?.CampaignManagement.ListStrategiesResponse,
  CreateCampaignsStrategyResponse:
    state?.CampaignManagement.CreateCampaignsStrategyResponse,
  GetProductTargetingResponse:
    state?.CampaignManagement.GetProductTargetingResponse,
  CreateCampaignsTargetionSuggestionResponse:
    state?.CampaignManagement.CreateCampaignsTargetionSuggestionResponse,
  GetKeywordsTargetingResponse:
    state?.CampaignManagement.GetKeywordsTargetingResponse,
  GetBidSuggestionResponse: state?.CampaignManagement.GetBidSuggestionResponse,
  CampaignsStrategiesUpdateResponse:
    state?.CampaignManagement.CampaignsStrategiesUpdateResponse,
  CampaignsStrategiesDeleteResponse:
    state?.CampaignManagement.CampaignsStrategiesDeleteResponse,
  CampaignsUpdateResponse: state?.CampaignManagement.CampaignsUpdateResponse,
  CampaignsDeleteResponse: state?.CampaignManagement.CampaignsDeleteResponse,
  UpdateAdGroupResponse: state?.CampaignManagement.UpdateAdGroupResponse,

  GetMarketplaceListResponse:
    state?.MarketplaceCredential.GetMarketplaceListResponse,

  GetAdGroupListResponse: state?.CampaignManagement.GetAdGroupListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CampaignsPortfoliosListAction,
      GetCampaignConfigAction,
      GetProductsListAction,
      CreateCampaignAction,
      CampaignListAction,
      CreateCampaignStrategyAction,
      StrategiesListAction,
      GetProductTargetingAction,
      CreateCampaignTargetingSuggestionsAction,
      fakeActionCampaignsManagementAnalytics,
      DeleteStrategiesAction,
      UpdateStrategiesAction,
      UpdateCampaignAction,
      GetAdGroupListAction,
      UpdateAdGroupAction,

      GetKeywordsTargetingAction,
      DeleteCampaignAction,
      GetBidSuggestionsAction,

      // marketplace
      GetMarketplaceListAction,
      fakeActionMarketplace,
    },
    dispatch
  );

const CampaignManagement_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignManagement);

export default CampaignManagement_;
