import React from "react";
import { Tooltip } from "antd";

function NegativeKeywords() {
  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">
                      Add Negative Keywords Targeting
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body py-2">
                    <div className="mt-5 mb-5">
                      <div className>
                        <div className=" d-flex gap-5">
                          <div className="d-flex align-items-center me-5">
                            <label htmlFor className="fw-bolder fs-6">
                              Match type
                            </label>
                            <Tooltip
                              placement="top"
                              title={
                                "Negative keyword match types allow you to fine-tune which customer search terms trigger your ads."
                              }
                              arrow={true}
                            >
                              <i className="ki-outline ki-information fs-3 ms-2 text-dark" />
                            </Tooltip>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue
                              name="flexrdi"
                              id="flexCheckDefault"
                              defaultChecked
                            />
                            <label
                              className="form-check-label fw-bold text-dark"
                              htmlFor="flexCheckDefault"
                            >
                              Negative Exact
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue
                              name="flexrdi"
                              id="flexCheckDefault1"
                            />
                            <label
                              className="form-check-label fw-bold text-dark"
                              htmlFor="flexCheckDefault1"
                            >
                              Negative Phrase
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className>
                      <textarea
                        className="form-control fs-7"
                        name
                        id
                        cols={30}
                        rows={10}
                        placeholder="Enter your list and separate each item with a new line."
                        defaultValue={""}
                      />
                      <div className="mt-3 text-end">
                        <button className="btn btn-primary fs-7">
                          Add Keywords
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">
                      Negative Keywords Targeting
                    </h3>
                    <div className="card-toolbar">
                      <a className="btn fs-7 btn-light-danger fw-bold btn-sm">
                        Remove All
                      </a>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className>Keywords</th>
                            <th className>
                              <div className="d-flex align-items-center">
                                Match Type
                                <Tooltip
                                  placement="top"
                                  title={
                                    "Negative keyword match types allow you to fine-tune which customer search terms trigger your ads."
                                  }
                                  arrow={true}
                                >
                                  <i className="ki-outline ki-information fs-4 ms-2 text-dark" />
                                </Tooltip>
                              </div>
                            </th>
                            <th className=" ">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className="fw-bolder">Keywords 1</span>
                            </td>
                            <td>Negative Exact</td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bolder">Keywords 2</span>
                            </td>
                            <td>Negative Phrase</td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-bolder">Keywords 3</span>
                            </td>
                            <td>Negative Phrase</td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NegativeKeywords;
