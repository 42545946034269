import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { adminMenus, userMenus } from "./lib/menus";
import { GlobalContext } from "../../../commonContext";

function Sidebar() {
  const location = useLocation();
  const contextVar = useContext(GlobalContext);
  const user_ = contextVar?.data?.profile;
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const [settingToggle, setSettingToggle] = useState(false);
  const [applicationToggle, setApplicationToggle] = useState(false);

  useEffect(() => {
    const splitLoca = location.pathname.split("/");
    if (splitLoca[1]) {
      if (splitLoca.length > 2) {
        setActiveMenu(splitLoca[2]);
      } else {
        setActiveMenu(splitLoca[1]);
      }
    } else {
      setActiveMenu("dashboard");
    }
  }, [location]);
  return (
    <div
      id="kt_app_sidebar"
      className="app-sidebar flex-column"
      data-kt-drawer="true"
      data-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="250px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
      }}
    >
      <div
        className="app-sidebar-header d-none d-lg-flex px-6 pt-8 pb-4"
        id="kt_app_sidebar_header"
      >
        <button
          type="button"
          data-kt-element="selected"
          className="btn btn-outline btn-custom btn-flex w-100"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-offset="0px, -1px"
        >
          <Link to="/">
            <img alt="Logo" src="/assets/media/vfads.png" className="h-50px" />
          </Link>
        </button>
      </div>
      <div
        className="app-sidebar-navs flex-column-fluid py-6"
        id="kt_app_sidebar_navs"
        style={{
          height: "calc(100% - 320px)",
          // minHeight: "250px",
          // display: "flex",
          justifyContent: "center",
          padding: "0 1.5rem",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          id="kt_app_sidebar_navs_wrappers"
          className="hover-scroll-y my-2"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_sidebar_header, #kt_app_sidebar_footer"
          data-kt-scroll-wrappers="#kt_app_sidebar_navs"
          data-kt-scroll-offset="5px"
          style={{
            overflow: "auto",
            transition: "width 0.4s, 0.4s",
            animation: ".5s linear",
          }}
        >
          <div
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
            className="menu menu-column menu-rounded menu-sub-indention menu-active-bg"
          >
            {adminMenus.length > 0 &&
              (localStorage.getItem("userType") == 1
                ? adminMenus
                : userMenus
              ).map((menu) => {
                return (
                  <div key={menu?.id}>
                    {menu?.child.length > 0 ? (
                      <div
                        className={`menu-item menu-accordion ${
                          menu?.id === "settings"
                            ? settingToggle
                              ? "hover show"
                              : ""
                            : applicationToggle
                            ? "hover show"
                            : ""
                        }`}
                      >
                        <span
                          className="menu-link"
                          onClick={() => {
                            menu?.id === "settings"
                              ? setSettingToggle(!settingToggle)
                              : setApplicationToggle(!applicationToggle);
                          }}
                        >
                          <span className="menu-icon">{menu?.icon}</span>
                          <span className="menu-title">{menu?.title}</span>
                          <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion">
                          {menu?.child.map((children) => {
                            return (
                              <div
                                className="menu-item"
                                onClick={() => setActiveMenu(children?.id)}
                              >
                                <Link
                                  to={children?.link}
                                  className={`menu-link ${
                                    activeMenu === children?.id ? "active" : ""
                                  }`}
                                >
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                  </span>
                                  <span className="menu-title">
                                    {children?.title}
                                  </span>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => setActiveMenu(menu?.id)}
                        className="menu-item"
                      >
                        <Link
                          to={menu?.link}
                          className={`menu-link ${
                            activeMenu === menu?.id ? "active" : ""
                          }`}
                        >
                          <span className="menu-icon">{menu?.icon}</span>
                          <span className="menu-title">{menu?.title}</span>
                        </Link>
                      </div>
                    )}
                    {/* <div onClick={() => setActiveMenu(menu?.id)} className="menu-item">
                                    <Link to={menu?.link} className={`menu-link ${activeMenu === menu?.id ? 'active' : ''}`}>
                                        <span className="menu-icon">
                                            {menu?.icon}
                                        </span>
                                        <span className="menu-title">{menu?.title}</span>
                                    </Link>
                                </div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div
        className="app-sidebar-footer d-flex justify-content-center px-11 pb-10 mt-4"
        id="kt_app_sidebar_footer"
      >
        <div className>
          {/* <div
            className="cursor-pointer symbol symbol-circle symbol-50px"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-overflow="true"
            data-kt-menu-placement="top-start"
          >
            <img src="/assets/media/avatars/300-2.jpg" alt="image" />
          </div> */}
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-50px me-5">
                  <img alt="Logo" src="/assets/media/avatars/300-2.jpg" />
                </div>
                <div className="d-flex flex-column">
                  <div className="fw-bold d-flex align-items-center fs-5">
                    Alice Page
                  </div>
                  <a className="fw-semibold text-muted text-hover-primary fs-7">
                    max@kt.com
                  </a>
                </div>
              </div>
            </div>
            <div className="separator my-2" />
            <div className="menu-item px-5">
              <a href className="menu-link px-5">
                My Profile
              </a>
            </div>
            <div className="separator my-2" />
            <div
              className="menu-item px-5"
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-placement="right-end"
              data-kt-menu-offset="-15px, 0"
            >
              <a className="menu-link px-5">
                <span className="menu-title position-relative">
                  Language
                  <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                    English
                    <img
                      className="w-15px h-15px rounded-1 ms-2"
                      src="/assets/media/flags/united-states.svg"
                      alt=""
                    />
                  </span>
                </span>
              </a>
              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                <div className="menu-item px-3">
                  <a href className="menu-link d-flex px-5 active">
                    <span className="symbol symbol-20px me-4">
                      <img
                        className="rounded-1"
                        src="/assets/media/flags/united-states.svg"
                        alt=""
                      />
                    </span>
                    English
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a href className="menu-link d-flex px-5">
                    <span className="symbol symbol-20px me-4">
                      <img
                        className="rounded-1"
                        src="/assets/media/flags/spain.svg"
                        alt=""
                      />
                    </span>
                    Spanish
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a href className="menu-link d-flex px-5">
                    <span className="symbol symbol-20px me-4">
                      <img
                        className="rounded-1"
                        src="/assets/media/flags/germany.svg"
                        alt=""
                      />
                    </span>
                    German
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a href className="menu-link d-flex px-5">
                    <span className="symbol symbol-20px me-4">
                      <img
                        className="rounded-1"
                        src="/assets/media/flags/japan.svg"
                        alt=""
                      />
                    </span>
                    Japanese
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a href className="menu-link d-flex px-5">
                    <span className="symbol symbol-20px me-4">
                      <img
                        className="rounded-1"
                        src="/assets/media/flags/france.svg"
                        alt=""
                      />
                    </span>
                    French
                  </a>
                </div>
              </div>
            </div>
            <div className="menu-item px-5 my-1">
              <a className="menu-link px-5">Account Settings</a>
            </div>
            <div className="menu-item px-5">
              <a className="menu-link px-5">Sign Out</a>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            localStorage.clear();
            contextVar?.updateCommonGlobalVal("profile", {});
            window.location.assign("/login");
          }}
          className="btn btn-sm btn-outline btn-flex btn-custom px-3"
        >
          <i className="ki-outline ki-entrance-left fs-2 me-2" />
          Logout
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
