import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 10px;

  .card-header {
    border-bottom: none !important;
  }
  // .row {
  //   margin-left: 0px !important;
  //   margin-right: 0px !important;
  // }
`;

export default Wrapper;
