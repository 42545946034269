import { Skeleton } from "antd";
import React from "react";
import { PerformanceWrapper } from "../style";
// import { SIGN } from "../../../../config";

// Define the formatter function
function formatNumber(value) {
  return value?.toLocaleString();
  let newValue = value;
  if (value >= 1000 && value < 1000000) {
    newValue = (value / 1000).toFixed(1) + "K";
  } else if (value >= 1000000 && value < 1000000000) {
    newValue = (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000000000) {
    newValue = (value / 1000000000).toFixed(1) + "B";
  }
  return newValue;
}

const Loading = (
  <>
    <div className="d-flex flex-stack">
      <div className="d-flex align-items-center me-5">
        <div className="me-5">
          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
            <Skeleton.Input
              active
              size="small"
              shape="round"
              className="me-2"
            />
          </a>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <h4 className="fw-bold mb-0">
          <Skeleton.Button active size="small" shape="round" />
        </h4>
      </div>
    </div>
  </>
);
function PerformaceMetricsView(
  performanceMetricsLoading,
  performanceMetricsList,
  performanceMetrics,
  SIGN
) {
  const ConvertValue = (value) => {
    if (value < 0) {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    } else if (value > 0) {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
          +{value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
        </span>
      );
    } else {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-warning" />
        </span>
      );
    }
  };

  const prLogic = (newvalue, oldvalue) => {
    const value =
      newvalue === 0 && oldvalue === 0
        ? 0
        : parseFloat(((newvalue - oldvalue) / (oldvalue || 1)) * 100).toFixed(
            2
          );
    if (value === 0) {
      return (
        <span className=" fs-base">
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
          {value}%
        </span>
      );
    } else if (value < 0) {
      return (
        <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    }
    return (
      <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
        + {value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
      </span>
    );
  };

  const oldValues = performanceMetrics;
  const newValues = performanceMetricsList;

  return (
    <PerformanceWrapper className="row gx-5 gx-xl-5">
      <div className="col-xl-4 col-lg-6 col-sm-12 mb-5 mb-xl-5">
        <div
          className="card card-flush h-lg-100"
          style={{
            border: "1px dashed #49b6ea",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
          }}
        >
          <div
            className="card-header border-bottom-dashed"
            style={{
              minHeight: "50px",
              background: "#e1f6ff",
            }}
          >
            <h3 className="card-title fw-bold">Performance</h3>
            <div className="card-toolbar"></div>
          </div>
          <div className="card-body pt-2 pb-4 ">
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      Spend
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {SIGN()}
                      {formatNumber(oldValues?.spend?.current || 0)}{" "}
                      {prLogic(
                        parseFloat(newValues?.spend?.current || 0),
                        parseFloat(oldValues?.spend?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {SIGN()}
                    {formatNumber(newValues?.spend?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      Sales
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {SIGN()}
                      {formatNumber(oldValues?.sales?.current || 0)}{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.sales?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.sales?.current || 0),
                        parseFloat(oldValues?.sales?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {SIGN()}
                    {formatNumber(newValues?.sales?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      ACOS
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {oldValues?.acos?.current || 0}%{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.acos?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.acos?.current || 0),
                        parseFloat(oldValues?.acos?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {newValues?.acos?.current || 0}%
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 col-sm-12 mb-5 mb-xl-5">
        <div
          className="card card-flush h-lg-100"
          style={{
            border: "1px dashed #49b6ea",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
          }}
        >
          <div
            className="card-header border-bottom-dashed"
            style={{
              minHeight: "50px",
              background: "#e1f6ff",
            }}
          >
            <h3 className="card-title fw-bold">Conversion</h3>
            <div className="card-toolbar"></div>
          </div>
          <div className="card-body pt-2 pb-4 ">
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      Clicks
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {formatNumber(oldValues?.clicks?.current || 0)}{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.clicks?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.clicks?.current || 0),
                        parseFloat(oldValues?.clicks?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {formatNumber(newValues?.clicks?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      Orders
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {formatNumber(oldValues?.orders?.current || 0)}{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.orders?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.orders?.current || 0),
                        parseFloat(oldValues?.orders?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {formatNumber(newValues?.orders?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      CVR
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {oldValues?.cvr?.current || 0}%{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.cvr?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.cvr?.current || 0),
                        parseFloat(oldValues?.cvr?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {newValues?.cvr?.current || 0}%
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-12 col-sm-12 mb-5 mb-xl-5">
        <div
          className="card card-flush h-lg-100"
          style={{
            border: "1px dashed #49b6ea",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
          }}
        >
          <div
            className="card-header border-bottom-dashed"
            style={{
              minHeight: "50px",
              background: "#e1f6ff",
            }}
          >
            <h3 className="card-title fw-bold">Efficiency</h3>
            <div className="card-toolbar"></div>
          </div>
          <div className="card-body pt-2 pb-4 ">
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      CPC
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {SIGN()}
                      {formatNumber(oldValues?.cpc?.current || 0)}{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.cpc?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.cpc?.current || 0),
                        parseFloat(oldValues?.cpc?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {SIGN()}
                    {formatNumber(newValues?.cpc?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      SPC
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {SIGN()}
                      {formatNumber(oldValues?.spc?.current || 0)}{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.spc?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.spc?.current || 0),
                        parseFloat(oldValues?.spc?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {SIGN()}
                    {formatNumber(newValues?.spc?.current || 0)}
                  </h4>
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-2" />
            {performanceMetricsLoading ? (
              Loading
            ) : (
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-5">
                  <div className="me-5">
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                      CTR
                    </a>
                    <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                      {oldValues?.ctr?.current || 0}%{" "}
                      {/* {ConvertValue(
                        performanceMetricsList?.ctr?.change_percentage
                      )} */}
                      {prLogic(
                        parseFloat(newValues?.ctr?.current || 0),
                        parseFloat(oldValues?.ctr?.current || 0)
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bold mb-0">
                    {newValues?.ctr?.current || 0}%
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PerformanceWrapper>
  );
}

export default PerformaceMetricsView;
