import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Button,
  message,
} from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const CampaignForm = ({
  visible,
  setVisible,
  selectedRow,
  type,
  portfloioList,
  portfloioLoading,
  fakeActionCampaignsManagementAnalytics,
  UpdateCampaignAction,
  getTable,
  SIGN,
  configsData,
  configsLoading,
}) => {
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const CampaignsUpdateResponse = useSelector(
    (state) => state.CampaignManagement.CampaignsUpdateResponse || {}
  );

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (CampaignsUpdateResponse?.status === true) {
      message.destroy();
      message.success(CampaignsUpdateResponse?.message);
      fakeActionCampaignsManagementAnalytics("CampaignsUpdateResponse");
      getTable();
      handleCancel();
    } else if (CampaignsUpdateResponse?.status === false) {
      message.destroy();
      message.error(
        Object.values(CampaignsUpdateResponse?.error)?.[0]?.[0] ||
          CampaignsUpdateResponse?.message
      );
      fakeActionCampaignsManagementAnalytics("CampaignsUpdateResponse");
    }
  }, [CampaignsUpdateResponse]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        message.destroy();
        message.loading("Loading...", 0);

        const formattedValues = {
          ...values,
          end_date: values.end_date ? values.end_date.format("YYYY-MM-DD") : "", // Format the date here
          start_date: values.start_date.format("YYYY-MM-DD"), // Format the date here
        };

        UpdateCampaignAction(selectedRow?.id, {
          marketplace_id: selectedRow?.marketplace_id,
          advertising_type: selectedRow?.advertising_type,
          campaign_id: selectedRow?.campaign_id,
          new_budget: formattedValues?.new_budget
            ? (formattedValues?.new_budget || 0).toString()
            : "0",
          old_budget: selectedRow?.budget_amount
            ? (selectedRow?.budget_amount || 0).toString()
            : "0",
          portfolio_id: formattedValues?.portfolio_id
            ? (formattedValues?.portfolio_id || 0).toString()
            : "0",
          start_date: formattedValues?.start_date
            ? dayjs(formattedValues?.start_date).format("YYYY-MM-DD")
            : "",
          end_date: formattedValues?.end_date
            ? dayjs(formattedValues?.end_date).format("YYYY-MM-DD")
            : "",

          name: formattedValues?.name,
          top_of_search_placement: formattedValues?.top_of_search_placement
            ? (formattedValues?.top_of_search_placement || 0).toString()
            : "0",
          product_pages_placement: formattedValues?.product_pages_placement
            ? (formattedValues?.product_pages_placement || 0).toString()
            : "0",
          bidding_strategy: formattedValues?.bidding_strategy,
          state: formattedValues?.state,
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const disabledDate = (current) => {
    // Disable dates that are in the past or today
    return current && current.valueOf() <= Date.now() - 86400000;
  };
  const disabledDateEnd = (current) => {
    const endDateToDisable = new Date(form.getFieldValue("start_date")); // Replace with your custom end date
    return current && current.valueOf() < endDateToDisable.valueOf();
  };

  const outputObject = {};

  for (const key in configsData?.biddingStrategyTypes) {
    const value = configsData?.biddingStrategyTypes[key];
    outputObject[value] = key;
  }

  const getError = () => {
    return (
      <div
        style={{
          position: "relative",
          justifyItems: "end",
          top: "5px",
          width: "239px",
        }}
        className="d-flex "
      >
        <small
          style={{
            color: "#ff2424",
          }}
        >
          !Enter a budget of at least {SIGN()}1.00
        </small>
        <div
          style={{
            color: "#1e93c6",
            cursor: "pointer",

            marginLeft: "10px",
          }}
          onClick={() => {
            form.setFieldValue("new_budget", 1);
            form
              .validateFields()
              .then(() => {
                console.log("Form validation succeeded");
              })
              .catch((errorInfo) => {
                console.log("Form validation failed:", errorInfo);
              });
          }}
        >
          Quick fix
        </div>
      </div>
    );
  };
  return (
    <>
      <Modal
        title={type === "view" ? "View" : "Edit"}
        open={visible}
        onOk={handleSave}
        maskClosable={false}
        onCancel={handleCancel}
        okButtonProps={{ disabled: type === "view" }}
      >
        <Form
          id="campign-update"
          initialValues={{
            new_budget: parseFloat(selectedRow?.budget_amount || 0),
            name: selectedRow?.campaign_name || null,
            portfolio_id: selectedRow?.portfolio_id
              ? parseInt(selectedRow?.portfolio_id)
              : null,
            start_date: selectedRow?.start_date
              ? dayjs(selectedRow?.start_date, "YYYY-MM-DD")
              : null,
            end_date: selectedRow?.end_date
              ? dayjs(selectedRow?.end_date, "YYYY-MM-DD")
              : null,
            top_of_search_placement:
              selectedRow?.top_of_search_placement || null,
            product_pages_placement:
              selectedRow?.product_pages_placement || null,
            bidding_strategy:
              outputObject?.[selectedRow?.bidding_strategy_type] || null,
            state: selectedRow?.campaign_state || null,
          }}
          form={form}
          layout="vertical"
        >
          <div className="row">
            <div className="col-6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input Campaign Name!",
                  },
                ]}
                name="name"
                label="Campaign Name"
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input Budget!",
                  },
                  {
                    min: 1,
                    type: "number",
                    message: getError,
                  },
                ]}
                name="new_budget"
                label="Budget"
              >
                <InputNumber addonBefore={SIGN()} style={{ width: "100%" }} />
                {/* {parseFloat(data?.budget_amount) < 50 ? (
                <div
                  style={{
                    position: "relative",

                    top: "5px",
                  }}
                  className="d-flex align-items-center"
                >
                  <small
                    style={{
                      color: "#ff2424",
                    }}
                  >
                    ! Enter a budget of at least {SIGN()}50.00
                  </small>
                  <div
                    style={{
                      color: "#1e93c6",
                      cursor: "pointer",

                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      onChangeData("budget_amount", 50);
                    }}
                  >
                    Quick fix
                  </div>
                </div>
              ) : (
                ""
              )} */}
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="portfolio_id"
            rules={[
              {
                required: true,
                message: "Please select Portfolio Id!",
              },
            ]}
            label="Portfolio Id"
          >
            <Select
              loading={portfloioLoading}
              options={portfloioList?.map((d) => ({
                label: d?.name,
                value: d?.portfolioId,
              }))}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <div className="row">
            <div className="col-6">
              <Form.Item
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please select Start date!",
                  },
                ]}
                label="Start Date"
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item name="end_date" label="End Date">
                <DatePicker
                  disabledDate={disabledDateEnd}
                  style={{ width: "100%" }}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Form.Item
                name="top_of_search_placement"
                label="Top of Search Placement"
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                name="product_pages_placement"
                label="Product Pages Placement"
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </div>

          <Form.Item name="bidding_strategy" label="Bidding Strategy">
            <Select
              loading={configsLoading}
              options={Object.entries(
                configsData?.biddingStrategyTypes || {}
              )?.map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              className="me-4"
              placeholder="Select Bidding Strategy"
            />
          </Form.Item>
          <Form.Item name="state" label="Status">
            <Select>
              <Select.Option value="PAUSED">Paused</Select.Option>
              <Select.Option value="ENABLED">Enabled</Select.Option>
              {/* <Select.Option value="PAUSED">Paused</Select.Option> */}
              {/* Add other state options as needed */}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CampaignForm;
