import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { createHashHistory } from "history";
import { Provider } from "react-redux";
import AppRoute from "./AppRoute";

import AppStyleWrapper from "./AppStyle";
import GlobalCommonContextProvider from "./commonContext";
import { store } from "./redux/configurations/store/configure-store";
import { nameObject } from "./core/lib";

// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const handleStorageChange = (event) => {
    const user_type = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).user_type
      : "";

    if (!event?.storageArea?.user) {
      window.location.pathname = "/manage-seller-users";
    }
    if (
      (window.location.pathname === "/login" && user_type === 1) ||
      (!event?.storageArea?.adminData &&
        parseInt(event?.storageArea?.userType) === 1)
    ) {
      window.location.pathname = "/manage-seller-users";
    }
    if (
      (window.location.pathname === "/login" && user_type === 2) ||
      (event?.storageArea?.adminData &&
        parseInt(event?.storageArea?.userType) === 2)
    ) {
      window.location.pathname = "/";
    }
  };

  useEffect(() => {
    var defaultThemeMode = localStorage.getItem("data-bs-theme") || "light";
    localStorage.setItem("data-bs-theme", defaultThemeMode);
    var themeMode;
    if (document.documentElement) {
      if (document.documentElement.hasAttribute("data-theme-mode")) {
        themeMode = document.documentElement.getAttribute("data-theme-mode");
      } else {
        if (localStorage.getItem("data-bs-theme") !== null) {
          themeMode = localStorage.getItem("data-bs-theme");
        } else {
          themeMode = defaultThemeMode;
        }
      }
      if (themeMode === "system") {
        themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light";
      }
      document.documentElement.setAttribute("data-bs-theme", themeMode);
    }
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins, sans-serif",
          },
          component: {
            Select: {
              borderColor: "red", // Customize with your desired border color
            },
          },
        }}
      >
        <GlobalCommonContextProvider>
          <Provider store={store}>
            <AppStyleWrapper />
            <AppRoute history={createHashHistory()} />
          </Provider>
        </GlobalCommonContextProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
