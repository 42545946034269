export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CAMPAIGNS_ANALYTICS_FILTER_LIST_SUCCESS":
    case "CAMPAIGNS_ANALYTICS_FILTER_LIST_ERROR":
      return {
        ...state,
        CampaignsAnalyticsFilterResponse: action.updatePayload,
      };
    case "CAMPAIGNS_ANALYTICS_PERFORMANCE_METRICS_SUCCESS":
    case "CAMPAIGNS_ANALYTICS_PERFORMANCE_METRICS_ERROR":
      return {
        ...state,
        CampaignsAnalyticsPerformanceMetricsResponse: action.updatePayload,
      };
    case "CAMPAIGNS_ANALYTICS_DAILY_PERFORMANCE_TREND_SUCCESS":
    case "CAMPAIGNS_ANALYTICS_DAILY_PERFORMANCE_TREND_ERROR":
      return {
        ...state,
        CampaignsAnalyticsDailyPerformanceTrendResponse: action.updatePayload,
      };
    case "ACTIVE_COMPAIGNS_DATA_SUCCESS":
    case "ACTIVE_COMPAIGNS_DATA_ERROR":
      return {
        ...state,
        ActiveCompaignsDataResponse: action.updatePayload,
      };
    case "CAMPAIGNS_ANALYTICS_FILTERS_SUCCESS":
    case "CAMPAIGNS_ANALYTICS_FILTERS_ERROR":
      return {
        ...state,
        CampaignsAnalyticsFiltersResponse: action.updatePayload,
      };
    case "ALL_CAMPAIGNS_DATA_SUCCESS":
    case "ALL_CAMPAIGNS_DATA_ERROR":
      return {
        ...state,
        AllCompaignsDataResponse: action.updatePayload,
      };
    case "GET_ALL_PORTFOLIOS_LIST_SUCCESS":
    case "GET_ALL_PORTFOLIOS_LIST_ERROR":
      return {
        ...state,
        GetAllPortFoliosListResponse: action.updatePayload,
      };
    case "BULK_ACTION_SUCCESS":
    case "BULK_ACTION_ERROR":
      return {
        ...state,
        BulkActionUpdateResponse: action.updatePayload,
      };
    case "GET_RULES_IN_CAMPAIGNS_ANALYTICS_SUCCESS":
    case "GET_RULES_IN_CAMPAIGNS_ANALYTICS_ERROR":
      return {
        ...state,
        GetRulesInCampaignsAnalyticsResponse: action.updatePayload,
      };
    case "GET_ALL_PORTFOLIOS_LIST_SUCCESS":
    case "GET_ALL_PORTFOLIOS_LIST_ERROR":
      return {
        ...state,
        GetAllPortFoliosListResponse: action.updatePayload,
      };
    case "UPDATE_CAMPAIGN_BUDGET_SUCCESS":
    case "UPDATE_CAMPAIGN_BUDGET_ERROR":
      return {
        ...state,
        UpdateCampaignBudgetResponse: action.updatePayload,
      };
    case "GET_CAMPAIGN_CONFIG_LIST_SUCCESS":
    case "GET_CAMPAIGN_CONFIG_LIST_ERROR":
      return {
        ...state,
        GetCampaignConfigListResponse: action.updatePayload,
      };
    case "UPDATE_CAMPAIGN_CONFIG_LIST_SUCCESS":
    case "UPDATE_CAMPAIGN_CONFIG_LIST_ERROR":
      return {
        ...state,
        UpdateCampaignConfigListResponse: action.updatePayload,
      };
    case "CAMPAIGN_ANALYTICS_BULK_APPLY_SUCCESS":
    case "CAMPAIGN_ANALYTICS_BULK_APPLY_ERROR":
      return {
        ...state,
        CampaignAnalyticsBulkApplyResponse: action.updatePayload,
      };
    case "FAKE_ACTION_CAMPAIGNS_ANALYTICS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
