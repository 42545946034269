import axiosCall from "../../configurations/network-services/axiosCall";

export const getUserCredentialsAction = () => {
  const path = `user-credentials`;
  const responseType = "USER_CREDENTIALS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GenerateRefreshTokenAction = (data) => {
  const path = `user-advertising-credentials/callback-url`;
  const responseType = "GENERATE_REFRESH_TOEKN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveAdProfileAction = (data) => {
  const path = `user-advertising-credentials/save-advertising-profile`;
  const responseType = "SAVE_ADVERTISTING_PROFILE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetMarketplaceListAction = () => {
  const path = `get-marketplaces`;
  const responseType = "GET_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UserSPCredentialsCallbackAction = (data) => {
  const path = `user-sp-credentials/callback-url`;
  const responseType = "USER_SP_CREDENTIALS_CALLBACK";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UserCredentialsSPAction = (id, data) => {
  const path = `user-credentials/${id}`;
  const responseType = "USER_CREDENTIALS_SP";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UserDeleteCredentialsSPAction = (id, data) => {
  const path = `user-credentials/${id}`;
  const responseType = "USER_DELETE_CREDENTIALS_SP";
  return axiosCall("delete", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionMarketplace = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MARKETPLACE_CREDENTIALS", state: data });
};
