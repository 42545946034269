import { styled } from "styled-components";
export const Wrapper = styled.div``;
export const PerformanceWrapper = styled.div`
  .card {
    transition: 0.6s;
    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
    }
  }
`;
