import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

function Footer() {
    return (
        <div id="kt_app_footer" className="app-footer">
            <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">{moment().format("YYYY")}©</span>
                    <Link to='/' className="text-gray-800 fw-bolder text-hover-primary">VFAds</Link>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                        <Link to='/' className="menu-link px-2">About</Link>
                    </li>
                    <li className="menu-item">
                        <Link to='/' className="menu-link px-2">Support</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer