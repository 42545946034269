import React, { useEffect, useState } from "react";
import { DefaultPerPage, timeSince } from "../../../../../config";
import { useSelector } from "react-redux";
import { Button, Popconfirm, Select, Table, message } from "antd";
import moment from "moment";
import Pagination from "../../../../../components/pagination";
import { NothingToShow } from "../../../../../components/not-found";
import { TableLoading } from "../../../../../components/table-animation";
import AutoStrategy from "../../../campaign-management/lib/auto-strategy";
import EditModal from "./lib/edit";

const CreationStrategy = (props) => {
  const {
    StrategiesListAction,
    setStrategyLoading,
    setStrategyList,
    DeleteStrategiesAction,
    fakeActionCampaignsManagementAnalytics,
    configsData,
    marketplaceLoading,
    marketplaceList,
    selectedMarketplaceStrategy,
    setSelectedMarketplaceStrategy,
  } = props;

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [strategiesList, setStrategiesList] = useState([]);
  const [strategiesLoading, setStrategiesLoading] = useState(true);

  const [autoStrategyView, setAutoStrategyView] = useState(false);

  const [editModalView, setEditModalView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const ListStrategiesResponse = useSelector(
    (state) => state.CampaignManagement.ListStrategiesResponse || {}
  );
  const CampaignsStrategiesDeleteResponse = useSelector(
    (state) => state.CampaignManagement.CampaignsStrategiesDeleteResponse || {}
  );

  useEffect(() => {
    if (CampaignsStrategiesDeleteResponse?.status === true) {
      message.destroy();
      setStrategiesLoading(true);
      setStrategiesList([]);
      setPage(1);
      setPageSize(10);
      StrategiesListAction({
        page: 1,
        perPage: DefaultPerPage,
        marketplace_id: selectedMarketplaceStrategy,
      });
      message.success(CampaignsStrategiesDeleteResponse?.message);
      fakeActionCampaignsManagementAnalytics(
        "CampaignsStrategiesDeleteResponse"
      );
    } else if (CampaignsStrategiesDeleteResponse?.status === false) {
      message.destroy();
      message.error(
        Object.values(CampaignsStrategiesDeleteResponse?.data)?.[0]?.[0] ||
          CampaignsStrategiesDeleteResponse?.message
      );
      fakeActionCampaignsManagementAnalytics(
        "CampaignsStrategiesDeleteResponse"
      );
    }
  }, [CampaignsStrategiesDeleteResponse]);

  useEffect(() => {
    if (ListStrategiesResponse?.status === true) {
      setStrategiesList(ListStrategiesResponse?.data?.records);
      setStrategyList(ListStrategiesResponse?.data?.records);
      setStrategyLoading(false);
      setTotalPage(ListStrategiesResponse.data.pagination.totalCount);
      setStrategiesLoading(false);
      fakeActionCampaignsManagementAnalytics("ListStrategiesResponse");
    } else if (ListStrategiesResponse?.status === false) {
      setStrategiesLoading(false);
      setStrategyLoading(false);
      fakeActionCampaignsManagementAnalytics("ListStrategiesResponse");
    }
  }, [ListStrategiesResponse]);

  useEffect(() => {
    if (!selectedMarketplaceStrategy) return;
    setStrategiesLoading(true);
    setStrategiesList([]);
    StrategiesListAction({
      page: 1,
      perPage: DefaultPerPage,
      marketplace_id: selectedMarketplaceStrategy,
    });
  }, [selectedMarketplaceStrategy]);

  const onPageNo = (e) => {
    setStrategiesLoading(true);
    setStrategiesList([]);
    StrategiesListAction({
      page: e,
      perPage: pageSize,
      marketplace_id: selectedMarketplaceStrategy,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setStrategiesLoading(true);
    StrategiesListAction({
      page: 1,
      perPage: e,
      marketplace_id: selectedMarketplaceStrategy,
    });
  };
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceStrategy
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  const columns = [
    {
      title: "#",

      width: 75,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Strategy Name",
      dataIndex: "strategy_name",
      key: "strategy_name",
      width: 150,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Ad Group Name",
      dataIndex: "adGroupName",
      key: "adGroupName",
      width: 150,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 75,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "advertising_type",
      key: "advertising_type",
      width: 75,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
      width: 100,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Bidding Strategy",
      dataIndex: "bidding_strategy_type",
      key: "bidding_strategy_type",
      width: 150,
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text * 1000)).format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">({timeSince(text)})</span>
          </div>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 200,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text * 1000)).format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">({timeSince(text)})</span>
          </div>
        );
      },
    },
    // {
    //   title: "Portfolio",
    //   dataIndex: "portfolio",
    //   key: "portfolio",
    //   width: 100,
    //   render: (text) => {
    //     return <span>{text || "-"}</span>;
    //   },
    // },
    {
      title: "Budget",
      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 100,
      render: (text) => {
        return (
          <span>
            {SIGN()}
            {text?.toLocaleString() || "-"}
          </span>
        );
      },
    },
    {
      title: "Top of Search",
      dataIndex: "top_of_search_placement",
      key: "top_of_search_placement",
      width: 125,
      render: (text) => {
        return <span>{text?.toLocaleString() || "-"}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 75,
      render: (text) => (
        <div className="d-flex flex-shrink-0 justify-content-center">
          <Button
            type="link"
            onClick={() => {
              setSelectedRow(text);
              setEditModalView(true);
            }}
            icon={<i className="ki-outline ki-pencil fs-2 text-primary" />}
          />
          <Popconfirm
            title="Delete the Strategy"
            description="Are you sure to delete this Strategy?"
            okText="Yes"
            placement="left"
            cancelText="No"
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              DeleteStrategiesAction(text?.id);
            }}
          >
            <Button
              type="link"
              icon={<i className="ki-outline ki-trash fs-2 text-danger" />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const marketplace_signs = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.marketplace_signs?.[
          selectedMarketplaceStrategy
        ] || ""
      );
    } catch (error) {
      return "INR";
    }
  };

  return (
    <>
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        {/*begin::Header*/}
        <div className="card-header border-bottom-dashed">
          <h3 className="card-title" />
          <div className="card-toolbar">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <Select
                size="large"
                placeholder="Select Marketplace"
                loading={marketplaceLoading}
                value={selectedMarketplaceStrategy || null}
                onChange={(e) => {
                  setSelectedMarketplaceStrategy(e);
                }}
                style={{
                  width: "200px",
                }}
                options={marketplaceList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
                className="fs-7 me-3"
              />
              {/*begin::Menu*/}
              <button
                className="btn btn-dark fs-7 fw-bold"
                onClick={(e) => setAutoStrategyView(true)}
              >
                Create Strategy
              </button>

              {/*begin::Menu 2*/}

              {/*end::Menu*/}
            </div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-2 pt-0">
          {/*begin::Table container*/}
          {strategiesLoading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="table-responsive">
              <Table
                dataSource={strategiesList}
                columns={columns}
                locale={{ emptyText: <NothingToShow /> }}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={false}
                components={{
                  header: {
                    cell: () => null, // Hide the original header cell
                    row: () => null, // Hide the original header row
                    wrapper: () => (
                      <thead className="ant-table-thead">
                        <tr className="ant-table-row">
                          {columns.map((column) => (
                            <th
                              key={column.key}
                              // style={tableHeaderStyle}
                              className="ant-table-cell"
                            >
                              {column.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    ),
                  },
                }}
              />
            </div>
          )}

          {/*end::Table container*/}
          {/*end::Table container*/}
          <Pagination
            loading={strategiesLoading || strategiesList?.length === 0}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
        {/*begin::Body*/}
      </div>
      {/* {editModalView && (
        <EditModal
          visible={editModalView}
          onCancel={() => {
            setEditModalView(false);
          }}
          onSave={() => {
            setEditModalView(false);
          }}
          data={selectedRow}
        />
      )} */}
      {(autoStrategyView || editModalView) && (
        <AutoStrategy
          visible={autoStrategyView || editModalView}
          handleCancel={() => {
            setEditModalView(false);
            setAutoStrategyView(false);
            setSelectedRow({});
          }}
          selectedMarketplaceStrategy={selectedMarketplaceStrategy}
          selectedRow={selectedRow}
          configsData={configsData}
          marketplace_signs={marketplace_signs}
          getList={() => {
            setPage(1);
            setPageSize(10);
            setStrategiesLoading(true);
            setStrategiesList([]);
            StrategiesListAction({
              page: 1,
              perPage: DefaultPerPage,
              marketplace_id: selectedMarketplaceStrategy,
            });
          }}
          SIGN={SIGN}
          {...props}
        />
      )}
    </>
  );
};

export default CreationStrategy;
