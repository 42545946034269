export const EndPoint = "https://api.vfunnel.ai/";
export const pageDropdown = [10, 25, 50, 100];
export const DefaultPerPage = 10;
export const DefaultKeywordPerPage = 20;
export const imageURL = `${EndPoint}images/user-images/`;
export const APPID =
  "amzn1.application-oa2-client.e81f4e0cccbf454d87f1ac7468b07284";
export const ClientSecret =
  "amzn1.0a2-cs.v1.fedc97e80b60f968a19b0a31f6c23eaf5af4cd5b250a1123e8197bff264d174e";

export const SPAPI = "amzn1.sp.solution.59f1f676-f703-459f-9834-3802ec8904ce";
export const SIGN = "₹";
export const timeSince = (date) => {
  const units = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const seconds = Math.floor((new Date() - date * 1000) / 1000);

  for (let i = 0; i < units.length; i++) {
    const interval = seconds / units[i].seconds;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        " " +
        units[i].label +
        (interval > 1 ? "s" : "") +
        " ago"
      );
    }
  }

  return "just now";
};

// Define the formatter function
export function FormatNumber(value) {
  return value?.toLocaleString();
  let newValue = value;
  if (value >= 1000 && value < 1000000) {
    newValue = (value / 1000).toFixed(1) + "K";
  } else if (value >= 1000000 && value < 1000000000) {
    newValue = (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000000000) {
    newValue = (value / 1000000000).toFixed(1) + "B";
  }
  return newValue;
}

export const generateRandomColorCode = (l) => {
  const colorCodes = [];

  for (let i = 0; i < l; i++) {
    const characters = "0123456789ABCDEF";

    let colorCode = "#";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);

      colorCode += characters[randomIndex];
    }

    colorCodes.push(colorCode);
  }

  return colorCodes;
};

export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
