import React, { useState } from "react";
import { Modal, Select } from "antd";
import Chart from "react-apexcharts";

function OrganicSponRanking() {
  const [addNewModal, setAddNewModal] = useState(false);
  const [analyticsModal, setAnalyticsModal] = useState(false);

  const series = [
    {
      name: "Spend",
      data: [65, 80, 60, 60, 45, 80, 80],
    },
    {
      name: "Sales",
      data: [90, 110, 95, 85, 95, 115, 100],
    },
    {
      name: "ACOS",
      data: [75, 90, 70, 55, 75, 80, 88],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        // show: false,
        tools: {
          download: false,
        },
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 15,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      lineCap: "butt",
      width: 3,
      dashArray: 0,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    colors: ["#f1416c", "#22a9e6", "#50cd89"],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-5 mb-xl-8">
                  <div className="card-header border-bottom border-bottom-dashed pt-3">
                    <div className="d-flex flex-stack flex-wrap gap-4">
                      <div className="position-relative mr-4">
                        <input
                          type="text"
                          className="form-control fs-7 form-control-solid"
                          placeholder="Search by Search Term"
                        />
                      </div>
                      <div className="position-relative mr-4">
                        <input
                          type="text"
                          className="form-control fs-7 form-control-solid"
                          placeholder="Search by Input ASIN"
                        />
                      </div>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control fs-7 form-control-solid"
                          placeholder="Search by Competitors ASIN"
                        />
                      </div>
                      <div className="position-relative">
                        <button className="btn btn-success btn-sm fs-7 ps-5 px-5">
                          <i className="bi bi-search pe-0" />
                        </button>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <button
                        type="button"
                        onClick={() => setAddNewModal(true)}
                        className="btn btn-primary fw-bold btn-sm px-7"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_add_customer"
                      >
                        Add New
                      </button>
                      <button
                        type="button"
                        className="btn  fs-7 btn-light btn-active-light-primary ms-3 fw-bold"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        Import
                      </button>
                      <div
                        className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        id="kt_menu_63be7b6fcb7e6"
                      >
                        <div className="px-7 py-5">
                          <div className="fs-5 text-dark fw-bold">
                            Filter Options
                          </div>
                        </div>
                        <div className="separator border-gray-200" />
                        <div className="px-7 py-5">
                          <div className="mb-5">
                            <a href className="fw-bold text-primary">
                              <i className="bi bi-download text-primary" />{" "}
                              Download an import schema
                            </a>
                          </div>
                          <div className="mb-10">
                            <input
                              className="form-control form-control-sm form-control-solid"
                              placeholder
                              type="file"
                              id="formFile"
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-menu-dismiss="true"
                            >
                              Reset
                            </button>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              data-kt-menu-dismiss="true"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-light-primary fs-7 fw-bold ms-3">
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="card-body pt-1">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className="min-w-50px">#</th>
                            <th className="min-w-50px" />
                            <th className="min-w-50px">Search Term</th>
                            <th className="min-w-50px">Input ASIN</th>
                            <th className="min-w-50px">Competitors ASIN</th>
                            <th className="min-w-50px">Organic Rank</th>
                            <th className="min-w-50px">Sponsored Rank</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td className>
                              <a
                                href
                                data-bs-toggle="collapse"
                                data-bs-target="#kt_accordion_1_body_1"
                                aria-expanded="false"
                                aria-controls="kt_accordion_1_body_1"
                                className="open-arrow collapsed"
                              >
                                <i className="las la-angle-down text-dark fs-4" />
                              </a>
                            </td>
                            <td>
                              <a href className="fw-bold">
                                Green Cap
                              </a>
                            </td>
                            <td>B08T1P7Z8T</td>
                            <td>B08T1P7Z8D, B08T1P7Z8S, B08T1P7Z8N</td>
                            <td>2001</td>
                            <td>1024</td>
                            <td>
                              <div
                                className="d-flex"
                                onClick={() => setAnalyticsModal(true)}
                              >
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#chart"
                                  href
                                >
                                  <i className="bi bi-bar-chart fs-4 text-primary" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={11}
                              className="hiddenRow  bg-light bg-opacity-100"
                            >
                              <div
                                id="kt_accordion_1_body_1"
                                className="accordion-collapse collapse m-3 bg-white"
                                aria-labelledby="kt_accordion_1_header_1"
                                data-bs-parent="#kt_accordion_1"
                              >
                                <div className="table-responsive m-0">
                                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                      <tr className="fw-bolder text-dark">
                                        <th className="min-w-50px">#</th>
                                        <th className="min-w-50px">
                                          Input ASIN
                                        </th>
                                        <th className="min-w-50px">
                                          Competitors ASIN
                                        </th>
                                        <th className="min-w-50px">Name</th>
                                        <th className="min-w-50px">
                                          Organic Rank
                                        </th>
                                        <th className="min-w-50px">
                                          Sponsored Rank
                                        </th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr></tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                      <div className="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                        <select
                          name
                          aria-controls
                          className="form-select form-select-sm form-select-solid w-75px me-3"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        Showing 1 to 3 of 50 entries
                      </div>
                      <ul className="pagination">
                        <li className="page-item previous">
                          <a className="page-link">
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link">1</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">2</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">3</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">4</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">5</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">6</a>
                        </li>
                        <li className="page-item next">
                          <a className="page-link">
                            <i className="next" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={addNewModal}
        maskClosable={false}
        onOk={() => setAddNewModal(false)}
        onCancel={() => setAddNewModal(false)}
        width={700}
        // className='custom-modal'
        footer={[
          <div className="modal-footer">
            <button
              className="btn btn-light fs-7 me-3"
              onClick={() => setAddNewModal(false)}
            >
              Close
            </button>
            <button type="button" class="btn btn-primary fw-bold fs-7">
              Start
            </button>
          </div>,
        ]}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h2 className="modal-title fs-2 fw-bold">Add New</h2>
          </div>
          <div className="modal-body pb-7 pt-5 px-lg-10">
            <div
              className="scroll-y pe-7"
              id="kt_modal_add_customer_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_customer_header"
              data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Search Term</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                  />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Input ASIN</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                  />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Competitors ASIN</label>
                </div>
                <div className="col-md-8 fv-row">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    size="large"
                    placeholder="Select Option"
                    // onChange={handleInventoryChange}
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "B08T1P7Z8D",
                        label: "B08T1P7Z8D",
                      },
                      {
                        value: "B08T1P7Z8S",
                        label: "B08T1P7Z8S",
                      },
                    ]}
                    className="fs-7"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </Modal>
      <Modal
        centered
        open={analyticsModal}
        onOk={() => setAnalyticsModal(false)}
        onCancel={() => setAnalyticsModal(false)}
        width={800}
        // className='custom-modal'
        footer={[]}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h2 className="modal-title fs-2 fw-bold">Analytics</h2>
          </div>
          <div className="modal-body p-1">
            <div
              id="kt_charts_widget_36"
              className="min-h-auto ps-4 pe-6 "
              style={{ height: "300px" }}
            >
              <Chart
                options={options}
                series={series}
                type="line"
                height={300}
              />
            </div>
          </div>
          <hr />
        </div>
      </Modal>
    </>
  );
}

export default OrganicSponRanking;
