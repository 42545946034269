import React, { useState, createContext } from "react";
export const GlobalContext = createContext();

const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    profile: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") || {})
      : {},
    back: localStorage.getItem("adminData") ? true : false,
  });

  return (
    <GlobalContext.Provider
      value={{
        data: { ...commonGlobalVal },
        updateCommonGlobalVal: (key, value, ...rest) => {
          setCommonGlobalVal({
            ...commonGlobalVal,
            [key]: value,
            ...rest?.[0],
          });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
