/* eslint-disable react/prop-types */
import React from "react";
import { TableWrap } from "./style";

export const TableLoading = (props) => {
  const { id, columns, actions, checkBoxVal, style, row = 15 } = props;
  return (
    <TableWrap>
      <div id={id} className="tweet" style={style}>
        <div className="skeleton">
          <div className="tweet-content">
            <div className="tweet-header">
              <div className="skeleton-line heading">
                <div className="heading-checkbox">
                  {checkBoxVal === undefined ? (
                    <div className="skeleton-checkbox" />
                  ) : checkBoxVal === false ? null : (
                    <div className="skeleton-checkbox" />
                  )}
                  {columns.map((index) => (
                    <div key={index} className="skeleton-column" />
                  ))}
                </div>
              </div>
            </div>
            <div style={{ height: "" }}>
              {Array(row || 15)
                ?.fill(1)
                .map((d, i) => (
                  <div key={i} className="skeleton-img">
                    <div className="tweet-footer" style={{ float: "left" }}>
                      {checkBoxVal === undefined ? (
                        <div className="skeleton-button rounded" />
                      ) : checkBoxVal === false ? null : (
                        <div className="skeleton-button rounded" />
                      )}
                    </div>
                    <div
                      className="tweet-footer"
                      style={{ float: "left", width: "14%" }}
                    >
                      {columns.map((index) => (
                        <div key={index} className="text-column" />
                      ))}
                    </div>
                    <div className="tweet-footer" style={{ float: "right" }}>
                      {actions !== undefined ? (
                        actions.map((index) => (
                          <div
                            key={index}
                            className="skeleton-button rounded"
                          />
                        ))
                      ) : (
                        <>
                          <div className="skeleton-button rounded" />
                          <div className="skeleton-button rounded" />
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </TableWrap>
  );
};
