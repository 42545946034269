import {
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UpdateModal = (props) => {
  const {
    show,
    title,
    action,
    SIGN,
    advertising_type,
    data,
    marketplace_id,
    setActionView,
    portfoliosList,
    BulkActionUpdateAction,

    GetRuleInCampaignsAnalyticsAction,
    fakeActionCampaignsAnalytics,
    selectedMarketplace,
    selectedRowKeys,
    close,
    CampaignAnalyticsBulkApplyAction,
  } = props;

  const onCloseModal = () => {
    close();
    setActionView({
      show: false,
    });
  };

  const GetRulesInCampaignsAnalyticsResponse = useSelector(
    (state) =>
      state?.CampaignsAnalytics.GetRulesInCampaignsAnalyticsResponse || {}
  );
  const CampaignAnalyticsBulkApplyResponse = useSelector(
    (state) =>
      state?.CampaignsAnalytics.CampaignAnalyticsBulkApplyResponse || {}
  );

  const [filterValues, setFilterValues] = useState({});

  const [selectedRow, setSelectedRow] = useState([]);

  const [ruleList, setRuleList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (CampaignAnalyticsBulkApplyResponse?.status === true) {
      message?.destroy();
      message.success(CampaignAnalyticsBulkApplyResponse?.message || "");
      onCloseModal();
      fakeActionCampaignsAnalytics("CampaignAnalyticsBulkApplyResponse");
    } else if (CampaignAnalyticsBulkApplyResponse?.status === false) {
      message?.destroy();
      message.error(CampaignAnalyticsBulkApplyResponse?.message || "");
      onCloseModal();
      fakeActionCampaignsAnalytics("CampaignAnalyticsBulkApplyResponse");
    }
  }, [CampaignAnalyticsBulkApplyResponse]);

  const handleApply = () => {
    const obj = {
      marketplace_id: marketplace_id,
      campaign_data: selectedRowKeys?.map((d) => ({
        [d?.campaign_id]: d?.campaign_name,
      })),
      rule_data: {
        sub_rule_ids: selectedRow,
      },
    };
    switch (action) {
      case "budget_rule":
        message.destroy();
        message.loading("Loading...", 0);
        CampaignAnalyticsBulkApplyAction(obj);
        break;
      case "bid_rule":
        message.destroy();
        message.loading("Loading...", 0);
        CampaignAnalyticsBulkApplyAction(obj);
        break;
      case "all_rule":
        message.destroy();
        message.loading("Loading...", 0);
        CampaignAnalyticsBulkApplyAction(obj);
        break;

      default:
    }
    if (Object.values(filterValues)?.length === 0) return;
    switch (action) {
      case "update_budget":
        if (parseFloat(filterValues?.new_budget) < 50) return;
        const update_budget = {
          marketplace_id: marketplace_id,
          advertising_type: advertising_type,
          action: action,
          campaign_data: data?.map((f) => ({
            campaign_id: f?.campaign_id,
            new_budget: filterValues?.new_budget,
            old_budget: f?.campaign_budget_amount,
          })),
        };
        message.destroy();
        message.loading("Loading...", 0);
        BulkActionUpdateAction(update_budget);

        break;
      case "add_portfolio":
        const add_portfolio = {
          marketplace_id: marketplace_id,
          advertising_type: advertising_type,
          action: action,
          campaign_data: data?.map((f) => ({
            campaign_id: f?.campaign_id,
            portfolio_id: filterValues?.portfolio_id,
          })),
        };
        message.destroy();
        message.loading("Loading...", 0);
        BulkActionUpdateAction(add_portfolio);

        break;

      case "update_campaign_state":
        const update_campaign_state = {
          marketplace_id: marketplace_id,
          advertising_type: advertising_type,
          action: action,
          campaign_data: data?.map((f) => ({
            campaign_id: f?.campaign_id,
            campaign_state: filterValues?.campaign_state,
          })),
        };
        message.destroy();
        message.loading("Loading...", 0);
        BulkActionUpdateAction(update_campaign_state);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (GetRulesInCampaignsAnalyticsResponse?.status === true) {
      setRuleList(GetRulesInCampaignsAnalyticsResponse?.data?.records || []);
      setLoading(false);
      fakeActionCampaignsAnalytics("GetRulesInCampaignsAnalyticsResponse");
    } else if (GetRulesInCampaignsAnalyticsResponse?.status === false) {
      setRuleList([]);
      setLoading(false);
      fakeActionCampaignsAnalytics("GetRulesInCampaignsAnalyticsResponse");
    }
  }, [GetRulesInCampaignsAnalyticsResponse]);

  useEffect(() => {
    if (action === "budget_rule") {
      setLoading(true);
      GetRuleInCampaignsAnalyticsAction({
        marketplace_id: selectedMarketplace,
        rule_type: "budget",
      });
    } else if (action === "bid_rule") {
      GetRuleInCampaignsAnalyticsAction({
        marketplace_id: selectedMarketplace,
        rule_type: "bid",
      });
      setLoading(true);
    } else if (action === "all_rule") {
      GetRuleInCampaignsAnalyticsAction({
        marketplace_id: selectedMarketplace,
        rule_type: "",
      });
      setLoading(true);
    }

    return () => {};
  }, []);

  const actionComponents = {
    update_budget: (
      <div className="my-7">
        <Input
          prefix={SIGN()}
          onChange={(e) => {
            setFilterValues({ new_budget: e.target.value });
          }}
          value={filterValues?.new_budget}
          placeholder={`Enter ${title}`}
        />
        {parseFloat(filterValues?.new_budget) < 1 ? (
          <div
            style={{
              position: "relative",

              top: "5px",
            }}
            className="d-flex align-items-center"
          >
            <small
              style={{
                color: "#ff2424",
              }}
            >
              !Enter a budget of at least {SIGN()}1.00
            </small>
            <div
              style={{
                color: "#1e93c6",
                cursor: "pointer",

                marginLeft: "10px",
              }}
              onClick={() => {
                setFilterValues({ new_budget: 1 });
              }}
            >
              Quick fix
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ),
    add_portfolio: (
      <div className="my-7">
        <Select
          options={portfoliosList?.map((d) => ({
            label: d?.name,
            value: d?.portfolioId,
          }))}
          onChange={(e) => {
            setFilterValues({ portfolio_id: e });
          }}
          value={filterValues?.portfolio_id}
          style={{ width: "100%" }}
          placeholder={`Select ${title}`}
        />
      </div>
    ),
    update_campaign_state: (
      <div className="my-7">
        <Radio.Group
          onChange={(e) => {
            setFilterValues({ campaign_state: e.target.value });
          }}
          value={filterValues?.campaign_state}
        >
          {[
            {
              label: "Enabled",
              value: "ENABLED",
            },
            {
              label: "Archived",
              value: "ARCHIVED",
            },
            {
              label: "Incomplete",
              value: "INCOMPLETE",
            },
            {
              label: "Paused",
              value: "PAUSED",
            },
          ]?.map((d, i) => (
            <Radio key={i} value={d?.value}>
              {d?.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    ),
    budget_rule: (
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "170px",
            }}
          >
            <Spin />
          </div>
        ) : ruleList?.length === 0 ? (
          <Empty />
        ) : (
          <Checkbox.Group
            value={selectedRow}
            style={{
              width: "100%",
            }}
            onChange={(e) => setSelectedRow(e)}
          >
            <div className="row">
              {ruleList?.map((d, i) => (
                <Checkbox key={i} value={d?.id}>
                  {d?.name || "-"}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        )}
      </div>
    ),
    bid_rule: (
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "170px",
            }}
          >
            <Spin />
          </div>
        ) : ruleList?.length === 0 ? (
          <Empty />
        ) : (
          <Checkbox.Group
            value={selectedRow}
            style={{
              width: "100%",
            }}
            onChange={(e) => setSelectedRow(e)}
          >
            <div className="row">
              {ruleList?.map((d, i) => (
                <Checkbox key={i} value={d?.id}>
                  {d?.name || "-"}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        )}
      </div>
    ),
    all_rule: (
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "170px",
            }}
          >
            <Spin />
          </div>
        ) : ruleList?.length === 0 ? (
          <Empty />
        ) : (
          <Checkbox.Group
            value={selectedRow}
            style={{
              width: "100%",
            }}
            onChange={(e) => setSelectedRow(e)}
          >
            <div className="row gap-2">
              {ruleList?.map((d, i) => (
                <Checkbox key={i} value={d?.id}>
                  {d?.name || "-"}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        )}
      </div>
    ),
  };

  return (
    <Modal
      title={title}
      maskClosable={false}
      open={show}
      onCancel={onCloseModal}
      footer={[
        <Button
          key="close"
          onClick={() => {
            onCloseModal();
          }}
        >
          Close
        </Button>,
        <Button key="apply" type="primary" onClick={handleApply}>
          Apply
        </Button>,
      ]}
    >
      {actionComponents?.[action]}
    </Modal>
  );
};

export default UpdateModal;
