import {
  Checkbox,
  Input,
  InputNumber,
  Segmented,
  Select,
  message,
  Upload,
  Table,
  Button,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FileSearchOutlined,
  BulbOutlined,
  SearchOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { SIGN } from "../../../../../../../../config";

const { Dragger } = Upload;
const CheckboxGroup = Checkbox.Group;

const KeywordTargeting = (props) => {
  const {
    setSelectedFilter,
    selectedFilter,
    fakeActionCampaignsManagementAnalytics,
    groupIndex,
    basicDetails,
    setBasicDetails,
    selectedMarketplaceCreate,
  } = props;

  const GetKeywordsTargetingResponse = useSelector(
    (state) => state.CampaignManagement.GetKeywordsTargetingResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [customBidValue, setCustomBidValue] = useState(0);

  useEffect(() => {
    if (GetKeywordsTargetingResponse?.status === true) {
      setList(GetKeywordsTargetingResponse?.data || []);
      setLoading(false);
      message.destroy();
      fakeActionCampaignsManagementAnalytics("GetKeywordsTargetingResponse");
    } else if (GetKeywordsTargetingResponse?.status === false) {
      setLoading(false);
      setList([]);
      message.destroy();
      message.error(GetKeywordsTargetingResponse?.message);
      fakeActionCampaignsManagementAnalytics("GetKeywordsTargetingResponse");
    }
  }, [GetKeywordsTargetingResponse]);

  const [selectedBidType, setSelectedBidType] = useState("Suggested Bid");
  const [filterBy, setFilterBy] = useState(["broad"]);
  const options = [
    { label: "Broad", value: "broad" },
    { label: "Phrase", value: "phrase" },
    { label: "Exact", value: "exact" },
  ];

  const filterComponents = {
    enter_list: (
      <div className="mt-5">
        <textarea
          className="form-control form-control-solid fs-7"
          cols={30}
          rows={4}
          placeholder="Enter ASINs seprated by comma, space or new line."
          defaultValue={""}
        />
        <div className="mt-3 text-end">
          <button className="btn btn-dark fs-7">Add</button>
        </div>
      </div>
    ),
    upload: (
      <div id="kt_tab_pane_3_upload_ex" className="mt-5" role="tabpanel">
        <p className="mb-3 mt-2 text-muted">
          Use the template to make sure your information uploads accurately.
        </p>
        <div className="fv-row">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single upload. Strictly prohibited from uploading
              product data.
            </p>
          </Dragger>
        </div>
      </div>
    ),
  };

  const addKeywordTargeting = (d, obj) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].keyword_targeting.push({
      state: "ENABLED",
      title: obj?.keyword,
      IR: obj?.search_term_impression_rank,
      IS: obj?.search_term_impression_share,
      MatchType: d,
      SuggBid: obj?.bid_info?.[d],
      Bid:
        selectedBidType === "Suggested Bid"
          ? obj?.bid_info?.[d]?.suggested_bid
          : customBidValue,
      keyrow:
        updatedData.ad_groups_data[groupIndex].keyword_targeting?.length + 1,
    });

    setBasicDetails({ ...updatedData });
  };
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };
  const columns = [
    {
      title: "Keyword",
      width: 200,
      render: (e) => {
        return (
          <div className="row">
            <div
              className="col-12 text-overflow"
              style={{ fontWeight: "700", marginBottom: "10px" }}
            >
              {e?.keyword || "-"}
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col">IS&nbsp;:</div>
                    <div className="col">
                      {e?.search_term_impression_share || 0}%
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row" style={{ borderLeft: "1px solid grey" }}>
                    <div className="col">IR&nbsp;:</div>
                    <div className="col">
                      {e?.search_term_impression_rank || 0}%
                    </div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Match type",
      width: 120,
      render: (e) => {
        return (
          <div className="d-grid justify-items-start gap-2">
            {Object.keys(e?.bid_info || {})?.map((d) => {
              const index = filterBy?.findIndex((f) => f === d?.toLowerCase());
              if (index === -1) return;
              return (
                <div style={{ background: "#21aae620", padding: "7px" }}>
                  <Tag color="blue-inverse">{d}</Tag>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      width: 150,
      render: (e) => {
        return (
          <div className="d-grid justify-items-start gap-2">
            {Object.values(e?.bid_info || {})?.map((d) => {
              const index = filterBy?.findIndex(
                (f) => f === d?.match_type?.toLowerCase()
              );
              if (index === -1) return;
              return (
                <div style={{ background: "#21aae620", padding: "7px" }}>
                  {SIGN()}
                  {d?.suggested_bid || "0"}
                </div>
              );
            })}
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      render: (e) => {
        return (
          <>
            <div className="d-grid justify-items-start gap-2">
              {Object.keys(e?.bid_info || {})?.map((d) => {
                const index = filterBy?.findIndex(
                  (f) => f === d?.toLowerCase()
                );

                const getSelected = () => {
                  return basicDetails?.ad_groups_data?.[
                    groupIndex
                  ]?.keyword_targeting?.filter((d) => d?.title === e?.keyword);
                };

                if (index === -1) return;
                return (
                  <div style={{ padding: "7px" }}>
                    <Button
                      onClick={() => addKeywordTargeting(d, e)}
                      type="primary"
                      size="small"
                      disabled={
                        getSelected()?.filter((t) => t?.MatchType === d)
                          ?.length !== 0
                      }
                    >
                      {getSelected()?.filter((t) => t?.MatchType === d)
                        ?.length !== 0
                        ? "Added"
                        : "Add"}
                    </Button>
                  </div>
                );
              })}
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div className="row">
      <div className=" col-12 add-products-title d-flex align-items-center justify-content-between">
        <h3>
          <b>Keyword List</b>
        </h3>
        <div className="add-products-filters">
          <Segmented
            className="step-1-segment-view"
            style={{ marginBottom: ".5rem" }}
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e)}
            options={[
              {
                value: `suggested`,
                label: "Suggested",
                icon: <BulbOutlined />,
              },

              {
                value: `enter_list`,
                label: "Enter List",
                icon: <FileDoneOutlined />,
              },
              {
                value: `upload`,
                label: "Upload",
                icon: <CloudUploadOutlined />,
              },
            ]}
          />
        </div>
      </div>
      <div className="col-12 px-5">
        <div className="row mt-5 d-flex align-items-center">
          <div className="col-4">Bid</div>
          <div className="col-8 d-flex align-items-center">
            <Select
              onChange={(e) => setSelectedBidType(e)}
              value={selectedBidType}
              options={["Suggested Bid", "Custom Bid"]?.map((d) => {
                return { label: d, value: d };
              })}
              placeholder="Select Bid"
              style={{ width: "100%" }}
            />
            {selectedBidType === "Custom Bid" && (
              <InputNumber
                onChange={(e) => setCustomBidValue(e)}
                value={customBidValue}
                className="ms-5"
                style={{ width: "100%" }}
                placeholder="Enter Custom Bid"
              />
            )}
          </div>
        </div>
        <div className="row my-7">
          <div className="col-4">Filter By</div>
          <div className="col-8 ">
            <CheckboxGroup
              options={options}
              value={filterBy}
              onChange={(e) => {
                setFilterBy(e);
              }}
            />
          </div>
        </div>
        {filterComponents?.[selectedFilter]}
      </div>
      <div className="col-12 mt-5">
        <Table
          scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
          dataSource={list}
          loading={loading}
          columns={columns}
          pagination={false}
          rowKey={"keyword"}
        />
      </div>
    </div>
  );
};

export default KeywordTargeting;
