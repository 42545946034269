import React, { useState } from "react";
import { Modal, Select, Input, Button, Radio, message } from "antd";
import { SIGN } from "../../../../config";
import { useEffect } from "react";

const { Option } = Select;

const FilterModal = (props) => {
  const {
    show,
    componentType,
    data,
    modalLabel,
    apiKey,
    setFiltersModals,
    sign,
    SIGN,
    setFilterData,
    labelGet,
    filterLabel,
    filterValues,
    setFilterValues,
  } = props;

  const onCloseModal = () => {
    setFiltersModals({
      show: false,
    });
  };

  const handleApply = () => {
    if (
      Object?.values(filterValues)?.length === 0 ||
      Object?.values(filterValues)?.filter((d) => !d)?.length > 0
    ) {
      message.destroy();
      return message.warning("Please fill value");
    }
    if (
      componentType === "custom" &&
      Object?.values(filterValues)?.length < 2
    ) {
      message.destroy();
      return message.warning("Please fill value");
    }
    setFilterData(filterValues);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const componentsFilter = {
    select: (
      <div className="my-10">
        <Select
          options={data?.map((d) => ({
            label: d?.name,
            value: d?.portfolioId,
          }))}
          filterOption={filterOption}
          showSearch
          placeholder={`Select ${modalLabel}`}
          style={{ width: "100%" }}
          onChange={(e, _) => {
            labelGet({
              label: modalLabel,
              value: _?.label,
            });
            setFilterValues({
              [apiKey]: e,
            });
          }}
          value={filterValues?.[apiKey] || null}
        />
      </div>
    ),
    radio: (
      <div className="my-10">
        <Radio.Group
          onChange={(e) => {
            labelGet({
              label: modalLabel,
              value: e.target.value,
            });
            setFilterValues({
              [apiKey]: e.target.value,
            });
          }}
          value={filterValues?.[apiKey] || null}
        >
          {data?.map((d, i) => (
            <Radio key={i} value={d?.value}>
              {d?.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    ),
    custom: (
      <div className="row">
        <div className="col-5">
          <label>Type:</label>
          <Select
            placeholder={`Select ${modalLabel}`}
            style={{ width: "100%" }}
            options={data}
            onChange={(e, _) => {
              labelGet({
                label: modalLabel,
                value: e + " " + (sign ? SIGN() : "") + filterValues?.[apiKey],
              });
              setFilterValues({
                ...filterValues,
                filter_condition: e,
              });
            }}
            value={filterValues?.filter_condition || null}
          />
        </div>
        <div className="col-7">
          <label>Value:</label>
          <Input
            prefix={sign ? SIGN() : ""}
            placeholder={`Enter ${modalLabel}`}
            onChange={(e) => {
              labelGet({
                label: modalLabel,
                value:
                  filterValues?.filter_condition +
                  " " +
                  (sign ? SIGN() : "") +
                  e.target.value,
              });
              setFilterValues({
                ...filterValues,
                [apiKey]: e.target.value,
              });
            }}
            value={filterValues?.[apiKey] || null}
          />
        </div>
      </div>
    ),
  };

  return (
    <Modal
      title={modalLabel}
      open={show}
      maskClosable={false}
      onCancel={onCloseModal}
      footer={[
        <Button
          key="close"
          onClick={() => {
            onCloseModal();
          }}
        >
          Close
        </Button>,
        <Button key="apply" type="primary" onClick={handleApply}>
          Apply
        </Button>,
      ]}
    >
      {componentsFilter?.[componentType]}
    </Modal>
  );
};

export default FilterModal;
