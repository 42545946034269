export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PORTFOLIOS_LIST_SUCCESS":
    case "GET_PORTFOLIOS_LIST_ERROR":
      return {
        ...state,
        GetPortfoliosListResponse: action.updatePayload,
      };
    case "GET_CAMPAIGN_CONFIG_SUCCESS":
    case "GET_CAMPAIGN_CONFIG_ERROR":
      return {
        ...state,
        GetCampaignConfigResponse: action.updatePayload,
      };
    case "GET_PRODUCTS_LIST_SUCCESS":
    case "GET_PRODUCTS_LIST_ERROR":
      return {
        ...state,
        GetProductsListResponse: action.updatePayload,
      };
    case "CREATE_CAMPAIGNS_SUCCESS":
    case "CREATE_CAMPAIGNS_ERROR":
      return {
        ...state,
        CreateCampaignsResponse: action.updatePayload,
      };
    case "LIST_CAMPAIGNS_SUCCESS":
    case "LIST_CAMPAIGNS_ERROR":
      return {
        ...state,
        ListCampaignsResponse: action.updatePayload,
      };
    case "LIST_STRATEGIES_SUCCESS":
    case "LIST_STRATEGIES_ERROR":
      return {
        ...state,
        ListStrategiesResponse: action.updatePayload,
      };
    case "CREATE_CAMPAIGNS_STRATEGY_SUCCESS":
    case "CREATE_CAMPAIGNS_STRATEGY_ERROR":
      return {
        ...state,
        CreateCampaignsStrategyResponse: action.updatePayload,
      };
    case "GET_PRODUCT_TARGETING_SUCCESS":
    case "GET_PRODUCT_TARGETING_ERROR":
      return {
        ...state,
        GetProductTargetingResponse: action.updatePayload,
      };
    case "CREATE_CAMPAIGNS_TARGETING_SUGGESTION_SUCCESS":
    case "CREATE_CAMPAIGNS_TARGETING_SUGGESTION_ERROR":
      return {
        ...state,
        CreateCampaignsTargetionSuggestionResponse: action.updatePayload,
      };
    case "GET_KEYWORDS_TARGETING_SUCCESS":
    case "GET_KEYWORDS_TARGETING_ERROR":
      return {
        ...state,
        GetKeywordsTargetingResponse: action.updatePayload,
      };
    case "GET_BID_SUGGESTIONS_SUCCESS":
    case "GET_BID_SUGGESTIONS_ERROR":
      return {
        ...state,
        GetBidSuggestionResponse: action.updatePayload,
      };
    case "CAMPAIGNS_STRATEGIES_UPDATE_SUCCESS":
    case "CAMPAIGNS_STRATEGIES_UPDATE_ERROR":
      return {
        ...state,
        CampaignsStrategiesUpdateResponse: action.updatePayload,
      };
    case "CAMPAIGNS_STRATEGIES_DELETE_SUCCESS":
    case "CAMPAIGNS_STRATEGIES_DELETE_ERROR":
      return {
        ...state,
        CampaignsStrategiesDeleteResponse: action.updatePayload,
      };
    case "CAMPAIGNS_UPDATE_SUCCESS":
    case "CAMPAIGNS_UPDATE_ERROR":
      return {
        ...state,
        CampaignsUpdateResponse: action.updatePayload,
      };
    case "CAMPAIGNS_DELETE_SUCCESS":
    case "CAMPAIGNS_DELETE_ERROR":
      return {
        ...state,
        CampaignsDeleteResponse: action.updatePayload,
      };
    case "GET_AD_GROUP_LIST_SUCCESS":
    case "GET_AD_GROUP_LIST_ERROR":
      return {
        ...state,
        GetAdGroupListResponse: action.updatePayload,
      };
    case "GET_AD_ALL_GROUP_LIST_SUCCESS":
    case "GET_AD_ALL_GROUP_LIST_ERROR":
      return {
        ...state,
        GetAdGroupAllListResponse: action.updatePayload,
      };
    case "UPDATE_AD_GROUP_SUCCESS":
    case "UPDATE_AD_GROUP_ERROR":
      return {
        ...state,
        UpdateAdGroupResponse: action.updatePayload,
      };
    case "FAKE_ACTION_CAMPAIGNS_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
