import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
.rc-virtual-list-scrollbar-thumb {
    background: rgb(39, 165, 224) !important;
}
#campign-update {
    .ant-input-number-handler-wrap, .ant-input-number {
        background: #f2f9fe !important;
        border: 1px solid #21aae6 !important;
    }
    .ant-input-number-disabled .ant-input-number-input {
        color: inherit !important;
    }
    .ant-picker .ant-picker-input >input[disabled] {
        color: rgba(0, 0, 0, 0.25) !important;
    }
}
.loader {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 5px solid var(--kt-primary);
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: rot5 1s infinite;
}

@keyframes rot5 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
    border-top-color: var(--kt-primary);
    border-bottom-color: var(--kt-primary);
    border-right-color: transparent;
    border-left-color: transparent;
  }

  100% {
    transform: rotate(360deg);
  }
}
/* loading end */


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



#root {
    height: 100%;
}

#root > .globalHeight {
    height: 100%;
}



.ant-select-selection-placeholder, .ant-select {
    color: var(--bs-gray-700) !important;
    font-weight: 500 !important;
    font-size: .95rem !important;
    .anticon {
        svg {
            color: var(--bs-gray-700) !important;
            background: var(--bs-body-bg) !important;
        }
    }
}
.ant-select-selection-placeholder .anticon svg, .ant-select .anticon svg{
    background: unset !important;
}
.ant-select-selection-placeholder{
    font-weight: 600 !important;
}
.ant-select-selection-item{
    font-size: small !important;
}
.ant-select-open {
    .ant-select-selection-item {
        color: var(--bs-gray-700) !important;
    }
}
.ant-empty-description {
    color: var(--bs-body-color) !important;
}
.ant-select-dropdown {
    background-color: var(--bs-dropdown-bg) !important;
    box-shadow: var(--bs-dropdown-box-shadow) !important;
    color: var(--bs-body-color) !important;
    .ant-select-item-option-content {
        color: var(--bs-gray-700) !important;
    }
    .ant-select-item-option-active {
        background-color: var(--bs-component-hover-bg) !important;
        color: var(--bs-component-hover-color) !important;
        transition: color .2s ease !important;
        .ant-select-item-option-content {
            color: var(--bs-component-hover-color) !important;
        }
    }
}

.ant-picker-input {
    input {
    color: var(--bs-gray-600) !important;
    }
    svg {
            color: var(--kt-primary) !important;
            background: var(--bs-body-bg) !important;
        }
}
.ant-picker-panel-container  {
    background-color: var(--bs-body-bg)  !important;
    .ant-picker-time-panel-cell-inner {
    color: var(--bs-gray-600) !important;
    }
    .ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
            background-color: var(--kt-primary) !important;
            color: var(--kt-primary-inverse) !important;
        }
    }
}




.ant-select-item-group {
    color: var(--bs-gray-700) !important;
}

:where(.css-dev-only-do-not-override-1jt3v7g).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--bs-body-bg)  !important;
    border-color: var(--bs-gray-300)  !important;
    color: var(--bs-gray-700) !important;
}


.overlayLoading {
    margin: auto;
    width: fit-content;
    height: fit-content;
    inset: 0;
    position: absolute;
    z-index: 99999999;
}

.ant-radio-group {
    label {
        span {
            color: var(--kt-input-solid-color) !important;
        }
    }
}

#kt_modal_add, #kt_modal_add_customer {
    background: #0000004f;
}

.ant-upload-list-item-error {
    border-color: var(--kt-light-inverse) !important;
}



.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
}
.rc-virtual-list-scrollbar-thumb{
    width: 50% !important;
}
.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-presets ul {
    color: var(--bs-gray-700) !important;
}
.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels{
    .ant-picker-header button{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table thead tr th{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell{
        color: var(--bs-gray-400) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell.ant-picker-cell-in-view{
        color: var(--bs-gray-700) !important;
    }
    .ant-picker-body table tbody tr {
        .ant-picker-cell-in-range:before{
            // background-color: var(--bs-component-hover-bg) !important;
        }
        .ant-picker-cell-range-start:before{
            background: #1677ff;
        }
        .ant-picker-cell-range-end:before{
            background: #1677ff;
        }
        .ant-picker-cell-range-hover-start:before{
            background: unset;
        }
    }
    .ant-picker-body table tbody tr .ant-picker-cell-in-range .ant-picker-cell-inner{
        color: var(--bs-component-hover-color) !important;
    }
}
.ant-dropdown .ant-dropdown-menu {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active{
        background-color: var(--bs-primary-light);
        transition: color .2s ease;
        .menu-item{
            border-radius: 0.475rem;
            .menu-link{
                color: var(--bs-primary) !important;
            }
        }
    }
    .ant-dropdown-menu-item .menu-item a{
        color: var(--bs-gray-800) !important;
        padding: 0rem 0rem;
    }
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid var(--bs-border-color);
    border-top-left-radius: 0.475rem;
    border-top-right-radius: 0.475rem;
}
.custom-modal_ {
    .ant-collapse-item{
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.02);
        padding-left: 10px;
        margin-bottom: 10px;
    }
    .ant-collapse-content-box {
        background: rgba(0, 0, 0, 0.02);
    }
    .ant-tooltip .ant-tooltip-arrow:before {
        background: var(--bs-body-bg) !important;
    }
}
.custom-modal .modal-body {
    margin-top: 8px;
    height: 500px;
    overflow: auto;
}
.accordion .ant-collapse {
    .ant-collapse-item .ant-collapse-header {
        padding: 10px 0px;
        .ant-collapse-expand-icon {
            .accordion-icon-off {
                color: var(--bs-text-muted)
            }
            .accordion-icon-on{
                color: var(--bs-primary);
            }
        }
    }
    .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{
        border-radius: 8px;
        background: var(--bs-primary-light);
        padding-left: 10px;
    }

}
.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
    color: var(--bs-gray-800) !important;
    background-color: var(--bs-body-bg) !important;
}
  
.ant-tooltip .ant-tooltip-arrow:before {
    background: var(--bs-body-bg) !important;
}

.ant-dropdown-menu-item-group-title{
    font-weight: 700;
    color: var(--bs-text-dark)!important;
    border-bottom: 1px solid var(--bs-border-color);
    margin-bottom: 0.75rem!important;

}
/* antd model custome css */
.ant-modal-content {
  color:  var(--bs-modal-color) !important;
  background-color: var(--bs-body-bg) !important;
  border: 0 solid var(--bs-border-color-translucent) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
  pointer-events: auto;

}
.ant-modal-close {
    color: var(--bs-btn-close-color) !important;
    background-position: center !important;
  }


   .custom-select {
        .ant-select-selection-item {
        display: inline-flex;
            align-items: center;
        
            height: 19px !important;
            flex-direction: row-reverse;
            background-color: var(--bs-gray-300) !important;
        
            font-size: 0.85rem;
            margin-left: 1.1rem;
        
        
            .anticon-close {
                position: relative;
                top: -1px;
                left: -2px;
            }
        }
    }
    .light-range-picker {
        background-color: rgb(255 255 255) !important;
        border: 1px solid #d9d9d9 !important;
        input {
            font-size: 12px !important;
        }
    }
    .ant-select-selector, .ant-picker {
        background-color: #f9f9f9;
        border: 1px solid #d9d9d900;   
        .ant-select-selection-placeholder, .ant-picker-input > input::placeholder {
                    color: var(--bs-text-gray-600)!important;
                font-weight: 500 !important;
                font-family: inherit !important;
                font-size: 0.95rem !important;
        }
    }
    .ant-select-item-option-content, .ant-picker-panels > .ant-picker-content {
            color: var(--bs-gray-700) !important;
            transition: color .2s ease !important;
            font-size: 13px!important;
            font-weight: 400 !important;
            font-family: Inter,Helvetica,sans-serif !important;
            margin: 0 0 !important;
            &:hover {
                background-color: var(--bs-component-hover-bg) !important;
                color: var(--bs-component-hover-color) !important;
                transition: color .2s ease !important;
            }
    }
    .ant-select-selection-placeholder, .ant-picker-input-active {
        color: #8b90a3!important;
        font-size: .95rem!important;
    }
    .ant-table-wrapper .ant-table-thead >tr>th {
        background: #ffffff  !important;
        border-bottom: 1px dashed #e4e6ed !important;
    }
    .ant-btn-primary {
        background-color: #22a9e6;
    }
    .ant-picker-cell-in-range::before {
        background-color: #1677ff42 !important;
    }

    .ant-picker-middle {
        background-color: #ffffff !important;
        border-color: #d9d9d9 !important;
    }
    .ant-input,  {
        &::placeholder {
            font-size: 12px !important;
            color: #000000 !important;
        }
    }
    .text-overflow {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    .ant-input-number-input-wrap > input, .ant-picker-input > input {
        font-size: 13px !important;
        color: #000000 !important;
    }


    .ant-select-selector, .ant-picker, .ant-input, .all-search {
        background-color: #22a9e612 !important;
        border: 1px solid #22a9e6 !important;
    }
    .ant-input-affix-wrapper {

        background-color: #22a9e612 !important;
        border: 1px solid #22a9e6 !important;
        .ant-input {
            background-color: transparent !important;
            border: 1px solid transparent !important;
        }
    }
    .text-container {
        width: 200px; /* Set the width of the container */
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide the overflow */
        text-overflow: ellipsis; /* Show ellipsis (...) when text overflows */
      }


      div::-webkit-scrollbar-thumb, main::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, span::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
        background-color: #21aae6 !important;
      }
      .apexcharts-xaxistooltip.apexcharts-theme-light {
        background: #FFF !important;
      }

      .ant-upload-list-item-actions {

          .anticon-eye {
              display: none;
        }
    }
    .ant-table-cell {
        text-transform: capitalize;
    }
    .product-body {
        height: calc(100vh - 410px);
        overflow: auto;
    }
  .ant-select-item-option-content {
    text-transform: capitalize;
  }
  .two-line {
    display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 2; 
            overflow: hidden; 
            text-overflow: ellipsis; 
  }
.ant-input::placeholder {
    color: #7e8299 !important;
}
`;

export default AppStyleWrapper;
