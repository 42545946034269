import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../../modules/pages/login/Login";
import {
  loginAction,
  forgotPasswordAction,
  fakeActionAuth,
  resetPasswordAction,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  LoginResponse: state.Auth.LoginResponse,
  ForgotPasswordResponse: state.Auth.ForgotPasswordResponse,
  ResetPasswordResponse: state.Auth.ResetPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAction,
      forgotPasswordAction,
      resetPasswordAction,
      fakeActionAuth,
    },
    dispatch
  );

const Login_ = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login_;
