import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Popconfirm, Table, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Icons from "../../../../components/icons";
import Loading from "../../../../components/loading";
import Pagination from "../../../../components/pagination";
// import ASINTable from "../../../../components/table";
import { DefaultPerPage, testArr, timeSince } from "../../../../config";
import UserAdd from "./lib/user-add";
import Wrapper from "./style";
import { TableLoading } from "../../../../components/table-animation";
import { GlobalContext } from "../../../../commonContext";
import { useContext } from "react";
import NoData from "../../../../components/no-data";
import { switchUser, userList } from "./lib/fakeData";

export default function (props) {
  const history = useHistory();
  const { getUserAction, switchUserAction, deleteUserAction, fakeActionUser } =
    props;
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [data, setData] = useState([]);

  const [userModal, setUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const UserListResponse = useSelector(
    (state) => state?.User?.UserListResponse || {}
  );
  const SwitchUserResponse = useSelector(
    (state) => state?.User?.SwitchUserResponse || {}
  );
  const DeleteUserResponse = useSelector(
    (state) => state.User.DeleteUserResponse || {}
  );
  const contextValue = useContext(GlobalContext);
  const getList = () => {
    setLoading(true);
    setPage(1);
    setPageSize(DefaultPerPage);
    getUserAction({
      page: 1,
      perPage: DefaultPerPage,
    });
  };

  useEffect(() => {
    getList();

    return () => {};
  }, []);

  useEffect(() => {
    if (UserListResponse?.status === true) {
      setLoading(false);
      setData(UserListResponse.data.records);
      setTotalPage(UserListResponse.data.pagination.totalCount);
      fakeActionUser("UserListResponse");
    } else if (UserListResponse?.status === false) {
      setLoading(false);
      message.warning(UserListResponse?.message);

      setLoading(false);
      setData(userList.data.records);
      setTotalPage(userList.data.pagination.totalCount);
      fakeActionUser("UserListResponse");
    }
  }, [UserListResponse]);

  useEffect(() => {
    if (SwitchUserResponse.status === true) {
      message.destroy();
      let adminToken = localStorage.getItem("token");
      let adminData = localStorage.getItem("user");
      const data = {
        token: adminToken,
        data: adminData,
      };
      localStorage.setItem("adminData", JSON.stringify(data));
      contextValue?.updateCommonGlobalVal(
        "profile",
        SwitchUserResponse?.data?.user_data,
        { back: true }
      );

      localStorage.setItem(
        "user",
        JSON.stringify(SwitchUserResponse?.data?.user_data)
      );
      localStorage.setItem(
        "userType",
        SwitchUserResponse?.data?.user_data?.user_type
      );

      localStorage.setItem(
        "token",
        SwitchUserResponse?.data?.auth_token_data?.access_token
      );
      message.success(SwitchUserResponse.message);

      fakeActionUser("SwitchUserResponse");
      history.replace("/");
    } else if (SwitchUserResponse.status === false) {
      message.destroy();
      message.error(SwitchUserResponse.message);
      fakeActionUser("SwitchUserResponse");
    }
  }, [SwitchUserResponse]);

  useEffect(() => {
    if (DeleteUserResponse.status) {
      message.destroy();
      message.success(DeleteUserResponse?.message);
      getList();
      fakeActionUser("DeleteUserResponse");
    } else if (DeleteUserResponse.status === false) {
      message.destroy();
      message.error(DeleteUserResponse?.message);
      fakeActionUser("DeleteUserResponse");
    }
  }, [DeleteUserResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    getUserAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    getUserAction({
      page: 1,
      perPage: e,
    });
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }
    return color;
  };

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Name",
      align: "left",
      render: (item) => {
        const color_ = getRandomColor();
        return (
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <Link>
                <div className="symbol-label fs-3 text-danger bg-light-danger">
                  {item?.name?.[0] || "NA"}
                </div>
              </Link>
            </div>
            <div className="d-flex flex-column">
              <Link className=" admin_user_name_title fw-bolder text-hover-primary mb-1">
                {item?.name || "NA"}
              </Link>
              <span className="admin_user_mail_title">
                {item?.email || "NA"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Contact No",
      align: "left",
      render: (text) => {
        return <span>{text?.contact_no || "N/A"}</span>;
      },
    },

    {
      title: "Switch User",
      render: (text) => {
        return (
          <span
            style={{
              pointerEvents: parseInt(text?.user_type) === 1 ? "none" : "auto",
              opacity: parseInt(text?.user_type) === 1 ? 0.5 : 1,
            }}
            onClick={() => {
              if (parseInt(text?.user_type) === 1) {
                message.destroy();
                message.warning("You cannot switch yourself!");
                return;
              }
              message.destroy();
              message.loading("Loading...");
              switchUserAction(text.id);
            }}
            className="d-flex align-items-center fw-bolder switch_user cursor-pointer"
          >
            <span className="svg-icon svg-icon-success svg-icon-2x me-2">
              <Icons type="userSwitch" />
            </span>
            Switch User
          </span>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(parseInt(text.updated_at) * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(parseInt(text.updated_at))})
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        return (
          <div
            className="d-flex justify-content-center"
            style={{
              pointerEvents: parseInt(text?.user_type) === 1 ? "none" : "auto",
              opacity: parseInt(text?.user_type) === 1 ? 0.5 : 1,
            }}
            id="userActionIcons"
          >
            <div
              className="Edit-Icon-Antd d-flex"
              onClick={() => {
                if (parseInt(text?.user_type) === 1) {
                  return;
                }
                setUserModal(true);
                setSelectedRow(text);
              }}
            >
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById("user-table-admin").parentNode
                }
                title="Edit"
                placement="left"
              >
                <Button type="dashed">
                  <EditTwoTone style={{ position: "relative", top: -3 }} />
                </Button>
              </Tooltip>
            </div>
            <Popconfirm
              title="Are you sure to delete this user?"
              placement="left"
              cancelText="No"
              getPopupContainer={() =>
                document.getElementById("user-table-admin").parentNode
              }
              okText="Yes"
              onConfirm={() => {
                if (parseInt(text?.user_type) === 1) {
                  return;
                }
                if (text?.id) {
                  message.loading("Deleting...", 0);
                  deleteUserAction(text?.id);
                }
              }}
            >
              <div className="Delete-Icon-Antd d-flex">
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById("user-table-admin").parentNode
                  }
                  title="Delete"
                  placement="left"
                >
                  <Button type="dashed" className="ms-3">
                    <DeleteTwoTone style={{ position: "relative", top: -3 }} />
                  </Button>
                </Tooltip>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Wrapper
        className="fadeInRight content d-flex flex-column flex-column-fluid p-4"
        id="kt_content"
      >
        <div className="container-fluid" id="kt_content_container">
          <h2 className="mb-6 smallScreenPageHeader">Manage Seller Users </h2>
          <div className="card">
            <div className="card-header ml-auto align-items-center">
              <div className="card-title"></div>
              <div className="card-toolbar">
                <Button
                  type="primary"
                  // className="antd-success"
                  size="large"
                  onClick={() => {
                    setUserModal(true);
                    setSelectedRow({});
                  }}
                >
                  Create User
                </Button>
              </div>
            </div>
            <div className="separator separator-dashed mb-0" />
            <div className="card-body py-4" id="user-table-admin">
              <div className="table-responsive">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : data?.length !== 0 ? (
                  <Table
                    columns={columns}
                    fixed="top"
                    dataSource={data}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />
                ) : (
                  <NoData />
                )}
              </div>
              <Pagination
                loading={loading || data?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      {userModal && (
        <UserAdd
          show={userModal}
          data={selectedRow}
          close={() => {
            setUserModal(false);
            setSelectedRow({});
          }}
          getList={() => {
            getList();
          }}
          {...props}
        />
      )}
    </>
  );
}
