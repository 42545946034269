import { Button, Table, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CampaignPreview = (props) => {
  const {
    basicDetails,
    CreateCampaignAction,
    setBasicDetails,
    fakeActionCampaignsManagementAnalytics,
    loading,
    setLoading,
  } = props;
  const [submit, setSubmit] = useState(false);
  const [newData, setNewData] = useState(basicDetails);
  function getFirstLetter(str) {
    return str.charAt(0).toUpperCase();
  }

  useEffect(() => {
    setNewData(basicDetails);
  }, [basicDetails]);

  const columnsExpand = [
    {
      title: "Ad Group Name",
      render: (text) => {
        return (
          <div>
            <span className="badge badge-light-success w-35px justify-content-center fs-base me-2">
              {getFirstLetter(newData?.campaign_data?.targeting_type)}
            </span>
            <a className="fw-bold">
              {(text?.name || "") + " | " + (text?.date || "") || "-"}
            </a>
          </div>
        );
      },
    },
    {
      title: "Default Bid",
      render: (text) => {
        return <div>{text?.default_bid || "0"}</div>;
      },
    },
    {
      title: "Targeting",
      render: (text) => {
        return <div>{newData?.campaign_data?.targeting_type}</div>;
      },
    },
    {
      title: "Created Date",
      render: (text) => {
        return <div>{text?.date || "-"}</div>;
      },
    },
  ];
  const columns = [
    {
      title: "",
      width: 100,
      render: (text) => {
        return (
          <div>
            <span className="badge badge-light-primary w-35px justify-content-center fs-base">
              {text?.campaign_ad_type || "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Campaign Name",
      width: 400,
      render: (text) => {
        return (
          <a className="fw-bold">
            {(text?.name || "") + " | " + (text?.suffix || "") || "-"}
          </a>
        );
      },
    },
    {
      title: "Daily Budget",
      render: (text) => {
        return <div>{text?.daily_budget?.toLocaleString()}</div>;
      },
    },
    {
      title: "Created Date",
      render: (text) => {
        return <div>{moment(new Date()).format("YYYY/MM/DD")}</div>;
      },
    },
  ];

  const expandableRow = () => (
    <Table
      dataSource={newData?.ad_groups_data?.map((d, i) => ({
        ...d,
        key: i,
      }))}
      columns={columnsExpand}
      pagination={false}
    />
  );
  const CreateCampaignsResponse = useSelector(
    (state) => state.CampaignManagement.CreateCampaignsResponse || {}
  );

  useEffect(() => {
    if (CreateCampaignsResponse?.status === true) {
      message.destroy();
      message.success(CreateCampaignsResponse?.message);

      fakeActionCampaignsManagementAnalytics("CreateCampaignsResponse");
      window.location.reload();
    } else if (CreateCampaignsResponse?.status === false) {
      message.destroy();
      message.error(CreateCampaignsResponse?.message);
      setLoading(false);
      setSubmit(false);
      fakeActionCampaignsManagementAnalytics("CreateCampaignsResponse");
    }
  }, [CreateCampaignsResponse]);

  const onSave = () => {
    setLoading(true);
    if (
      !newData?.campaign_data?.suffix ||
      !newData?.campaign_data?.name ||
      !newData?.campaign_data?.portfolio_id ||
      !newData?.campaign_data?.start_date ||
      !newData?.campaign_data?.daily_budget ||
      newData?.ad_groups_data?.filter((d) => !d?.name || !d?.date)?.length !==
        0 ||
      newData?.ad_groups_data?.filter((d) => !d?.default_bid)?.length !== 0 ||
      parseFloat(newData?.campaign_data?.daily_budget) < 1
    ) {
      return;
    }

    localStorage.setItem("CampaignData", JSON.stringify(newData));
    const obj = newData;

    // Mix the name and suffix
    const mixedNameAndSuffix = `${obj.campaign_data.name || ""} - ${
      obj.campaign_data.suffix || ""
    }`;

    // Update the campaign data with the mixed name and suffix
    obj.campaign_data.name = mixedNameAndSuffix;
    delete obj.campaign_data.suffix;

    obj.ad_groups_data.forEach((adGroup) => {
      // Join the name and date with a separator (e.g., "-")
      const joinedNameAndDate = `${adGroup.name || ""} - ${adGroup.date || ""}`;

      // Update the name of the ad group with the joined value
      adGroup.name = joinedNameAndDate;

      // Optionally, remove the "date" property if you want to remove the date
      delete adGroup.date;

      // Loop through product_ads in the ad group
      adGroup.product_ads.forEach((productAd) => {
        // Update the existing product_ad object with only the desired keys
        productAd.asin = productAd.asin1 || productAd?.asin;
        productAd.sku = productAd.seller_sku || productAd?.sku;
        productAd.state = productAd.state || productAd?.state;

        // Optionally, delete the unnecessary keys if needed
        delete productAd.asin1;
        delete productAd.seller_sku;
        delete productAd.id;
        delete productAd.image_url;
        delete productAd.item_name;
        delete productAd.marketplace;
        delete productAd.marketplace_id;
        delete productAd.status;
      });
    });

    // Now, obj should contain the updated data
    if (newData?.campaign_data?.targeting_type === "MANUAL") {
      if (obj?.manualType === "keyword") {
        obj.ad_groups_data.forEach((adGroup) => {
          delete adGroup.product_targeting;
          delete adGroup.product_targeting_manual;
          adGroup?.keyword_targeting?.forEach((keyword) => {
            keyword.keyword = keyword?.title;
            keyword.bid = keyword?.Bid;
            keyword.match_type = keyword?.MatchType;
            keyword.state = "ENABLED";

            delete keyword.Bid;
            delete keyword.IR;
            delete keyword.IS;
            delete keyword.MatchType;
            delete keyword.SuggBid;
            delete keyword.keyrow;
            delete keyword.title;
          });
        });
        setSubmit(true);

        setBasicDetails(JSON.parse(localStorage.getItem("CampaignData")));
        CreateCampaignAction(obj);
      } else {
        obj.ad_groups_data.forEach((adGroup) => {
          delete adGroup.keyword_targeting;
          delete adGroup.product_targeting;
          adGroup?.product_targeting_manual?.forEach((product) => {
            product.expression_type = product?.expression_type;
            product.expression_value = product?.expression_value;
            product.state = "ENABLED";
            product.bid = product?.bid;
            delete product.Type;

            delete product.category_id;
            delete product.category_name;
            delete product.category_path;
            delete product.category_translated_name;
            delete product.category_translated_path;
            delete product.key;
            delete product.keyrow;
            delete product.min_bid;
            delete product.max_bid;
            delete product.parent_category_id;
            delete product.products;
            delete product.suggested_bid;
            delete product.typeP;
            delete product.recommendedAsin;
          });
          adGroup.product_targeting = adGroup?.product_targeting_manual;
          delete adGroup.product_targeting_manual;
        });

        setSubmit(true);
        setBasicDetails(JSON.parse(localStorage.getItem("CampaignData")));
        CreateCampaignAction(obj);
      }
    } else {
      setSubmit(true);

      setBasicDetails(JSON.parse(localStorage.getItem("CampaignData")));
      CreateCampaignAction(obj);
    }
  };
  return (
    <>
      <Table
        dataSource={[{ ...newData?.campaign_data, key: 1 }]}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: expandableRow,
        }}
      />
      <div className="d-flex align-items-center justify-content-end">
        <Button
          loading={submit}
          onClick={() => onSave()}
          type="primary"
          className="mt-5 "
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CampaignPreview;
