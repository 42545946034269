import { Modal } from "antd";
import React, { useEffect } from "react";
import Icons from "../../../../components/icons";

const CampaignsView = (props) => {
  const { visible, handleCancel, selectedCamp } = props;

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal
      title="Campaigns List"
      maskClosable={false}
      centered
      open={visible}
      onOk={() => handleCancel()}
      wrapClassName="custom-modal" // Apply custom CSS class
      onCancel={() => handleCancel()}
      closeIcon={<Icons type="close" />}
      width={700}
      footer={[
        // <button
        //   type="button"
        //   className="btn btn-danger fs-7"
        //   style={{ marginRight: "10px" }}
        // >
        //   Exclude
        // </button>,
        <button
          onClick={() => handleCancel()}
          type="button"
          className="btn btn-primary fs-7"
          data-bs-dismiss="modal"
        >
          Close
        </button>,
      ]}
    >
      <div
        className="scroll-y pe-7 pt-4"
        id="kt_modal_add_customer_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_customer_header"
        data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
        data-kt-scroll-offset="300px"
        style={{ maxHeight: "682px" }}
      >
        {selectedCamp?.map((d, i) => (
          <div className="form-check mb-4" key={i}>
            <input
              className="form-check-input d-none"
              type="checkbox"
              defaultValue
              id="flexCheckDefault"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckDefault"
            >
              {d?.label || Object.values(d)}
            </label>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CampaignsView;
