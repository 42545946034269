import React from "react";
import { Modal } from "antd";
import { useState } from "react";
function ManageUser() {
  const [profileModal, setProfileModal] = useState(false);
  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <div className="d-flex flex-stack flex-wrap gap-4">
                      <div className="position-relative mr-4">
                        <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height={2}
                              rx={1}
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            />
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <input
                          type="text"
                          data-kt-table-widget-4="search"
                          className="form-control text-dark  w-250px fs-7 ps-12 all-search"
                          name="all_search"
                          placeholder="Search by name or email..."
                        />
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <a
                        onClick={() => setProfileModal(true)}
                        className="btn  fs-7 btn-primary fw-bold"
                      >
                        Add New User
                      </a>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className="min-w-50px">#</th>
                            <th className="min-w-250px">Name</th>
                            <th className="min-w-150px">Contact No</th>
                            <th className="min-w-150px">Switch User</th>
                            <th className="min-w-175px">Created at</th>
                            <th className="min-w-175px">Updated at</th>
                            <th className="min-w-100px">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <img
                                    src="/assets/media/avatars/blank.png"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex flex-column">
                                  <a className="text-gray-800 text-hover-primary mb-1 fw-bolder">
                                    Melody Macy
                                  </a>
                                  <span>melody@altbox.com</span>
                                </div>
                              </div>
                            </td>
                            <td>-</td>
                            <td>
                              <a className="d-flex align-items-center text-primary fw-bolder">
                                <span className="svg-icon svg-icon-primary svg-icon-2x me-2">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.3"
                                      width={12}
                                      height={2}
                                      rx={1}
                                      transform="matrix(-1 0 0 1 15.5 11)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                Switch User
                              </a>
                            </td>
                            <td>Jan 10, 2023, 6:44 AM</td>
                            <td>Jan 28, 2023, 1:04 AM</td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
                                  <i className="ki-outline ki-pencil fs-2 text-primary" />
                                </a>
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-2 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <a href>
                                    <div className="symbol-label fs-3 fw-bolder bg-light-danger text-danger">
                                      M
                                    </div>
                                  </a>
                                </div>
                                <div className="d-flex flex-column">
                                  <a className="text-gray-800 text-hover-primary mb-1 fw-bolder">
                                    Melody Macy
                                  </a>
                                  <span>melody@altbox.com</span>
                                </div>
                              </div>
                            </td>
                            <td>-</td>
                            <td>
                              <a className="d-flex align-items-center text-primary fw-bolder">
                                <span className="svg-icon svg-icon-primary svg-icon-2x me-2">
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.3"
                                      width={12}
                                      height={2}
                                      rx={1}
                                      transform="matrix(-1 0 0 1 15.5 11)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                Switch User
                              </a>
                            </td>
                            <td>Jan 10, 2023, 6:44 AM</td>
                            <td>Jan 28, 2023, 1:04 AM</td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
                                  <i className="ki-outline ki-pencil fs-2 text-primary" />
                                </a>
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-2 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                      <div className="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                        <select
                          name
                          aria-controls
                          className="form-select form-select-sm form-select-solid w-75px me-3"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        Showing 1 to 4 of 4 entries
                      </div>
                      <ul className="pagination">
                        <li className="page-item previous">
                          <a className="page-link">
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link">1</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">2</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">3</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">4</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">5</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">6</a>
                        </li>
                        <li className="page-item next">
                          <a className="page-link">
                            <i className="next" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={profileModal}
        maskClosable={false}
        onOk={() => setProfileModal(false)}
        onCancel={() => setProfileModal(false)}
        width={650}
        className="custom-modal"
        footer={[
          <div className="modal-footer">
            <button
              className="btn btn-light fs-7 me-3"
              onClick={() => setProfileModal(false)}
            >
              Close
            </button>
            <button type="button" class="btn btn-primary fw-bold fs-7">
              Add Users
            </button>
          </div>,
        ]}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h2 className="modal-title fs-2 fw-bold">Add a Account</h2>
          </div>
          <div className="modal-body pb-7 pt-5">
            <div
              className="scroll-y pe-7"
              id="kt_modal_add_customer_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_customer_header"
              data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="row mb-6 mt-4 align-items-center">
                <label className="col-lg-4 fs-6 fw-bold mb-2">Avatar</label>
                <div className="col-lg-8">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage: "url(/assets/media/avatars/blank.png)",
                    }}
                  >
                    <div
                      className="image-input-wrapper w-100px h-100px"
                      style={{
                        backgroundImage: "url(/assets/media/avatars/300-1.jpg)",
                      }}
                    />
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      title="Remove avatar"
                    >
                      <i className="bi bi-x fs-2 mt-1" />
                    </span>
                  </div>
                  <div className="form-text">
                    Allowed file types: png, jpg, jpeg.
                  </div>
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Name</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Email</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control " />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Passowrd</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control " />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4  fv-row">
                  <label className=" fs-6 fw-bold mb-2">Confirm Passowrd</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control " />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Contact No</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control " />
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-4 fv-row">
                  <label className=" fs-6 fw-bold mb-2">Address</label>
                </div>
                <div className="col-md-8 fv-row">
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </Modal>
    </>
  );
}

export default ManageUser;
