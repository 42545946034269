import React from "react";

const Icons = (icon) => {
  const { type, ...rest } = icon;
  const getIcon = () => {
    switch (type) {
      case "userSwitch":
        return (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
              fill="currentColor"
            />
            <rect
              opacity="0.3"
              x={14}
              y={4}
              width={4}
              height={4}
              rx={2}
              fill="currentColor"
            />
            <path
              d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
              fill="currentColor"
            />
            <rect
              opacity="0.3"
              x={6}
              y={5}
              width={6}
              height={6}
              rx={3}
              fill="currentColor"
            />
          </svg>
        );
      case "remove-group":
        return (
          <span className="svg-icon svg-icon-danger svg-icon-2hx">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x={2}
                y={2}
                width={20}
                height={20}
                rx={5}
                fill="currentColor"
              />
              <rect
                x="6.0104"
                y="10.9247"
                width={12}
                height={2}
                rx={1}
                fill="currentColor"
              />
            </svg>
          </span>
        );
      case "left-arrow":
        return (
          <span className="svg-icon svg-icon-primary svg-icon-1">
            <svg
              width={20}
              height={16}
              style={{ transform: "rotate(180deg)" }}
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M12.4 9H1.00002C0.400015 9 1.52588e-05 8.6 1.52588e-05 8C1.52588e-05 7.4 0.400015 7 1.00002 7L12.4 7V9Z"
                fill="currentColor"
              />
              <path
                d="M14.1071 1.7071C13.4771 1.07714 12.4 1.52331 12.4 2.41421V13.5858C12.4 14.4767 13.4771 14.9229 14.1071 14.2929L19.7 8.7C20.1 8.3 20.1 7.7 19.7 7.3L14.1071 1.7071Z"
                fill="currentColor"
              />
            </svg>
          </span>
        );
      case "add-group":
        return (
          <span className="svg-icon svg-icon-success svg-icon-2hx me-2">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x={2}
                y={2}
                width={20}
                height={20}
                rx={5}
                fill="currentColor"
              />
              <rect
                x="10.8891"
                y="17.8033"
                width={12}
                height={2}
                rx={1}
                transform="rotate(-90 10.8891 17.8033)"
                fill="currentColor"
              />
              <rect
                x="6.01041"
                y="10.9247"
                width={12}
                height={2}
                rx={1}
                fill="currentColor"
              />
            </svg>
          </span>
        );
      case "clear":
        return (
          <svg
            width={20}
            height={20}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
              fill="currentColor"
            />
            <path
              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
              fill="currentColor"
            />
          </svg>
        );
      case "edit":
        return (
          <svg
            width={20}
            height={20}
            fill="#21aae6"
            style={{ cursor: "pointer" }}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M19.125 2.258c.299-.3.784-.3 1.084 0l3.066 3.067c.3.3.3.784 0 1.084l-6.045 6.045a1.533 1.533 0 0 1-.399.287l-6.021 3.011a.767.767 0 0 1-1.03-1.028l3.012-6.022c.074-.148.17-.282.287-.399l6.046-6.045Zm.542 1.626-5.504 5.504-1.592 3.184.39.39 3.185-1.592 5.503-5.503-1.982-1.983Zm-3.834-.317L14.3 5.1H8.013c-.656 0-1.103 0-1.448.029-.336.027-.508.077-.628.138a1.534 1.534 0 0 0-.67.67c-.061.12-.11.292-.138.628-.028.345-.029.792-.029 1.448v9.507c0 .657 0 1.103.029 1.448.027.337.077.508.138.628.147.289.382.523.67.67.12.062.292.111.628.139.345.028.792.028 1.448.028h9.507c.657 0 1.103 0 1.448-.028.336-.028.508-.078.628-.139.289-.147.523-.381.67-.67.061-.12.111-.291.139-.628.028-.345.028-.791.028-1.448v-6.287L21.967 9.7v7.852c0 .617 0 1.126-.034 1.541-.035.431-.11.827-.3 1.2a3.066 3.066 0 0 1-1.34 1.34c-.373.19-.769.265-1.2.3-.415.034-.924.034-1.541.034h-9.57c-.618 0-1.127 0-1.542-.034-.43-.035-.827-.11-1.199-.3a3.066 3.066 0 0 1-1.34-1.34c-.19-.373-.265-.769-.3-1.2-.034-.415-.034-.924-.034-1.541v-9.57c0-.617 0-1.127.034-1.542.035-.43.11-.827.3-1.199a3.067 3.067 0 0 1 1.34-1.34c.372-.19.768-.265 1.2-.3.414-.034.923-.034 1.54-.034h7.852Z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "check":
        return (
          <svg
            width={21}
            height={21}
            fill="#39b900"
            style={{ cursor: "pointer" }}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12 1.845A10.153 10.153 0 0 0 1.845 12c0 5.608 4.546 10.155 10.155 10.155 5.608 0 10.155-4.547 10.155-10.155 0-5.61-4.547-10.155-10.155-10.155ZM3.302 12a8.698 8.698 0 1 1 17.396 0 8.698 8.698 0 0 1-17.396 0Zm12.775-3.01a.767.767 0 0 0-1.254-.882l-4.326 6.147-1.783-1.816a.767.767 0 0 0-1.094 1.074l2.427 2.473a.766.766 0 0 0 1.175-.096l4.855-6.9Z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "cross":
        return (
          <svg
            width={21}
            height={21}
            fill="#ff3c2e"
            style={{ cursor: "pointer" }}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M1.845 12C1.845 6.39 6.39 1.845 12 1.845c5.608 0 10.155 4.546 10.155 10.155 0 5.608-4.547 10.155-10.155 10.155-5.61 0-10.155-4.547-10.155-10.155ZM12 3.302a8.698 8.698 0 1 0 0 17.396 8.698 8.698 0 0 0 0-17.396Zm3.609 5.09c.3.299.3.784 0 1.083L13.084 12l2.525 2.525a.767.767 0 1 1-1.084 1.084L12 13.084 9.475 15.61a.767.767 0 0 1-1.084-1.084L10.916 12 8.39 9.475a.767.767 0 0 1 1.084-1.084L12 10.916l2.525-2.525c.299-.3.784-.3 1.084 0Z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "noData":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="39"
            viewBox="0 0 42 39"
          >
            <path
              fill="#363A3E"
              fillRule="evenodd"
              d="M1.917 22.254l3.879-1.066.223.8 3.924-1.065.446 1.555 9.897-2.666v7.062l-7.266 11.15 1.515.976 6.599-10.217L27.733 39l1.515-.977-7.222-11.149v-7.551l9.586-2.577.268 1.065L42 16.612 37.451 0l-9.317 4.086.268 1.066-21.223 5.73.446 1.556L3.7 13.503l.223.8L0 15.368l1.917 6.886zM36.293 2.443l3.433 12.615-6.51.8-2.942-10.75 6.019-2.665zM28.891 6.84l2.23 8.172-19.53 5.33-2.228-8.172L28.89 6.84zm-20.82 7.33l1.382 5.062-2.229.622-1.383-5.063 2.23-.622zM4.37 16.034l.937 3.465-2.14.578-.981-3.466 2.184-.577z"
              opacity=".507"
            />
          </svg>
        );
      case "menus":
        return (
          <span className="svg-icon svg-icon-primary svg-icon-1">
            <svg
              width={16}
              height={15}
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y={6} width={16} height={3} rx="1.5" fill="currentColor" />
              <rect
                opacity="0.3"
                y={12}
                width={8}
                height={3}
                rx="1.5"
                fill="currentColor"
              />
              <rect
                opacity="0.3"
                width={12}
                height={3}
                rx="1.5"
                fill="currentColor"
              />
            </svg>
          </span>
        );
      case "close":
        return (
          <span className="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={6}
                y="17.3137"
                width={16}
                height={2}
                rx={1}
                transform="rotate(-45 6 17.3137)"
                fill="black"
              />
              <rect
                x="7.41422"
                y={6}
                width={16}
                height={2}
                rx={1}
                transform="rotate(45 7.41422 6)"
                fill="black"
              />
            </svg>
          </span>
        );
      default:
        return <span>NA</span>;
    }
  };
  return <span {...rest}>{getIcon()}</span>;
};

export default Icons;
