import { styled } from "styled-components";

const Wrapper = styled.div`
  .auto-manual {
    position: absolute;
    right: -50px;
    top: 8px;
  }
  .ant-tabs-card {
    .ant-tabs-tab-active {
      background: #201e2a;
      .ant-tabs-tab-btn {
        color: #ffffff;
      }
    }
  }
`;

export default Wrapper;
