import {
  Checkbox,
  Input,
  InputNumber,
  Segmented,
  Select,
  message,
  Upload,
  Button,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FileSearchOutlined,
  BulbOutlined,
  SearchOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Papa from "papaparse";
import { EndPoint, SIGN } from "../../../../../../../../config";

const { Dragger } = Upload;
const CheckboxGroup = Checkbox.Group;

const IndividualProducts = (props) => {
  const {
    setSelectedFilter,
    selectedFilter,
    groupIndex,
    setBasicDetails,
    fakeActionCampaignsManagementAnalytics,
    selectedMarketplaceCreate,
    basicDetails,
    GetProductTargetingAction,
  } = props;
  const [selectedBidType, setSelectedBidType] = useState("Suggested Bid");
  const [filterBy, setFilterBy] = useState(["exact"]);
  const [searchASIN, setSearchASIN] = useState("");
  const [enterListASIN, setEnterListASIN] = useState("");
  const options = [
    { label: "Exact", value: "exact" },
    { label: "Expanded", value: "expanded" },
  ];

  const GetProductTargetingResponse = useSelector(
    (state) => state.CampaignManagement.GetProductTargetingResponse || {}
  );
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customBidValue, setCustomBidValue] = useState(0);

  useEffect(() => {
    if (GetProductTargetingResponse?.status === true) {
      setList(GetProductTargetingResponse?.data || []);
      setLoading(false);
      message.destroy();
      fakeActionCampaignsManagementAnalytics("GetProductTargetingResponse");
    } else if (GetProductTargetingResponse?.status === false) {
      setLoading(false);
      setList([]);
      message.destroy();
      message.error(GetProductTargetingResponse?.message);
      fakeActionCampaignsManagementAnalytics("GetProductTargetingResponse");
    }
  }, [GetProductTargetingResponse]);

  const addProduct = (obj, d) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].product_targeting_manual.push({
      ...obj,
      expression_type: d === "expanded" ? "ASIN_EXPANDED_FROM" : "ASIN_SAME_AS",
      expression_value: obj?.recommendedAsin,
      bid:
        selectedBidType === "Custom Bid" ? customBidValue : obj?.suggested_bid,
      typeP: d,
      Type: "Product",
      keyrow:
        updatedData.ad_groups_data[groupIndex].product_targeting_manual
          ?.length + 1,
      state: "ENABLED",
    });

    setBasicDetails({ ...updatedData });
  };
  const searchProduct = () => {
    message.destroy();
    message.loading("Loading...", 0);
    GetProductTargetingAction({
      marketplace_id: basicDetails?.marketplace_id,
      asins: searchASIN?.split(","),
    });
  };

  const enterListAction = (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      marketplace_id: basicDetails?.marketplace_id,
      asins: data || enterListASIN?.split(","),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${EndPoint}api/v1/campaigns/get-product-targeting-suggestions`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result?.data?.forEach((e) => {
          const index = basicDetails?.ad_groups_data?.[
            groupIndex
          ]?.product_targeting_manual?.findIndex(
            (r) => r?.recommendedAsin === e?.recommendedAsin
          );

          if (index === -1) {
            filterBy?.map((d, i) => addProduct(e, d));
          }
        });
        setEnterListASIN("");
        message.destroy();
      })
      .catch((error) => {
        message.destroy();
        message.warning("something went wrong");
      });
  };

  const handleUpload = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        console.log(results.data, "results.data");
        // 'results' contains the parsed CSV data
        enterListAction(results.data?.map((d) => d?.ASIN));

        // Perform actions with the parsed data
      },
      header: true, // Set this to true if your CSV has headers
      dynamicTyping: true, // Automatically convert string data to numbers, etc.
    });
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      handleUpload(file);
    }, 0);
  };

  const filterComponents = {
    search: (
      <div className="row mt-4">
        <div className="filter-asin ">
          <Input
            placeholder="Search by Product name or ASIN"
            size="large"
            onChange={(e) => setSearchASIN(e.target.value)}
            value={searchASIN}
            onPressEnter={() => {
              searchProduct();
            }}
            className="antd-border-add"
            addonBefore={<SearchOutlined />}
          />
          <small>Search for products you want to all asins.</small>
        </div>
      </div>
    ),
    enter_list: (
      <div className="mt-5">
        <Input.TextArea
          className="form-control form-control-solid fs-7"
          cols={30}
          rows={4}
          placeholder="Enter ASINs seprated by comma, space or new line."
          onChange={(e) => setEnterListASIN(e.target.value)}
          value={enterListASIN}
        />
        <div className="mt-3 text-end">
          <button
            className="btn btn-dark fs-7"
            onClick={() => {
              enterListAction();
            }}
          >
            Add
          </button>
        </div>
      </div>
    ),
    upload: (
      <div className="mt-5">
        <Dragger customRequest={customRequest} showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </div>
    ),
  };

  const columns = [
    {
      title: "ASIN",
      width: 150,
      render: (e) => {
        return <div className="row">{e?.recommendedAsin || "-"}</div>;
      },
    },
    {
      title: "Sugg. bid",
      render: (e) => {
        return (
          <div className="row">
            <div className="col-12">
              {SIGN()}
              {e?.suggested_bid || 0}
            </div>
            <div className="col-12">
              {SIGN()}
              {e?.min_bid || 0}&nbsp;&nbsp;-&nbsp;&nbsp;{SIGN()}
              {e?.max_bid || 0}
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      render: (e) => {
        return (
          <div className="row gap-2">
            {filterBy?.map((d, i) => (
              <div className="col-12" key={i}>
                {d?.toUpperCase()}
              </div>
            ))}
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      width: 130,
      render: (e) => {
        const index_ = basicDetails.ad_groups_data[
          groupIndex
        ].product_targeting_manual?.filter(
          (d) => d?.recommendedAsin === e?.recommendedAsin
        );

        const getIndex = (type__) => {
          return index_?.findIndex((r) => r?.typeP === type__);
        };

        return (
          <div className="row  gap-2">
            {filterBy?.map((d, i) => (
              <Button
                disabled={getIndex(d) !== -1}
                key={i}
                onClick={() => addProduct(e, d)}
                type="primary"
              >
                {getIndex(d) !== -1 ? "Added" : "Add"}
              </Button>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <div className="row">
      <div className=" col-12 add-products-title d-flex align-items-center justify-content-between">
        <h3>
          <b>Product List</b>
        </h3>
        <div className="add-products-filters">
          <Segmented
            className="step-1-segment-view"
            style={{ marginBottom: ".5rem" }}
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e)}
            options={[
              {
                value: `suggested`,
                label: "Suggested",
                icon: <BulbOutlined />,
              },
              {
                value: `search`,
                label: "Search",
                icon: <FileSearchOutlined />,
              },
              {
                value: `enter_list`,
                label: "Enter List",
                icon: <FileDoneOutlined />,
              },
              {
                value: `upload`,
                label: "Upload",
                icon: <CloudUploadOutlined />,
              },
            ]}
          />
        </div>
      </div>
      <div className="col-12 px-5">
        <div className="row mt-5 d-flex align-items-center">
          <div className="col-4">Bid</div>
          <div className="col-8 d-flex align-items-center">
            <Select
              onChange={(e) => setSelectedBidType(e)}
              value={selectedBidType}
              options={["Suggested Bid", "Custom Bid"]?.map((d) => {
                return { label: d, value: d };
              })}
              placeholder="Select Bid"
              style={{ width: "100%" }}
            />
            {selectedBidType === "Custom Bid" && (
              <InputNumber
                className="ms-5"
                style={{ width: "100%" }}
                onChange={(e) => setCustomBidValue(e)}
                value={customBidValue}
                placeholder="Enter Custom Bid"
              />
            )}
          </div>
        </div>
        <div className="row my-7">
          <div className="col-4">Filter By</div>
          <div className="col-8 ">
            <CheckboxGroup
              options={options}
              value={filterBy}
              onChange={(e) => {
                setFilterBy(e);
              }}
            />
          </div>
        </div>
        {filterComponents?.[selectedFilter]}
      </div>
      {selectedFilter !== "enter_list" && (
        <div className="col-12 mt-5">
          <div className="product-body">
            <Table
              scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
              dataSource={list?.map((d, i) => {
                return { ...d, key: i };
              })}
              rowKey="key"
              loading={loading}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualProducts;
