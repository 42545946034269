import axiosCall from "../../../configurations/network-services/axiosCall";

export const ApplicationLogsCentralLogAction = (data) => {
  const path = `application-logs/central-logs?event_name=${
    data?.event_name || ""
  }&event_type=${data?.event_type || ""}&event_status=${
    data?.event_status || ""
  }&marketplace=${data?.marketplace || ""}&page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&search_text=${data?.search_text || ""}`;
  const responseType = "GET_APPLICATION_LOG_CENTRAL_LOG";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const LogsFilterDataAction = () => {
  const path = `application-logs/filters`;
  const responseType = "GET_APPLICATION_LOG_CENTRAL_LOG_FILTERS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionUserCentralLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_USER_CENTRAL_LOG", state: data });
};
