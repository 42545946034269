import React, { useState } from "react";
import { Modal, Select, Input, Button } from "antd";
import { SIGN } from "../../../../config";

const { Option } = Select;

const BudgetModal = ({ visible, onClose, onApply, type }) => {
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [budgetAmount, setBudgetAmount] = useState("");

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleBudgetChange = (e) => {
    setBudgetAmount(e.target.value);
  };

  const handleApply = () => {
    onApply({
      category: selectedCategory,
      amount: budgetAmount,
    });
    onClose();
  };

  return (
    <Modal
      title={type}
      open={visible}
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button key="apply" type="primary" onClick={handleApply}>
          Apply
        </Button>,
      ]}
    >
      <div className="row">
        <div className="col-auto">
          <label>Type:</label>
          <Select
            style={{ width: "100%" }}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <Option value="1">Greater than</Option>
            <Option value="2">Equals</Option>
            <Option value="3">Less then</Option>
            <Option value="4">Greater than or Equals to</Option>
            <Option value="5">Less than or Equals to</Option>
          </Select>
        </div>
        <div className="col-auto">
          <label>Amount:</label>
          <Input
            prefix={SIGN}
            value={budgetAmount}
            onChange={handleBudgetChange}
            placeholder="Enter budget amount"
          />
        </div>
      </div>
    </Modal>
  );
};

export default BudgetModal;
