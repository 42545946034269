import { Button, Input, InputNumber, Table } from "antd";
import React from "react";
import { SIGN } from "../../../../../../../../config";

const SelectedProdcut = (props) => {
  const {
    basicDetails,
    setBasicDetails,
    groupIndex,
    selectedMarketplaceCreate,
  } = props;
  const removeAll = (obj) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].product_targeting_manual = [];

    setBasicDetails({ ...updatedData });
  };
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };
  const RemoveAsin = (productIndex) => {
    if (groupIndex < 0 || groupIndex >= basicDetails.ad_groups_data.length)
      return;

    const adGroup = basicDetails.ad_groups_data[groupIndex];

    if (
      productIndex < 0 ||
      productIndex >= adGroup.product_targeting_manual.length
    )
      return;

    adGroup.product_targeting_manual.splice(productIndex, 1);
    setBasicDetails({ ...basicDetails });
  };

  const onBidChange = (e, row) => {
    // Create a copy of the ad group data
    const updatedAdGroupData = { ...basicDetails };

    // Update the Bid value in the specified keyword targeting
    updatedAdGroupData.ad_groups_data[groupIndex].product_targeting_manual[
      row?.key
    ].bid = e;

    // Update the state with the modified data
    setBasicDetails(updatedAdGroupData);
  };
  const columns = [
    {
      title: "Categories & Products",
      width: 250,
      render: (text) => {
        if (text?.Type === "Product") {
          return (
            <div className="row">
              <div className="col-12 text-overflow">
                Product - {text?.typeP}
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6">ASIN&nbsp;:</div>
                  <div className="col-6">{text?.expression_value}</div>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className="row">
            <div
              className="col-12 text-overflow"
              style={{ fontWeight: 600, marginBottom: "10px" }}
            >
              {text?.category_path || "-"}
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">Category&nbsp;:</div>
                <div className="col-6">{text?.category_name || "-"}</div>
              </div>
              <div className="row">
                <div className="col-6">Products&nbsp;:</div>
                <div className="col-6">
                  {text?.products?.min}
                  {` - `}
                  {text?.products?.max}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      render: (text) => {
        return (
          <div className="row">
            <div className="col-12">
              {SIGN()}
              {text?.suggested_bid || 0}
            </div>
            <div className="col-12">
              {SIGN()}
              {text?.min_bid || 0}&nbsp;&nbsp;-&nbsp;&nbsp;{SIGN()}
              {text?.max_bid || 0}
            </div>
          </div>
        );
      },
    },
    {
      title: "Bid",
      render: (row) => {
        return (
          <Input
            className="w-150px"
            onChange={(e) => onBidChange(e.target.value, row)}
            prefix={SIGN()}
            value={row?.bid}
          />
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      width: 100,
      render: (e) => (
        <span
          onClick={() => RemoveAsin(e.key)}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        >
          <i className="ki-outline ki-trash fs-3 text-danger" />
        </span>
      ),
    },
  ];
  return (
    <>
      {basicDetails?.ad_groups_data?.[groupIndex]?.product_targeting_manual
        ?.length !== 0 && (
        <div
          className="row mx-1 p-1 mb-5"
          style={{
            background: "#e4f3fc",
            borderRadius: 7,
            border: "1px dashed #22a9e6",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="col-auto">
            {basicDetails?.ad_groups_data?.[groupIndex]
              ?.product_targeting_manual?.length || 0}{" "}
            Selectd Row
          </div>
          <div className="col-auto">
            <Button
              type="ghost"
              onClick={() => removeAll()}
              className="text-danger cursor-pointer text-hover-warning"
            >
              Remove All
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <Table
          scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
          dataSource={basicDetails?.ad_groups_data?.[
            groupIndex
          ]?.product_targeting_manual?.map((d, i) => {
            return { ...d, key: i };
          })}
          rowKey={"key"}
          columns={columns}
          pagination={false}
        />
      </div>
    </>
  );
};

export default SelectedProdcut;
