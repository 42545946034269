import axiosCall from "../../../configurations/network-services/axiosCall";

export const getUserAction = (data) => {
  const path = `users?page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const responseType = "USER_GET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const switchUserAction = (id) => {
  const path = `switch-user`;
  const responseType = "SWITCH_USER";
  return axiosCall(
    "post",
    path,
    responseType,
    {
      id: id,
    },
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );
};

export const addUserAction = (data) => {
  const path = `user`;
  const responseType = "ADD_USER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const updateUserAction = (id, data) => {
  let FormData_ = new FormData();
  FormData_.append("name", data?.name || "");
  FormData_.append("email", data?.email || "");

  FormData_.append("contact_no", data?.contact_no || "");
  if (data?.photo || data?.photo === "remove") {
    FormData_.append(
      "photo",
      data?.photo === "remove" ? "" : data?.photo || ""
    );
  }
  const path = `user/${id}`;
  const responseType = "UPDATE_USER";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const deleteUserAction = (id) => {
  const path = `user/${id}`;
  const responseType = "DELETE_USER";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionUser = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADMIN_USER", state: data });
};
