import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import asyncComponent from "./core/asyncComponent";
import Login_ from "./containers/login";

import Page_ from "./modules/Page";
import SignUp_ from "./containers/sign-up";

function AppRoute(props) {
  return (
    <Router history={props.history}>
      <Suspense
        fallback={
          <div className="overlayLoading">
            <div className="loader"></div>
          </div>
        }
      >
        <Switch>
          {/*<Route exact path="/landing" component={asyncComponent(LandingModule_)} /> */}
          <Route
            exact
            path="/reset-password"
            component={asyncComponent(Login_)}
          />
          <Route exact path="/login" component={asyncComponent(Login_)} />
          <Route exact path="/sign-up" component={asyncComponent(SignUp_)} />
          <Route path="/" component={asyncComponent(Page_)} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default AppRoute;
