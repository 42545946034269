export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "OPTIONS_GET_SUCCESS":
    case "OPTIONS_GET_ERROR":
      return {
        ...state,
        OptionListResponse: action.updatePayload,
      };

    case "CARDS_DATA_GET_SUCCESS":
    case "CARDS_DATA_GET_ERROR":
      return {
        ...state,
        CardsDataResponse: action.updatePayload,
      };
    case "DASHBOARD_PERFORMANCE_METRICS_SUCCESS":
    case "DASHBOARD_PERFORMANCE_METRICS_ERROR":
      return {
        ...state,
        DashboardPerformanceMetricsResponse: action.updatePayload,
      };
    case "DASHBOARD_PERFORMANCE_GRAPH_SUCCESS":
    case "DASHBOARD_PERFORMANCE_GRAPH_ERROR":
      return {
        ...state,
        DashboardPerformanceGraphResponse: action.updatePayload,
      };
    case "DASHBOARD_ACTIVE_CAMPAIGNS_SUCCESS":
    case "DASHBOARD_ACTIVE_CAMPAIGNS_ERROR":
      return {
        ...state,
        DashboardActiveCampaignsResponse: action.updatePayload,
      };
    case "DASHBOARD_TARGETING_CARD_SUCCESS":
    case "DASHBOARD_TARGETING_CARD_ERROR":
      return {
        ...state,
        DashboardTargetingCardResponse: action.updatePayload,
      };
    case "FAKE_ACTION_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
