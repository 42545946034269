import React from "react";
import Chart from "react-apexcharts";
import { NumericFormat } from "react-number-format";
import NoData from "../../../../components/no-data";
import CardLoading from "./card-loading";
// import { SIGN } from "../../../../config";
import { Tag } from "antd";

// Define the formatter function
function formatNumber(value) {
  return value?.toLocaleString();
}

function CardView(
  cardsDataLoader,
  allAdsPerformanceData,
  createCardSeries,
  dashboardCardCurrant,
  SIGN
) {
  const prLogic = (newvalue, oldvalue) => {
    const value =
      newvalue === 0 && oldvalue === 0
        ? 0
        : parseFloat(((newvalue - oldvalue) / (oldvalue || 1)) * 100).toFixed(
            2
          );
    if (value === 0) {
      return (
        <span className="badge badge-light-warning fs-base">
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
          {value}%
        </span>
      );
    } else if (value < 0) {
      return (
        <span className="badge badge-light-danger d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    }
    return (
      <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
        + {value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
      </span>
    );
  };
  return (
    <>
      <div className="row gy-5 mb-5">
        {/* Total Ad Spend */}
        <div className="col-md-6 col-lg-6 col-xl-6 ">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {cardsDataLoader ? (
              <CardLoading />
            ) : Object.values(allAdsPerformanceData?.total_roas || {})
                ?.length === 0 &&
              Object.values(dashboardCardCurrant?.total_roas || {})?.length ===
                0 ? (
              <div style={{ minHeight: "200px" }}>
                <NoData height={"100%"} />
              </div>
            ) : (
              <>
                <div className="card-header border-bottom-dashed pt-5 pb-4">
                  <div className="card-title d-flex flex-column">
                    <span className="text-gray-500 fw-semibold fs-2">
                      <Tag>Total Ad Spend</Tag>
                    </span>
                  </div>
                  <div className="card-toolbar d-grid">
                    <div className="d-flex align-items-center">
                      <span
                        className="fs-4 fw-semibold text-gray-400 me-1 align-self-start"
                        style={{
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {SIGN()}
                      </span>
                      <span className="text-gray-800 fw-bolder text-hover-primary fs-4">
                        {formatNumber(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_spends_total || 0
                        ).toLocaleString()}
                      </span>
                      {prLogic(
                        parseFloat(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_spends_total || 0
                        ),
                        parseFloat(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_spends_total || 0
                        )
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                        {SIGN()}
                      </span>
                      <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                        {formatNumber(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_spends_total || 0
                        ).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0 row">
                  {Object.keys(allAdsPerformanceData).length > 0 &&
                  Object.values(allAdsPerformanceData?.total_ad_spends).length >
                    0 ? (
                    <>
                      <div className="col-md-6 col-lg-6 pt-2">
                        <div
                          id="kt_card_widget_17_chart_as"
                          style={{
                            minWidth: "100px",
                            minHeight: "100px",
                          }}
                          data-kt-size={100}
                          data-kt-line={11}
                        >
                          <Chart
                            options={{
                              chart: {
                                type: "donut",
                                toolbar: {
                                  show: false,
                                },
                                zoom: {
                                  enabled: false,
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              legend: {
                                show: false,
                              },
                              labels: Object.keys(
                                allAdsPerformanceData?.total_ad_spends
                              ),
                              yaxis: {
                                labels: {
                                  formatter: function (value) {
                                    return formatNumber(
                                      parseFloat(
                                        parseFloat(value).toFixed(2)
                                      ).toLocaleString()
                                    );
                                  },
                                },
                              },
                              colors: [
                                "#f1416c",
                                "#22a9e6",
                                "#50cd89",
                                "#333333",
                                "#dddddd",
                              ],
                            }}
                            series={createCardSeries(
                              allAdsPerformanceData?.total_ad_spends
                            )}
                            type="donut"
                            height={150}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 d-flex flex-column ">
                        {Object.keys(allAdsPerformanceData).length > 0 &&
                        Object.values(allAdsPerformanceData?.total_ad_spends)
                          .length > 0 ? (
                          <>
                            {Object.entries(
                              allAdsPerformanceData?.total_ad_spends
                            ).map(([key, val], i) => {
                              return (
                                <div className="d-flex fw-semibold align-items-center mb-3">
                                  <div
                                    className="bullet w-8px h-3px rounded-2  me-3"
                                    style={{
                                      background: [
                                        "#f1416c",
                                        "#22a9e6",
                                        "#50cd89",
                                        "#333333",
                                        "#dddddd",
                                      ]?.[i],
                                    }}
                                  />
                                  <div className="text-gray-500 flex-grow-1 me-3">
                                    {key}
                                  </div>
                                  <div className="fw-bolder text-gray-700 text-xxl-end">
                                    <NumericFormat
                                      value={val}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={SIGN()}
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        {/* Total Ad Sales */}
        <div className="col-md-6 col-lg-6 col-xl-6 ">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {cardsDataLoader ? (
              <CardLoading />
            ) : Object.values(allAdsPerformanceData?.total_roas || {})
                ?.length === 0 &&
              Object.values(dashboardCardCurrant?.total_roas || {})?.length ===
                0 ? (
              <div style={{ minHeight: "200px" }}>
                <NoData height={"100%"} />
              </div>
            ) : (
              <>
                <div className="card-header border-bottom-dashed pt-5 pb-4">
                  <div className="card-title d-flex flex-column">
                    <span className="text-gray-500 fw-semibold fs-2">
                      <Tag>Total Ad Sales</Tag>
                    </span>
                  </div>
                  <div className="card-toolbar d-grid">
                    <div className="d-flex align-items-center">
                      <span
                        className="fs-4 fw-semibold text-gray-400 me-1 align-self-start"
                        style={{
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {SIGN()}
                      </span>
                      <span className="text-gray-800 fw-bolder text-hover-primary fs-4">
                        {formatNumber(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_sales_total || 0
                        ).toLocaleString()}
                      </span>
                      {prLogic(
                        parseFloat(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_sales_total || 0
                        ),
                        parseFloat(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_sales_total || 0
                        )
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                        {SIGN()}
                      </span>
                      <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                        {formatNumber(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_sales_total || 0
                        ).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0 row">
                  {Object.keys(allAdsPerformanceData).length > 0 &&
                  Object.values(allAdsPerformanceData?.total_ad_sales).length >
                    0 ? (
                    <>
                      <div className="col-md-6 col-lg-6 pt-2">
                        <div
                          id="kt_card_widget_17_chart_as"
                          style={{
                            minWidth: "100px",
                            minHeight: "100px",
                          }}
                          data-kt-size={100}
                          data-kt-line={11}
                        >
                          <Chart
                            options={{
                              chart: {
                                type: "donut",
                                toolbar: {
                                  show: false,
                                },
                                zoom: {
                                  enabled: false,
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              legend: {
                                show: false,
                              },
                              labels: Object.keys(
                                allAdsPerformanceData?.total_ad_sales
                              ),
                              yaxis: {
                                labels: {
                                  formatter: function (value) {
                                    return formatNumber(
                                      parseFloat(
                                        parseFloat(value).toFixed(2)
                                      ).toLocaleString()
                                    );
                                  },
                                },
                              },
                              colors: [
                                "#f1416c",
                                "#22a9e6",
                                "#50cd89",
                                "#333333",
                                "#dddddd",
                              ],
                            }}
                            series={createCardSeries(
                              allAdsPerformanceData?.total_ad_sales
                            )}
                            type="donut"
                            height={150}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 d-flex flex-column ">
                        {Object.keys(allAdsPerformanceData).length > 0 &&
                        Object.values(allAdsPerformanceData?.total_ad_sales)
                          .length > 0 ? (
                          <>
                            {Object.entries(
                              allAdsPerformanceData?.total_ad_sales
                            ).map(([key, val], i) => {
                              return (
                                <div className="d-flex fw-semibold align-items-center mb-3">
                                  <div
                                    className="bullet w-8px h-3px rounded-2  me-3"
                                    style={{
                                      background: [
                                        "#f1416c",
                                        "#22a9e6",
                                        "#50cd89",
                                        "#333333",
                                        "#dddddd",
                                      ]?.[i],
                                    }}
                                  />
                                  <div className="text-gray-500 flex-grow-1 me-3">
                                    {key}
                                  </div>
                                  <div className="fw-bolder text-gray-700 text-xxl-end">
                                    <NumericFormat
                                      value={val}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={SIGN()}
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row gy-5 mb-5">
        {/* Total ACOS */}
        <div className="col-md-6 col-lg-6 col-xl-6 ">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {cardsDataLoader ? (
              <CardLoading />
            ) : Object.values(allAdsPerformanceData?.total_roas || {})
                ?.length === 0 &&
              Object.values(dashboardCardCurrant?.total_roas || {})?.length ===
                0 ? (
              <div style={{ minHeight: "200px" }}>
                <NoData height={"100%"} />
              </div>
            ) : (
              <>
                <div className="card-header border-bottom-dashed pt-5 pb-4">
                  <div className="card-title d-flex flex-column">
                    <span className="text-gray-500 fw-semibold fs-2">
                      <Tag>Total ACOS</Tag>
                    </span>
                  </div>
                  <div className="card-toolbar d-grid">
                    <div className="d-flex align-items-center">
                      <span
                        className="fs-4 fw-semibold text-gray-400 me-1 align-self-start"
                        style={{
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {/* {SIGN()} */}
                      </span>
                      <span className="text-gray-800 fw-bolder text-hover-primary fs-4">
                        {formatNumber(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_acos_total || 0
                        ).toLocaleString()}
                        %
                      </span>
                      {prLogic(
                        parseFloat(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_acos_total || 0
                        ),
                        parseFloat(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_acos_total || 0
                        )
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                        {SIGN()}
                      </span> */}
                      <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                        {formatNumber(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_acos_total || 0
                        ).toLocaleString()}
                        %
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0 row">
                  {Object.keys(allAdsPerformanceData).length > 0 &&
                  Object.values(allAdsPerformanceData?.total_acos).length >
                    0 ? (
                    <>
                      <div className="col-md-6 col-lg-6 pt-2">
                        <div
                          id="kt_card_widget_17_chart_as"
                          style={{
                            minHeight: "150px",
                            minWidth: "150px",
                            marginTop: "-20px",
                            marginLeft: "-10px",
                          }}
                          data-kt-size={100}
                          data-kt-line={11}
                        >
                          <Chart
                            options={{
                              chart: {
                                type: "bar",
                                toolbar: {
                                  show: false,
                                },
                                zoom: {
                                  enabled: false,
                                },
                              },
                              plotOptions: {
                                bar: {
                                  distributed: true,
                                  horizontal: true,
                                  barHeight: "75%",
                                  dataLabels: {
                                    position: "bottom",
                                  },
                                },
                              },
                              yaxis: {
                                labels: {
                                  show: false,
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              grid: {
                                show: false,
                              },
                              legend: {
                                show: false,
                              },
                              // yaxis: {
                              //   labels: {
                              //     formatter: function (value) {
                              //       return formatNumber(
                              //         parseFloat(
                              //           parseFloat(value).toFixed(2)
                              //         ).toLocaleString()
                              //       );
                              //     },
                              //   },
                              // },
                              labels: Object.keys(
                                allAdsPerformanceData?.total_acos
                              ),
                              colors: [
                                "#f1416c",
                                "#22a9e6",
                                "#50cd89",
                                "#333333",
                                "#dddddd",
                              ],
                            }}
                            series={[
                              {
                                data: createCardSeries(
                                  allAdsPerformanceData?.total_acos
                                ),
                                name: "Total ACOS",
                              },
                            ]}
                            type="bar"
                            height={150}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 d-flex flex-column ">
                        {Object.keys(allAdsPerformanceData).length > 0 &&
                        Object.values(allAdsPerformanceData?.total_acos)
                          .length > 0 ? (
                          <>
                            {Object.entries(
                              allAdsPerformanceData?.total_acos
                            ).map(([key, val], i) => {
                              return (
                                <div className="d-flex fw-semibold align-items-center mb-3">
                                  <div
                                    className="bullet w-8px h-3px rounded-2  me-3"
                                    style={{
                                      background: [
                                        "#f1416c",
                                        "#22a9e6",
                                        "#50cd89",
                                        "#333333",
                                        "#dddddd",
                                      ]?.[i],
                                    }}
                                  />
                                  <div className="text-gray-500 flex-grow-1 me-3">
                                    {key}
                                  </div>
                                  <div className="fw-bolder text-gray-700 text-xxl-end">
                                    <NumericFormat
                                      value={val}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={"%"}
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        {/* Total ROAS */}
        <div className="col-md-6 col-lg-6 col-xl-6 ">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {cardsDataLoader ? (
              <CardLoading />
            ) : Object.values(allAdsPerformanceData?.total_roas || {})
                ?.length === 0 &&
              Object.values(dashboardCardCurrant?.total_roas || {})?.length ===
                0 ? (
              <div style={{ minHeight: "200px" }}>
                <NoData height={"100%"} />
              </div>
            ) : (
              <>
                <div className="card-header border-bottom-dashed pt-5 pb-4">
                  <div className="card-title d-flex flex-column">
                    <span className="text-gray-500 fw-semibold fs-2">
                      <Tag>Total ROAS</Tag>
                    </span>
                  </div>
                  <div className="card-toolbar d-grid">
                    <div className="d-flex align-items-center">
                      <span
                        className="fs-4 fw-semibold text-gray-400 me-1 align-self-start"
                        style={{
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {SIGN()}
                      </span>
                      <span className="text-gray-800 fw-bolder text-hover-primary fs-4">
                        {formatNumber(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_roas_total || 0
                        ).toLocaleString()}
                      </span>
                      {prLogic(
                        parseFloat(
                          allAdsPerformanceData?.all_performance_total
                            ?.all_ad_roas_total || 0
                        ),
                        parseFloat(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_roas_total || 0
                        )
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                        {SIGN()}
                      </span>
                      <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                        {formatNumber(
                          dashboardCardCurrant?.all_performance_total
                            ?.all_ad_roas_total || 0
                        ).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0 row">
                  {Object.keys(allAdsPerformanceData).length > 0 &&
                  Object.values(allAdsPerformanceData?.total_roas).length >
                    0 ? (
                    <>
                      <div className="col-md-6 col-lg-6 pt-2">
                        <div
                          id="kt_card_widget_17_chart_as"
                          style={{
                            minWidth: "100px",
                            minHeight: "100px",
                          }}
                          data-kt-size={100}
                          data-kt-line={11}
                        >
                          <Chart
                            options={{
                              chart: {
                                type: "donut",
                                toolbar: {
                                  show: false,
                                },
                                zoom: {
                                  enabled: false,
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              legend: {
                                show: false,
                              },
                              labels: Object.keys(
                                allAdsPerformanceData?.total_roas
                              ),
                              yaxis: {
                                labels: {
                                  formatter: function (value) {
                                    return formatNumber(
                                      parseFloat(
                                        parseFloat(value).toFixed(2)
                                      ).toLocaleString()
                                    );
                                  },
                                },
                              },
                              colors: [
                                "#f1416c",
                                "#22a9e6",
                                "#50cd89",
                                "#333333",
                                "#dddddd",
                              ],
                            }}
                            series={createCardSeries(
                              allAdsPerformanceData?.total_roas
                            )}
                            type="donut"
                            height={150}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 d-flex flex-column ">
                        {Object.keys(allAdsPerformanceData).length > 0 &&
                        Object.values(allAdsPerformanceData?.total_roas)
                          .length > 0 ? (
                          <>
                            {Object.entries(
                              allAdsPerformanceData?.total_roas
                            ).map(([key, val], i) => {
                              return (
                                <div className="d-flex fw-semibold align-items-center mb-3">
                                  <div
                                    className="bullet w-8px h-3px rounded-2  me-3"
                                    style={{
                                      background: [
                                        "#f1416c",
                                        "#22a9e6",
                                        "#50cd89",
                                        "#333333",
                                        "#dddddd",
                                      ]?.[i],
                                    }}
                                  />
                                  <div className="text-gray-500 flex-grow-1 me-3">
                                    {key}
                                  </div>
                                  <div className="fw-bolder text-gray-700 text-xxl-end">
                                    <NumericFormat
                                      value={val}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={SIGN()}
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default CardView;
