import styled from "styled-components";

const Wrapper = styled.div`
    height: 100%;
    position: relative;
.hideBar {
    transition: padding-left .3s ease !important;
    padding-left: 0 !important;
}
.drawer {
    display: block !important;
    z-index: 110;
    position: fixed;
    top: -1px;
    background-color: #1d2b58 !important;
    bottom: 0;
    transition: transform .3s ease-in-out!important;
}
.drawer.drawer-start {
    width: 250px !important;
    transform: none;
    transition: transform .3s ease-in-out!important;
    left: 0;
}

`;

export default Wrapper;