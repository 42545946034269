import { DatePicker, Select, Skeleton, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardView from "./lib/cards-list";
import GraphView from "./lib/graph";
import PerformaceMetricsView from "./lib/performance";
import moment from "moment";
import { FormatNumber } from "../../../config";
import { PerformanceWrapper, Wrapper } from "./style";
import Targetloading from "../campaigns-analytics/lib/targetloading";
import ReactApexChart from "react-apexcharts";

// Define the formatter function
function formatNumber(value) {
  return value?.toLocaleString();
}
const { RangePicker } = DatePicker;
const Loading = (
  <>
    <div className="d-flex flex-stack">
      <div className="d-flex align-items-center me-5">
        <div className="me-5">
          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
            <Skeleton.Input
              active
              size="small"
              shape="round"
              className="me-2"
            />
          </a>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <h4 className="fw-bold mb-0">
          <Skeleton.Button active size="small" shape="round" />
        </h4>
      </div>
    </div>
  </>
);
export default function (props) {
  const {
    getOptionListAction,
    getDashboardCardsDataAction,
    getDashboardPerformanceDataAction,
    getDashboardGraphAction,
    DashboardActiveCampaignsAction,
    DashboardTargetingCardDataAction,
    fakeActionDashboard,

    GetMarketplaceListAction,
    fakeActionMarketplace,
  } = props;

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];

  const GetDashboardOptionListRes = useSelector(
    (state) => state.Dashboard.OptionListResponse || {}
  );
  const GetDashboardCardsDataRes = useSelector(
    (state) => state.Dashboard.CardsDataResponse || {}
  );
  const DashboardPerformanceMetricsResponse = useSelector(
    (state) => state.Dashboard.DashboardPerformanceMetricsResponse || {}
  );
  const DashboardPerformanceGraphResponse = useSelector(
    (state) => state.Dashboard.DashboardPerformanceGraphResponse || {}
  );
  const DashboardTargetingCardResponse = useSelector(
    (state) => state.Dashboard.DashboardTargetingCardResponse || {}
  );
  const DashboardActiveCampaignsResponse = useSelector(
    (state) => state.Dashboard.DashboardActiveCampaignsResponse || {}
  );
  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );

  const [optionLoader, setOptionLoader] = useState(true);
  const [cardsDataLoader, setCardsDataLoader] = useState(true);

  const [matchOptionList, setMatchOptionList] = useState([]);
  const [advertisingOptionList, setAdvertisingOptionList] = useState([]);
  const [metricsOptionList, setMetricsOptionList] = useState([]);

  const [activeCampaignsData, setActiveCampaignsData] = useState({});
  const [activeCampaignsLoading, setActiveCampaignsLoading] = useState(true);

  const [allAdsPerformanceData, setAllAdsPerformanceData] = useState({});

  const [targetingLoading, setTargetingLoading] = useState(true);

  const [graphLoading, setGraphLoading] = useState(true);
  const [allAdsPerformanceGraphData, setAllAdsPerformanceGraphData] = useState(
    []
  );

  const [dateCurrent, setDateCurrent] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const [seriesList, setSeriesList] = useState([]);

  const [performanceMetricsLoading, setPerformanceMetricsLoading] =
    useState(true);
  const [performanceMetricsList, setPerformanceMetricsList] = useState({});

  const [dashboardCardCurrant, setDashboardCardCurrant] = useState({});
  const [performanceMetrics, setPerformanceMetrics] = useState({});
  const [activeCampaigns, setActiveCampaigns] = useState({});

  const [targeting, setTargeting] = useState({});
  const [targetingPrev, setTargetingPrev] = useState({});

  const [graphType, setGraphType] = useState("Current");
  const [graphCurrentData, setGraphCurrentData] = useState([]);
  const [graphSeriesList, setGraphSeriesList] = useState([]);

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-60, "d"),
    end_date: dayjs().add(-30, "d"),
    match_type: [],
    advertising_type: null,
    metrics: null,
  });

  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[selectedMarketplace] || ""
      );
    } catch (error) {
      return "$";
    }
  };
  useEffect(() => {
    if (!selectedMarketplace) {
      setMarketplaceLoading(false);
      setGraphLoading(false);
      setTargetingLoading(false);
      setCardsDataLoader(false);
      setOptionLoader(false);
    }
  }, [selectedMarketplace]);
  useEffect(() => {
    setOptionLoader(true);

    setPerformanceMetricsLoading(true);

    message.destroy();

    setMatchOptionList([]);
    setAdvertisingOptionList([]);
    setMetricsOptionList([]);

    getOptionListAction({});

    setPerformanceMetricsList({});
  }, []);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setGraphLoading(true);
    setAllAdsPerformanceGraphData([]);
    setSeriesList([]);
    getDashboardGraphAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      metrics: filters?.metrics,
      advertising_type: filters?.advertising_type,
      request_type: "Previous",
      marketplace_id: selectedMarketplace,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    filters?.metrics,
    filters?.advertising_type,
    selectedMarketplace,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setGraphLoading(true);
    setGraphCurrentData([]);
    setGraphSeriesList([]);
    getDashboardGraphAction({
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      metrics: filters?.metrics,
      advertising_type: filters?.advertising_type,
      request_type: "Current",
      marketplace_id: selectedMarketplace,
    });
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.metrics,
    filters?.advertising_type,
    selectedMarketplace,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setCardsDataLoader(true);
    if (matchOptionList?.length !== 0 && !optionLoader) {
      getDashboardCardsDataAction({
        match_type: filters?.match_type,
        start_date: !filters?.start_date
          ? null
          : dayjs(filters?.start_date).format("YYYY-MM-DD"),
        end_date: !filters?.end_date
          ? null
          : dayjs(filters?.end_date).format("YYYY-MM-DD"),
        metrics: filters?.metrics,
        request_type: "Previous",
        marketplace_id: selectedMarketplace,
      });
    }
  }, [
    filters?.end_date,
    filters?.start_date,
    filters?.match_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setCardsDataLoader(true);
    if (matchOptionList?.length !== 0 && !optionLoader) {
      getDashboardCardsDataAction({
        match_type: filters?.match_type,
        start_date: !dateCurrent?.start_date
          ? null
          : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
        end_date: !dateCurrent?.end_date
          ? null
          : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
        metrics: filters?.metrics,
        request_type: "Current",
        marketplace_id: selectedMarketplace,
      });
    }
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.match_type,
    selectedMarketplace,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;

    setPerformanceMetricsLoading(true);

    getDashboardPerformanceDataAction({
      advertising_type: filters?.advertising_type,
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "Previous",
      marketplace_id: selectedMarketplace,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    filters?.advertising_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;

    setPerformanceMetricsLoading(true);

    getDashboardPerformanceDataAction({
      advertising_type: filters?.advertising_type,
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "Current",
      marketplace_id: selectedMarketplace,
    });
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.advertising_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setActiveCampaignsLoading(true);
    setActiveCampaignsData({});
    DashboardActiveCampaignsAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      advertising_type: filters?.advertising_type,
      request_type: "Current",
      marketplace_id: selectedMarketplace,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setActiveCampaignsLoading(true);
    setActiveCampaigns({});
    DashboardActiveCampaignsAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "Previous",
      advertising_type: filters?.advertising_type,
      marketplace_id: selectedMarketplace,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setTargeting({});
    setTargetingLoading(true);
    DashboardTargetingCardDataAction({
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "Current",
      marketplace_id: selectedMarketplace,
      advertising_type: filters?.advertising_type,
    });
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;

    setTargetingPrev({});
    setTargetingLoading(true);
    DashboardTargetingCardDataAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "Previous",
      marketplace_id: selectedMarketplace,
      advertising_type: filters?.advertising_type,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setActiveCampaignsLoading(true);
    setActiveCampaignsData({});
    DashboardActiveCampaignsAction({
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      marketplace_id: selectedMarketplace,
    });
  }, [dateCurrent?.end_date, dateCurrent?.start_date, selectedMarketplace]);

  useEffect(() => {
    if (
      allAdsPerformanceGraphData?.length !== 0 &&
      metricsOptionList?.length !== 0
    ) {
      const series_ = [];
      metricsOptionList?.forEach((e) => {
        const isExit = allAdsPerformanceGraphData?.filter((d) => d?.[e?.value]);

        if (isExit?.length === 0) return;
        series_?.push({
          name: e?.label,
          data: allAdsPerformanceGraphData?.map((d) =>
            parseFloat(d?.[e?.value === "acos" ? "acos_percentage" : e?.value])
          ),
        });
      });

      setSeriesList(series_);
    }
  }, [allAdsPerformanceGraphData, metricsOptionList, filters?.metrics]);

  useEffect(() => {
    if (graphCurrentData?.length !== 0 && metricsOptionList?.length !== 0) {
      const series__ = [];
      metricsOptionList?.forEach((e) => {
        const isExit = graphCurrentData?.filter((d) => d?.[e?.value]);

        if (isExit?.length === 0) return;
        series__?.push({
          name: e?.label,
          data: graphCurrentData?.map((d) =>
            parseFloat(d?.[e?.value === "acos" ? "acos_percentage" : e?.value])
          ),
        });
      });

      setGraphSeriesList(series__);
    }
  }, [graphCurrentData, metricsOptionList, filters?.metrics]);

  useEffect(() => {
    if (GetDashboardOptionListRes.status) {
      let optionsList = GetDashboardOptionListRes?.data;
      if (Object.keys(optionsList?.advertising_type_data).length > 0) {
        let advertising_option = Object.entries(
          optionsList?.advertising_type_data
        ).map(([key, val]) => {
          return {
            value: key,
            label: val,
          };
        });
        setAdvertisingOptionList(advertising_option);
      }
      if (Object.keys(optionsList?.match_type_data).length > 0) {
        let match_option = Object.entries(optionsList?.match_type_data).map(
          ([key, val]) => {
            return {
              value: key,
              label: val,
            };
          }
        );
        setMatchOptionList(match_option);
        setFilters({
          ...filters,
          match_type: match_option?.map((d) => d?.value),
        });
        // setCardsDataLoader(false);
      }
      if (Object.keys(optionsList?.metrics_data).length > 0) {
        let metrics_option = Object.entries(optionsList?.metrics_data).map(
          ([key, val]) => {
            return {
              value: key === "acos" ? "acos_percentage" : key,
              label: val,
            };
          }
        );
        setFilters({
          ...filters,
          metrics: metrics_option.slice(0, 4)?.map((d) => d.value),
        });
        setMetricsOptionList(metrics_option);
      }
      setOptionLoader(false);
      fakeActionDashboard("OptionListResponse");
    } else if (GetDashboardOptionListRes.status === false) {
      setOptionLoader(false);
      fakeActionDashboard("OptionListResponse");
    }
  }, [GetDashboardOptionListRes]);
  // two date use
  useEffect(() => {
    if (DashboardActiveCampaignsResponse?.status === true) {
      if (DashboardActiveCampaignsResponse?.data?.request_type === "Previous") {
        setActiveCampaigns(DashboardActiveCampaignsResponse?.data || {});
      } else {
        setActiveCampaignsData(DashboardActiveCampaignsResponse?.data || {});
      }
      setActiveCampaignsLoading(false);
      fakeActionDashboard("DashboardActiveCampaignsResponse");
    } else if (DashboardActiveCampaignsResponse?.status === false) {
      setActiveCampaignsData({});
      setActiveCampaigns({});
      setActiveCampaignsLoading(false);
      fakeActionDashboard("DashboardActiveCampaignsResponse");
    }
  }, [DashboardActiveCampaignsResponse]);

  // two date use
  useEffect(() => {
    if (GetDashboardCardsDataRes.status) {
      if (GetDashboardCardsDataRes?.data) {
        let cards_data = GetDashboardCardsDataRes?.data;
        if (GetDashboardCardsDataRes?.data?.request_type !== "Current") {
          setDashboardCardCurrant(cards_data?.all_ads_performance_data || {});
        } else {
          setAllAdsPerformanceData(cards_data?.all_ads_performance_data || {});
        }
      } else {
        if (GetDashboardCardsDataRes?.data?.request_type !== "Current") {
          setDashboardCardCurrant({});
        } else {
          setAllAdsPerformanceData({});
        }
      }
      setCardsDataLoader(false);
      fakeActionDashboard("CardsDataResponse");
    } else if (GetDashboardOptionListRes.status === false) {
      setCardsDataLoader(false);
      fakeActionDashboard("CardsDataResponse");
    }
  }, [GetDashboardCardsDataRes]);

  // two date use
  useEffect(() => {
    if (DashboardPerformanceMetricsResponse?.status === true) {
      if (
        DashboardPerformanceMetricsResponse?.data?.request_type !== "Current"
      ) {
        setPerformanceMetrics(DashboardPerformanceMetricsResponse?.data || {});
      } else {
        setPerformanceMetricsList(
          DashboardPerformanceMetricsResponse?.data || {}
        );
      }

      setPerformanceMetricsLoading(false);

      fakeActionDashboard("DashboardPerformanceMetricsResponse");
    } else if (DashboardPerformanceMetricsResponse?.status === false) {
      setPerformanceMetricsLoading(false);

      fakeActionDashboard("DashboardPerformanceMetricsResponse");
    }
  }, [DashboardPerformanceMetricsResponse]);

  useEffect(() => {
    if (DashboardTargetingCardResponse?.status === true) {
      if (DashboardTargetingCardResponse?.data?.request_type === "Current") {
        setTargeting(
          DashboardTargetingCardResponse?.data?.targeting_data || {}
        );
      } else {
        setTargetingPrev(
          DashboardTargetingCardResponse?.data?.targeting_data || {}
        );
      }
      setTargetingLoading(false);
      fakeActionDashboard("DashboardTargetingCardResponse");
    } else if (DashboardTargetingCardResponse?.status === false) {
      setTargetingPrev({});
      setTargeting({});
      setTargetingLoading(false);
      fakeActionDashboard("DashboardTargetingCardResponse");
    }
  }, [DashboardTargetingCardResponse]);

  // graph API
  useEffect(() => {
    if (DashboardPerformanceGraphResponse?.status === true) {
      if (DashboardPerformanceGraphResponse?.data?.request_type !== "Current") {
        setAllAdsPerformanceGraphData(
          DashboardPerformanceGraphResponse?.data?.data
        );
      } else {
        setGraphCurrentData(DashboardPerformanceGraphResponse?.data?.data);
      }
      setGraphLoading(false);
      fakeActionDashboard("DashboardPerformanceGraphResponse");
    } else if (DashboardPerformanceGraphResponse?.status === false) {
      setGraphCurrentData([]);
      setAllAdsPerformanceGraphData([]);
      setGraphLoading(false);
      fakeActionDashboard("DashboardPerformanceGraphResponse");
    }
  }, [DashboardPerformanceGraphResponse]);

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      if (GetMarketplaceListResponse?.selected_marketplaces?.length > 0) {
        setSelectedMarketplace(
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
            GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id
        );
      }
      setMarketplaceList(GetMarketplaceListResponse?.selected_marketplaces);
      setMarketplaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setSelectedMarketplace(null);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 15,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      lineCap: "butt",
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: 5,
    },
    // yaxis: [
    //   {
    //     seriesName: "Total Sales",
    //     labels: {
    //       formatter: function (value) {
    //         return formatNumber(
    //           parseFloat(parseFloat(value).toFixed(2)).toLocaleString()
    //         );
    //       },
    //     },
    //   },
    //   {
    //     opposite: true,
    //     seriesName: "CTR",
    //     labels: {
    //       formatter: function (value) {
    //         return formatNumber(
    //           parseFloat(parseFloat(value).toFixed(2)).toLocaleString()
    //         );
    //       },
    //     },
    //   },

    // ],
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatNumber(
            parseFloat(parseFloat(value).toFixed(2)).toLocaleString()
          );
        },
      },
    },
    xaxis: {
      categories: (graphType === "Current"
        ? graphCurrentData || []
        : allAdsPerformanceGraphData || []
      )?.map((d) => dayjs(d?.report_date, "YYYY-MM-DD").format("MMM DD, YYYY")),
      title: {
        text: "Month",
      },
    },
    colors: [
      "#FF5733",
      "#1ABC9C",
      "#3498DB",
      "#E74C3C",
      "#9B59B6",
      "#F1C40F",
      "#27AE60",
      "#34495E",
      "#E67E22",
      "#2ECC71",
      "#F39C12",
      "#2980B9",
      "#D35400",
      "#8E44AD",
      "#C0392B",
      "#1E8449",
      "#ECF0F1",
      "#D35400",
      "#3498DB",
      "#2C3E50",
    ],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y: {
        formatter: function (
          value,

          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          const pr =
            withTitle_?.name === "ACOS" ||
            withTitle_?.name === "CTR" ||
            withTitle_?.name === "Ad Sales Percentage"
              ? "%"
              : "";
          const Sign =
            withTitle_?.name === "Spend" ||
            withTitle_?.name === "CPC" ||
            withTitle_?.name === "Total Sales"
              ? JSON.parse(localStorage.getItem("signs"))?.[
                  selectedMarketplace
                ] || ""
              : "";
          return Sign + value?.toLocaleString() + pr;
        },
      },
      // y: {
      //   formatter: function (value) {
      //     return value?.toLocaleString(); // Custom format for the tooltip value
      //   },
      // },
    },
  };

  const createCardSeries = (data) => {
    let res = Object.values(data).map((item) => {
      return Number(item);
    });
    return res;
  };

  const ConvertValue = (value) => {
    if (value < 0) {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value || 0}%{" "}
          <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    } else if (value > 0) {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
          +{value || 0}%{" "}
          <i className="ki-outline ki-arrow-up fs-3 text-success" />
        </span>
      );
    } else {
      return (
        <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
          {value || 0}%{" "}
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
        </span>
      );
    }
  };
  const prLogic = (newvalue, oldvalue, type) => {
    const value =
      newvalue === 0 && oldvalue === 0
        ? 0
        : parseFloat(((newvalue - oldvalue) / (oldvalue || 1)) * 100).toFixed(
            2
          );
    if (value === 0) {
      return (
        <span className=" fs-base">
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
          {value}%
        </span>
      );
    } else if (value < 0) {
      return (
        <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    }
    return (
      <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
        + {value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
      </span>
    );
  };

  useEffect(() => {
    setMarketplaceLoading(true);
    setMarketplaceList([]);
    GetMarketplaceListAction();
    return () => {};
  }, []);

  return (
    <Wrapper>
      <>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className>
              <div
                className="d-flex flex-wrap gap-3 mb-0"
                style={{
                  position: "sticky",
                  top: "0px",
                  zIndex: 99,
                  background: "rgb(249 248 248 / 0%)",
                  padding: "10px 0px",
                  marginTop: "-20px",
                  backdropFilter: "blur(23px)",
                }}
              >
                <div className="position-relative d-grid">
                  <label>Previous</label>
                  <RangePicker
                    suffixIcon={
                      <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                      </i>
                    }
                    size="large"
                    presets={rangePresets}
                    format="MMM, DD YYYY"
                    value={
                      filters?.start_date && filters?.end_date
                        ? [filters.start_date, filters.end_date]
                        : []
                    }
                    onChange={(e) => {
                      setCardsDataLoader(true);
                      setFilters({
                        ...filters,
                        start_date: !e ? null : e?.[0] || null,
                        end_date: !e ? null : e?.[1] || null,
                      });
                    }}
                    className="light-range-picker"
                    style={{
                      width: "280px",
                    }}
                  />
                </div>
                <div className="position-relative d-grid">
                  <label>Current</label>
                  <RangePicker
                    suffixIcon={
                      <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                      </i>
                    }
                    size="large"
                    presets={rangePresets}
                    format="MMM, DD YYYY"
                    value={
                      dateCurrent?.start_date && dateCurrent?.end_date
                        ? [dateCurrent.start_date, dateCurrent.end_date]
                        : []
                    }
                    onChange={(e) => {
                      setCardsDataLoader(true);
                      setDateCurrent({
                        ...dateCurrent,
                        start_date: !e ? null : e?.[0] || null,
                        end_date: !e ? null : e?.[1] || null,
                      });
                    }}
                    className="light-range-picker"
                    style={{
                      width: "280px",
                    }}
                  />
                </div>
                <div className="position-relative d-grid">
                  <label style={{ opacity: 0 }}>-</label>
                  <Select
                    showSearch
                    allowClear
                    size="large"
                    placeholder="Select Option"
                    mode="multiple"
                    maxTagCount="responsive"
                    value={filters?.match_type || []}
                    onChange={(e) => {
                      let filters_ = { ...filters };
                      filters_ = {
                        ...filters_,
                        match_type: e?.length !== 0 ? e : [],
                      };
                      setFilters(filters_);
                    }}
                    loading={optionLoader}
                    style={{
                      width: "200px",
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={matchOptionList}
                    className="fs-7"
                  />
                </div>
                <div className="position-relative d-grid">
                  <label style={{ opacity: 0 }}>-</label>
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    size="large"
                    placeholder="Select Option"
                    loading={optionLoader}
                    maxTagCount="responsive"
                    value={filters?.advertising_type || []}
                    onChange={(e) => {
                      setAllAdsPerformanceGraphData([]);
                      let filters_ = { ...filters };
                      filters_ = {
                        ...filters_,
                        advertising_type: e?.length !== 0 ? e : [],
                      };
                      setFilters(filters_);
                    }}
                    style={{
                      width: "210px",
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={advertisingOptionList}
                    className="fs-7"
                  />
                </div>
                <div className="position-relative d-grid">
                  <label style={{ opacity: 0 }}>-</label>
                  <Select
                    size="large"
                    placeholder="Select Marketplace"
                    loading={marketplaceLoading}
                    value={selectedMarketplace || null}
                    onChange={(e) => {
                      setSelectedMarketplace(e);
                    }}
                    style={{
                      width: "200px",
                    }}
                    options={marketplaceList?.map((d) => ({
                      label: d?.name,
                      value: d?.id,
                    }))}
                    className="fs-7"
                  />
                </div>
              </div>

              {CardView(
                cardsDataLoader,
                allAdsPerformanceData,
                createCardSeries,
                dashboardCardCurrant,
                SIGN
              )}
              {PerformaceMetricsView(
                performanceMetricsLoading,
                performanceMetricsList,
                performanceMetrics,
                SIGN
              )}
              <PerformanceWrapper className="row gx-5 gx-xl-5">
                <div className="col-xl-4 col-lg-12 mb-5 mb-xl-5">
                  <div
                    className="card card-flush h-lg-100"
                    style={{
                      border: "1px dashed #49b6ea",
                      boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                    }}
                  >
                    <div
                      className="card-header border-bottom-dashed"
                      style={{ minHeight: "50px", background: "#e1f6ff" }}
                    >
                      <h3 className="card-title fw-bold">Active Campaign</h3>
                      <div className="card-toolbar"></div>
                    </div>

                    <div className="card-body pt-2 pb-4 ">
                      {activeCampaignsLoading ? (
                        Loading
                      ) : (
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SP
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {FormatNumber(
                                  activeCampaigns?.SP?.current_count || 0
                                )}
                                {prLogic(
                                  activeCampaignsData?.SP?.current_count || 0,
                                  activeCampaigns?.SP?.current_count || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {FormatNumber(
                                activeCampaignsData?.SP?.current_count || 0
                              )}
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="separator separator-dashed my-2" />
                      {activeCampaignsLoading ? (
                        Loading
                      ) : (
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SB
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {FormatNumber(
                                  activeCampaigns?.SB?.current_count || 0
                                )}
                                {prLogic(
                                  activeCampaignsData?.SB?.current_count || 0,
                                  activeCampaigns?.SB?.current_count || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {FormatNumber(
                                activeCampaignsData?.SB?.current_count || 0
                              )}
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="separator separator-dashed my-2" />
                      {activeCampaignsLoading ? (
                        Loading
                      ) : (
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SD
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {FormatNumber(
                                  activeCampaigns?.SD?.current_count || 0
                                )}
                                {prLogic(
                                  activeCampaignsData?.SD?.current_count || 0,
                                  activeCampaigns?.SD?.current_count || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {FormatNumber(
                                activeCampaignsData?.SD?.current_count || 0
                              )}
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12 mb-5 mb-xl-5">
                  <div
                    className="card card-flush h-lg-100"
                    style={{
                      border: "1px dashed #49b6ea",
                      boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                    }}
                  >
                    <div
                      className="card-header border-bottom-dashed"
                      style={{ minHeight: "50px", background: "#e1f6ff" }}
                    >
                      <h3 className="card-title fw-bold">Targeting</h3>
                      <div className="card-toolbar"></div>
                    </div>
                    <div className="card-body pt-2 pb-4 ">
                      {targetingLoading ? (
                        <Targetloading />
                      ) : (
                        <div className="row">
                          <div className="col-md-6 pe-7">
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Active Keywords
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.Keyword?.Active
                                          ?.current || 0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.Keyword?.Active?.current || 0
                                      ),
                                      parseFloat(
                                        targetingPrev?.Keyword?.Active
                                          ?.current || 0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.Keyword?.Active?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-2" />
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Active Auto
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.Auto?.Active?.current ||
                                          0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.Auto?.Active?.current || 0
                                      ),
                                      parseFloat(
                                        targetingPrev?.Auto?.Active?.current ||
                                          0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.Auto?.Active?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>

                            <div className="separator separator-dashed my-2" />
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Active PAT
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.PAT?.Active?.current || 0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.PAT?.Active?.current || 0
                                      ),
                                      parseFloat(
                                        targetingPrev?.PAT?.Active?.current || 0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.PAT?.Active?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 ps-7 ms-auto">
                            {/* Inactive Keywords */}
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Inactive Keywords
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.Keyword?.InActive
                                          ?.current || 0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.Keyword?.InActive?.current ||
                                          0
                                      ),
                                      parseFloat(
                                        targetingPrev?.Keyword?.InActive
                                          ?.current || 0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.Keyword?.InActive?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-2" />
                            {/* Inactive Auto */}
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Inactive Auto
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.Auto?.InActive
                                          ?.current || 0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.Auto?.InActive?.current || 0
                                      ),
                                      parseFloat(
                                        targetingPrev?.Auto?.InActive
                                          ?.current || 0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.Auto?.InActive?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-2" />
                            {/* Inactive PAT */}
                            <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="me-5">
                                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                    Inactive PAT
                                  </a>
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    {FormatNumber(
                                      parseFloat(
                                        targetingPrev?.PAT?.InActive?.current ||
                                          0
                                      )
                                    )}
                                    {prLogic(
                                      parseFloat(
                                        targeting?.PAT?.InActive?.current || 0
                                      ),
                                      parseFloat(
                                        targetingPrev?.PAT?.InActive?.current ||
                                          0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="fw-bold mb-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targeting?.PAT?.InActive?.current || 0
                                    )
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </PerformanceWrapper>
              {GraphView(
                graphLoading,
                optionLoader,
                filters,
                setFilters,
                metricsOptionList,
                options,
                seriesList,
                allAdsPerformanceGraphData,
                setAllAdsPerformanceGraphData,
                setGraphType,
                graphType,
                graphSeriesList,
                (graphType === "Current"
                  ? graphCurrentData || []
                  : allAdsPerformanceGraphData || []
                )?.map((d) =>
                  dayjs(d?.report_date, "YYYY-MM-DD").format("MMM DD, YYYY")
                ),
                JSON.parse(localStorage.getItem("signs"))?.[
                  selectedMarketplace
                ] || ""
              )}
            </div>
          </div>
        </div>
      </>
    </Wrapper>
  );
}
