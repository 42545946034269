import axiosCall from "../../configurations/network-services/axiosCall";

export const CampaignsAnalyticsFilterListAction = () => {
  const path = `campaigns-analytics/filters`;
  const responseType = "CAMPAIGNS_ANALYTICS_FILTER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignsAnalyticsPerformanceMetricsAction = (data) => {
  const path = `campaigns-analytics/performance-metrics?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&advertising_type=${
    data?.advertising_type || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "CAMPAIGNS_ANALYTICS_PERFORMANCE_METRICS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignsAnalyticsDailyPerformanceTrendAction = (data) => {
  const path = `campaigns-analytics/daily-performance-trend?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&metrics=${
    data?.metrics || ""
  }&advertising_type=${data?.advertising_type || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "CAMPAIGNS_ANALYTICS_DAILY_PERFORMANCE_TREND";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ActiveCompaignsAction = (data) => {
  const path = `campaigns-analytics/active-campaigns?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "ACTIVE_COMPAIGNS_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignsAnalyticsAllTableDataAction = (
  data,
  key,
  value,
  filter_condition
) => {
  const path = `campaigns-analytics/all-campaigns-table-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&advertising_type=${
    data?.advertising_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}&${key || ""}=${
    value || ""
  }&filter_condition=${filter_condition || ""}`;
  const responseType = "ALL_CAMPAIGNS_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetPortfoliosListAction = (data) => {
  const path = `campaigns/get-portfolios?marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_ALL_PORTFOLIOS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateCampaignBugestAction = (data) => {
  const path = `campaigns/update-campaign-budget`;
  const responseType = "UPDATE_CAMPAIGN_BUDGET";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const BulkActionUpdateAction = (data) => {
  const path = `campaigns-analytics/bulk-action`;
  const responseType = "BULK_ACTION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetFilterListAction = () => {
  const path = `campaigns-analytics/filters`;
  const responseType = "CAMPAIGNS_ANALYTICS_FILTERS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CampaignConfigListAction = (type) => {
  const path = `user-table-configurations?type=${type}`;
  const responseType = "GET_CAMPAIGN_CONFIG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignConfigUpdateAction = (data) => {
  const path = `user-table-configurations`;
  const responseType = "UPDATE_CAMPAIGN_CONFIG_LIST";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CampaignAnalyticsBulkApplyAction = (data) => {
  const path = `campaigns-analytics/bulk-apply-rules`;
  const responseType = "CAMPAIGN_ANALYTICS_BULK_APPLY";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetRuleInCampaignsAnalyticsAction = (data) => {
  const path = `rule-management/get-rules?no_pagination=true&&rule_type=${
    data?.rule_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "GET_RULES_IN_CAMPAIGNS_ANALYTICS";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCampaignsAnalytics = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CAMPAIGNS_ANALYTICS", state: data });
};
