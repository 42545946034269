import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignsAnalyticsModule from "../../modules/pages/campaigns-analytics/CampaignsAnalytics";
import {
  CampaignsAnalyticsFilterListAction,
  CampaignsAnalyticsPerformanceMetricsAction,
  CampaignsAnalyticsDailyPerformanceTrendAction,
  ActiveCompaignsAction,
  CampaignsAnalyticsAllTableDataAction,
  GetPortfoliosListAction,
  UpdateCampaignBugestAction,
  CampaignConfigListAction,
  CampaignConfigUpdateAction,
  fakeActionCampaignsAnalytics,
  GetFilterListAction,
  BulkActionUpdateAction,
  CampaignAnalyticsBulkApplyAction,
  GetRuleInCampaignsAnalyticsAction,
} from "../../redux/modules/campaigns-analytics/index.action";
import {
  GetMarketplaceListAction,
  fakeActionMarketplace,
} from "../../redux/modules/marketplace-credentials/index.action";

import {
  getOptionListAction,
  fakeActionDashboard,
  DashboardTargetingCardDataAction,
} from "../../redux/modules/dashboard/dashboard.action";

const mapStateToProps = (state) => ({
  CampaignAnalyticsBulkApplyResponse:
    state?.CampaignsAnalytics.CampaignAnalyticsBulkApplyResponse,
  CampaignsAnalyticsFilterResponse:
    state?.CampaignsAnalytics.CampaignsAnalyticsFilterResponse,
  CampaignsAnalyticsPerformanceMetricsResponse:
    state?.CampaignsAnalytics.CampaignsAnalyticsPerformanceMetricsResponse,
  CampaignsAnalyticsDailyPerformanceTrendResponse:
    state?.CampaignsAnalytics.CampaignsAnalyticsDailyPerformanceTrendResponse,
  GetRulesInCampaignsAnalyticsResponse:
    state?.CampaignsAnalytics.GetRulesInCampaignsAnalyticsResponse,
  ActiveCompaignsDataResponse:
    state?.CampaignsAnalytics.ActiveCompaignsDataResponse,
  AllCompaignsDataResponse: state?.CampaignsAnalytics.AllCompaignsDataResponse,
  GetAllPortFoliosListResponse:
    state?.CampaignsAnalytics.GetAllPortFoliosListResponse,
  UpdateCampaignBudgetResponse:
    state?.CampaignsAnalytics.UpdateCampaignBudgetResponse,
  GetCampaignConfigListResponse:
    state?.CampaignsAnalytics.GetCampaignConfigListResponse,
  UpdateCampaignConfigListResponse:
    state?.CampaignsAnalytics.UpdateCampaignConfigListResponse,
  CampaignsAnalyticsFiltersResponse:
    state?.CampaignsAnalytics.CampaignsAnalyticsFiltersResponse,
  BulkActionUpdateResponse: state?.CampaignsAnalytics.BulkActionUpdateResponse,

  OptionListResponse: state.Dashboard.OptionListResponse,

  DashboardTargetingCardResponse:
    state?.Dashboard.DashboardTargetingCardResponse,

  GetMarketplaceListResponse:
    state?.MarketplaceCredential.GetMarketplaceListResponse,

  RuleManagementGetAppliedRuleLogResponse:
    state.RuleManagement.RuleManagementGetAppliedRuleLogResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CampaignsAnalyticsPerformanceMetricsAction,
      CampaignsAnalyticsDailyPerformanceTrendAction,
      CampaignsAnalyticsFilterListAction,
      ActiveCompaignsAction,
      GetPortfoliosListAction,
      CampaignsAnalyticsAllTableDataAction,
      UpdateCampaignBugestAction,
      CampaignConfigListAction,
      CampaignConfigUpdateAction,
      GetFilterListAction,
      fakeActionCampaignsAnalytics,
      BulkActionUpdateAction,

      getOptionListAction,
      DashboardTargetingCardDataAction,
      fakeActionDashboard,

      // marketplace
      GetMarketplaceListAction,
      fakeActionMarketplace,

      GetRuleInCampaignsAnalyticsAction,
      CampaignAnalyticsBulkApplyAction,
    },
    dispatch
  );

const CampaignsAnalytics_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignsAnalyticsModule);

export default CampaignsAnalytics_;
