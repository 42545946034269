import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ChangePasswordAction,
  UpdateUserAction,
  fakeActionProfile,
} from "../../redux/modules/profile/profile.action";
import MyProfile from "../../modules/pages/settings/my-profile";

const mapStateToProps = (state) => ({
  ChangePasswordResponse: state.profileReducer.ChangePasswordResponse,
  UpdateUserProfileResponse: state.profileReducer.UpdateUserProfileResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ChangePasswordAction,
      UpdateUserAction,
      fakeActionProfile,
    },
    dispatch
  );

const Profile_ = connect(mapStateToProps, mapDispatchToProps)(MyProfile);

export default Profile_;
