import axiosCall from "../../configurations/network-services/axiosCall";

export const CampaignsPortfoliosListAction = (data) => {
  const path = `campaigns/get-portfolios?marketplace_id=${data?.id}`;
  const responseType = "GET_PORTFOLIOS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCampaignConfigAction = () => {
  const path = `campaigns/get-campaign-configs`;
  const responseType = "GET_CAMPAIGN_CONFIG";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetProductsListAction = (data) => {
  const path = `campaigns/get-products?marketplace_id=${
    data?.marketplace_id || "-"
  }&asin=${data?.searchASIN || ""}&search_text=${
    data?.searchText || ""
  }&per-page=${data?.perPage || 10}&page=${data?.page || 1}`;
  const responseType = "GET_PRODUCTS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateCampaignAction = (data) => {
  const path = `campaigns/create-campaign`;
  const responseType = "CREATE_CAMPAIGNS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateCampaignStrategyAction = (data) => {
  const path = `campaigns-strategies/create-campaign-strategy`;
  const responseType = "CREATE_CAMPAIGNS_STRATEGY";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateCampaignTargetingSuggestionsAction = (data) => {
  const path = `campaigns/get-categories-targeting-suggestions`;
  const responseType = "CREATE_CAMPAIGNS_TARGETING_SUGGESTION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CampaignListAction = (data) => {
  const path = `campaigns/get-campaigns?start_date=${
    data?.start_date || ""
  }&campaign_name=${""}&page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&marketplace_id=${data?.marketplace_id || ""}&search_text=${
    data?.campaign_name || ""
  }&campaign_state=${data?.campaign_state || ""}&targeting_type=${
    data?.targeting_type || ""
  }`;
  const responseType = "LIST_CAMPAIGNS";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StrategiesListAction = (data) => {
  const path = `campaigns-strategies/get-campaign-strategies?page=${
    data?.page || 1
  }&per-page=${data?.perPage || 10}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "LIST_STRATEGIES";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetProductTargetingAction = (data) => {
  const path = `campaigns/get-product-targeting-suggestions`;
  const responseType = "GET_PRODUCT_TARGETING";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetKeywordsTargetingAction = (data) => {
  const path = `campaigns/get-keyword-targeting-suggestions`;
  const responseType = "GET_KEYWORDS_TARGETING";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetBidSuggestionsAction = (data) => {
  const path = `campaigns/get-bid-suggestions`;
  const responseType = "GET_BID_SUGGESTIONS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateStrategiesAction = (id, data) => {
  const path = `campaigns-strategies/${id}`;
  const responseType = "CAMPAIGNS_STRATEGIES_UPDATE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DeleteStrategiesAction = (id) => {
  const path = `campaigns-strategies/${id}`;
  const responseType = "CAMPAIGNS_STRATEGIES_DELETE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateCampaignAction = (id, data) => {
  const path = `campaigns/update-campaign/${id}`;
  const responseType = "CAMPAIGNS_UPDATE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DeleteCampaignAction = (data) => {
  const path = `campaigns/delete-campaigns`;
  const responseType = "CAMPAIGNS_DELETE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetAdGroupListAction = (campaign_id) => {
  const path = `campaign-ad-groups/get-ad-groups?campaign_id=${campaign_id}`;
  const responseType = "GET_AD_GROUP_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetAdGroupAllListAction = (campaign_id) => {
  const path = `campaigns/get-all-ad-groups?campaign_id=${campaign_id}`;
  const responseType = "GET_AD_ALL_GROUP_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateAdGroupAction = (id, data) => {
  const path = `campaign-ad-groups/update-ad-group/${id}`;
  const responseType = "UPDATE_AD_GROUP";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCampaignsManagementAnalytics =
  (data) => async (dispatch) => {
    dispatch({ type: "FAKE_ACTION_CAMPAIGNS_MANAGEMENT", state: data });
  };
