import React, { useState } from "react";
import { DatePicker, Statistic, Tag, Table, Switch, Input } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function CampaignsPortfolio() {
  const [gridDrawer, setGridDrawer] = useState(false);
  const { RangePicker } = DatePicker;

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];

  const onRangeChange = (dates, dateStrings) => {};
  const dataSource = [
    {
      key: "1",
      srNo: 1,
      portfolioName: <a className="fw-bold">Sponsored Product &amp; Brand</a>,
      status: <Tag color="success">Delivering</Tag>,
      profileName: "Fresh Wave",
      campaigns: 339,
      budgetCap: "No budget cap",
      budgetDateRange: "--",
      impr: "7.9M",
      clk: "36.7K",
      ctr: "0.46%",
      spend: "$55.4K",
      cpc: "$1.5",
      sales: "$301.7K",
      orders: "$13.2K",
      saleUnits: "$14.1K",
      cvr: "35.82%",
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
    },
    {
      title: "Portfolio Name",
      dataIndex: "portfolioName",
      key: "portfolioName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Profile Name",
      dataIndex: "profileName",
      key: "profileName",
    },
    {
      title: "Campaigns",
      dataIndex: "campaigns",
      key: "campaigns",
    },
    {
      title: "Budget Cap",
      dataIndex: "budgetCap",
      key: "budgetCap",
    },
    {
      title: "Budget Date Range",
      dataIndex: "budgetDateRange",
      key: "budgetDateRange",
    },
    {
      title: "Impr.",
      dataIndex: "impr",
      key: "impr",
    },
    {
      title: "Clk.",
      dataIndex: "clk",
      key: "clk",
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
    },
    {
      title: "Sale Units",
      dataIndex: "saleUnits",
      key: "saleUnits",
    },
    {
      title: "CVR",
      dataIndex: "cvr",
      key: "cvr",
    },
  ];

  const columns_ = [
    {
      title: "Active",
      render: () => {
        return <Switch />;
      },
    },
    {
      title: "Campaigns",
      render: () => {
        return <a>Vendo | Whey Cinnabon 'Cinnabon' KWs | NB | SP</a>;
      },
    },
    {
      title: "Status",
      render: () => {
        return <span>Delivering</span>;
      },
    },
    {
      title: "Type",
      render: () => {
        return <span>Sponsored Product</span>;
      },
    },
    {
      title: "Start date",
      render: () => {
        return <span>Jul 5, 2023</span>;
      },
    },
    {
      title: "End date",
      render: () => {
        return <span>Jul 5, 2023</span>;
      },
    },
    {
      title: "Budget",
      render: () => {
        return <Input prefix="$" />;
      },
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        dataSource={[1]}
        columns={columns_}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    );
  };

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row g-3 g-lg-3">
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-eye fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    <Statistic
                      title="Impressions"
                      value={37}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        37
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Impressions
                      </span> */}
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-directbox-default fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        6
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Sale Units
                      </span> */}
                    <Statistic
                      title="Sale Units"
                      value={6}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-click fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        4,7
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Clicks
                      </span> */}
                    <Statistic
                      title="Clicks"
                      value={47}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={
                        <ArrowDownOutlined style={{ color: "#cf1322" }} />
                      }
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-dollar fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        822
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        ACOS
                      </span> */}
                    <Statistic
                      title="ACOS"
                      value={822}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-handcart fs-1 text-primary" />
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        822
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Orders
                      </span> */}
                    <Statistic
                      title="Orders"
                      value={822}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={
                        <ArrowDownOutlined style={{ color: "#cf1322" }} />
                      }
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-bill fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        822
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Spend
                      </span> */}
                    <Statistic
                      title="Spend"
                      value={822}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={
                        <ArrowDownOutlined style={{ color: "#cf1322" }} />
                      }
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-dollar fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        822
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        Sales
                      </span> */}
                    <Statistic
                      title="Sales"
                      value={822}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-price-tag fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        822
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        CPC
                      </span> */}
                    <Statistic
                      title="CPC"
                      value={822}
                      precision={0}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white  rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-price-tag fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        $6.22
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        CPA
                      </span> */}
                    <Statistic
                      title="CPA"
                      value={6.22}
                      precision={2}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Items*/}
                <div className="bg-white rounded-2 px-6 py-5">
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="ki-duotone ki-price-tag fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Stats*/}
                  <div className="m-0">
                    {/*begin::Number*/}
                    {/* <span className="text-gray-700 fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                        0.33%
                      </span> */}
                    {/*end::Number*/}
                    {/*begin::Desc*/}
                    {/* <span className="text-gray-500 fw-semibold fs-6">
                        CTR
                      </span> */}
                    <Statistic
                      title="CTR"
                      value={0.33}
                      precision={2}
                      valueStyle={{
                        color: "#5e6278",
                        fontWeight: 700,
                        fontSize: "calc(1.26rem + .12vw)",
                      }}
                      prefix={<ArrowUpOutlined style={{ color: "#3f8600" }} />}
                      suffix="%"
                    />
                    {/*end::Desc*/}
                  </div>
                  {/*end::Stats*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Col*/}
            </div>
            <div className="row gy-5 g-xl-5 mt-3">
              <div className="col-xl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bolder">
                      Portfolio Level Data
                    </h3>
                    <div className="card-toolbar my-0">
                      <RangePicker
                        size="large"
                        presets={rangePresets}
                        format="DD/MM/YYYY"
                        onChange={onRangeChange}
                        open
                      />
                      {/* <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" data-kt-daterangepicker-range="today" className="btn btn-sm btn-light d-flex align-items-center px-4">
                                                <div className="text-gray-600 fw-bold">
                                                    
                                                </div>
                                                <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                            </div> */}
                      <div className>
                        <button
                          onClick={() => setGridDrawer(true)}
                          id="kt_drawer_example_basic_button"
                          className="btn btn-icon btn-light btn-active-color-primary btn-sm ms-2"
                        >
                          <i className="ki-duotone ki-setting-2 fs-1">
                            <i className="path1" />
                            <i className="path2" />
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <Table
                        expandedRowRender={expandedRowRender}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                    <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                      <div className="fs-6 fw-bold text-gray-700 d-flex align-items-center">
                        <select
                          name
                          aria-controls
                          className="form-select form-select-sm form-select-solid w-75px me-3"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        Showing 1 to 3 of 50 entries
                      </div>
                      <ul className="pagination">
                        <li className="page-item previous">
                          <a className="page-link">
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link">1</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">2</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">3</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">4</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">5</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">6</a>
                        </li>
                        <li className="page-item next">
                          <a className="page-link">
                            <i className="next" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="kt_drawer_example_basic"
        className={`bg-white drawer drawer-end ${
          gridDrawer ? "drawer-on" : ""
        } `}
        style={{ width: "500px" }}
      >
        <div className="card rounded-0 w-100 ">
          <div className="card-header pe-5">
            <div className="card-title">
              <div className="d-flex justify-content-center flex-column me-3">
                <a className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1">
                  Personalize Grid Configuration
                </a>
              </div>
            </div>
            <div className="card-toolbar">
              <div
                className="btn btn-sm btn-icon btn-active-light-primary"
                onClick={() => setGridDrawer(false)}
              >
                <i className="ki-duotone ki-cross fs-2">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
            </div>
          </div>
          <div className="card-body hover-scroll-overlay-y">
            <div className="border p-4 rounded">
              <div className="d-flex justify-content-between mb-5">
                <span className="fw-bolder ">Column</span>{" "}
                <span className="fw-bolder">Sticky</span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Portfolio Name</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Status</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Profile Name</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Campaigns</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Budget Cap</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Budget Date Range</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Impr.</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Clk.</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">CTR</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Spend</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">CPC</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Sales</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Orders</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">Sale Units</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
              <div className="border border-gray-300 border-dashed rounded d-flex justify-content-between align-items-center p-3 mb-4">
                <span className="fw-bolder fs-7 lh-0">CVR</span>
                <span className>
                  <div className="form-check form-check-solid form-switch mb-0 fv-row">
                    <input
                      className="form-check-input w-35px h-20px ms-auto"
                      type="checkbox"
                      id="allowmarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowmarketing"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer py-4 text-end">
            <button
              className="btn btn-primary fs-7"
              data-kt-drawer-dismiss="true"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignsPortfolio;
