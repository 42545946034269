import { Button, message, Popconfirm, Modal, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../../../components/loading";
import CustomTooltip from "../../../../components/tooltip";
import { APPID, ClientSecret, timeSince, urlDecode } from "../../../../config";
import AdList from "./lib/adList";
import AddNewAddModalView from "./lib/ad-amazon";
import NewAddModal from "./new-add";
import Wrapper from "./style";

const SellerNameModal = ({
  selectedRow,
  modalType,
  visible,
  onClose,

  UserCredentialsSPAction,
}) => {
  const [sellerName, setSellerName] = useState(selectedRow?.seller_name);

  return (
    <Modal
      maskClosable={false}
      title="Edit"
      open={visible}
      onOk={() => {
        message.destroy();
        message.loading("Loading...", 0);
        UserCredentialsSPAction(selectedRow?.id, {
          seller_name: sellerName,
        });
      }}
      onCancel={onClose}
    >
      <div className="d-grid my-10">
        <label>
          Seller Name<small style={{ color: "red" }}>*</small>
        </label>
        <Input
          onChange={(e) => {
            setSellerName(e.target.value);
          }}
          size="large"
          value={sellerName}
          style={{ width: "100%" }}
        />
      </div>
    </Modal>
  );
};

export default function (props) {
  const {
    getUserCredentialsAction,
    fakeActionMarketplace,
    GenerateRefreshTokenAction,
    UserDeleteCredentialsSPAction,
    UserSPCredentialsCallbackAction,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [addNewStatus, setAddNewStatus] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalType, setModalType] = useState("sp");
  const [addNewAddModal, setAddNewAddModal] = useState(false);

  const UserCredentialsListResponse = useSelector(
    (state) => state.MarketplaceCredential.UserCredentialsListResponse || {}
  );
  const GenerateRefreshTokenResponse = useSelector(
    (state) => state.MarketplaceCredential.GenerateRefreshTokenResponse || {}
  );
  const UserSPCredentialsCallbackResponse = useSelector(
    (state) =>
      state.MarketplaceCredential.UserSPCredentialsCallbackResponse || {}
  );
  const UserCredentialsSPResponse = useSelector(
    (state) => state.MarketplaceCredential.UserCredentialsSPResponse || {}
  );
  const UserDeleteCredentialsSPResponse = useSelector(
    (state) => state.MarketplaceCredential.UserDeleteCredentialsSPResponse || {}
  );

  useEffect(() => {
    if (UserDeleteCredentialsSPResponse?.status === true) {
      message.destroy();
      setListLoading(true);
      getUserCredentialsAction();
      message.success(UserDeleteCredentialsSPResponse?.message);
      fakeActionMarketplace("UserDeleteCredentialsSPResponse");
    } else if (UserDeleteCredentialsSPResponse?.status === false) {
      message.destroy();
      message.error(UserDeleteCredentialsSPResponse?.message);
      fakeActionMarketplace("UserDeleteCredentialsSPResponse");
    }
  }, [UserDeleteCredentialsSPResponse]);
  useEffect(() => {
    if (UserCredentialsSPResponse?.status === true) {
      message.destroy();
      setEditModal(false);
      setSelectedRow({});
      setModalType("sp");
      setListLoading(true);
      getUserCredentialsAction();
      message.success(UserCredentialsSPResponse?.message);
      fakeActionMarketplace("UserCredentialsSPResponse");
    } else if (UserCredentialsSPResponse?.status === false) {
      message.destroy();
      message.error(UserCredentialsSPResponse?.message);
      fakeActionMarketplace("UserCredentialsSPResponse");
    }
  }, [UserCredentialsSPResponse]);
  useEffect(() => {
    if (UserSPCredentialsCallbackResponse?.status === true) {
      message.destroy();
      message.success("Account added successfully");
      setListLoading(true);
      getUserCredentialsAction();

      fakeActionMarketplace("UserSPCredentialsCallbackResponse");
      setTimeout(() => {
        window.location.replace("/callback_sp");
      }, 500);
    } else if (UserSPCredentialsCallbackResponse?.status === false) {
      message.destroy();
      message.error(UserSPCredentialsCallbackResponse?.message);

      fakeActionMarketplace("UserSPCredentialsCallbackResponse");
    }
  }, [UserSPCredentialsCallbackResponse]);
  useEffect(() => {
    if (
      GenerateRefreshTokenResponse?.status === true ||
      GenerateRefreshTokenResponse?.status === false
    ) {
      message.destroy();
      localStorage.removeItem("adCode");
      if (GenerateRefreshTokenResponse?.status) {
        setAdList(JSON.parse(GenerateRefreshTokenResponse?.data));

        setAdListModal(true);
        message.success(GenerateRefreshTokenResponse?.message);
      } else {
        message.error(GenerateRefreshTokenResponse?.message);
      }
      fakeActionMarketplace("GenerateRefreshTokenResponse");
    }
  }, [GenerateRefreshTokenResponse]);

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState({
    sellername: userData?.seller_name || "",
    email: userData?.email,
    region_name: "",
    marketplace_id: "",
    seller_type: "seller",
  });
  useEffect(() => {
    // const callBackAd = JSON.parse(
    //   localStorage.getItem("credentials") || {}
    // )?.advertising_return_url?.split("/");

    if (location.pathname.includes("/callback_sp")) {
      if (location?.search) {
        const { selling_partner_id, spapi_oauth_code, state } =
          urlDecode(location);
        const dataView = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          seller_name: state?.split("!!")?.[1] || "",
          marketplace_id: state?.split("!!")?.[3] || "",
        };

        message.destroy();
        message.loading("Loading...");
        alert(JSON.stringify(dataView));
        UserSPCredentialsCallbackAction(dataView);
      } else {
        // setListLoading(true);
        // getUserCredentialsListAction();
      }
    } else if (location.pathname.includes("/callbackads")) {
      setTab(2);
      if (location.search) {
        const { code, marketplace_id, seller_name } = urlDecode(location);

        if (!code) {
          setListLoading(true);
          getUserCredentialsAction();
          return;
        }
        // localStorage.setItem("adCode", code);
        localStorage.setItem("adCode", code);
        history.replace(location?.pathname);
        message.destroy();
        message.loading("Loading...", 0);

        GenerateRefreshTokenAction({
          ad_code: localStorage.getItem("adCode"),
          marketplace_id,
          seller_name,
        });
      } else {
        setListLoading(true);
        getUserCredentialsAction();
      }
    }
  }, [location]);
  const [advertisingAPI, setAdvertisingAPI] = useState([]);
  useEffect(() => {
    if (UserCredentialsListResponse?.status === true) {
      setList(UserCredentialsListResponse?.data?.records?.["SP-API"] || []);
      setAdvertisingAPI(
        UserCredentialsListResponse?.data?.records?.["Advertising-API"]
      );
      setListLoading(false);
      if (!adListModal) {
        fakeActionMarketplace("UserCredentialsListResponse");
      }
    } else if (UserCredentialsListResponse?.status === false) {
      setListLoading(false);
      setList([]);
      fakeActionMarketplace("UserCredentialsListResponse");
    }
  }, [UserCredentialsListResponse]);
  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(APPID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);
    setListLoading(true);
    getUserCredentialsAction();
    return () => {};
  }, []);
  return (
    <Wrapper className="mx-5 my-5">
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container-fluid">
          <div className="row">
            <div className="col-md-12 cus-tab">
              <ul className="nav nav-pills mb-5 fs-6" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 1 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => {
                      setTab(1);
                      history.replace("/callback_sp");
                    }}
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    Amazon SP API Credentials
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 2 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => {
                      setTab(2);
                      history.replace("/callbackads");
                    }}
                    aria-selected="true"
                    role="tab"
                  >
                    Amazon Advertising Credentials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/*begin::Row*/}
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tab === 1 ? "active show" : ""}`}
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon SP API Credentials List
                      </h3>
                      <div className="card-toolbar">
                        <div className="card-toolbar">
                          <button
                            type="button"
                            className="btn btn-primary px-7"
                            onClick={() => {
                              setAddNewStatus(true);
                            }}
                          >
                            Add New{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-2">
                      {listLoading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-100px">Region</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-75px">Marketplace</th>
                                {/* <th className="min-w-150px">AWS Access Key</th> */}
                                {/* <th className="min-w-150px">AWS Secret Key</th> */}
                                <th className="min-w-175px">LWA ClientID</th>
                                <th className="min-w-150px">Action</th>
                                {/* <th className="min-w-175px">LWA Secret</th> */}
                                {/* <th className="min-w-225px">Refresh Token</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {list?.map((d, i) => {
                                const credential = d;
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{credential?.seller_name}</td>
                                    <td>{credential?.role_arn}</td>
                                    <td>{credential?.region}</td>
                                    <td>{d?.credential_type}</td>
                                    <td>{d?.marketplace}</td>
                                    {/* <td>-</td> */}
                                    {/* <td>{credential?.access_key}</td> */}
                                    {/* <td>
                                      <CustomTooltip maxTextWidth={150}>
                                        -{credential?.secret_key}
                                      </CustomTooltip>
                                    </td> */}
                                    <td>
                                      <CustomTooltip maxTextWidth={175}>
                                        {credential?.client_id}
                                      </CustomTooltip>
                                    </td>
                                    <td>
                                      <div
                                        className="d-flex"
                                        id="userActionIcons"
                                      >
                                        <div className="Edit-Icon-Antd d-flex">
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              setEditModal(true);
                                              setSelectedRow(d);
                                              setModalType("sp");
                                            }}
                                          >
                                            <EditTwoTone
                                              style={{
                                                position: "relative",
                                                top: -3,
                                              }}
                                            />
                                          </Button>
                                        </div>
                                        <Popconfirm
                                          title={
                                            <div className="d-grid">
                                              <span>
                                                Are you sure you want to delete
                                                this record?
                                              </span>
                                              <span>
                                                If you delete this record all
                                                the logs and data will be
                                                removed which has been processed
                                                till date.
                                              </span>
                                            </div>
                                          }
                                          placement="left"
                                          cancelText="No"
                                          okText="Yes"
                                          onConfirm={() => {
                                            message.destroy();
                                            message.loading("Loading...", 0);
                                            UserDeleteCredentialsSPAction(
                                              d?.id,
                                              { seller_name: d?.seller_name }
                                            );
                                          }}
                                        >
                                          <div className="Delete-Icon-Antd d-flex">
                                            <Button
                                              type="dashed"
                                              className="ms-3"
                                            >
                                              <DeleteTwoTone
                                                style={{
                                                  position: "relative",
                                                  top: -3,
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </Popconfirm>
                                      </div>
                                    </td>
                                    {/* <td>
                                      <CustomTooltip maxTextWidth={175}>
                                        -{credential?.client_secret}
                                      </CustomTooltip>
                                    </td> */}
                                    {/* <td>
                                      <CustomTooltip maxTextWidth={225}>
                                        -{credential?.refresh_token}
                                      </CustomTooltip>
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tab === 2 ? "active show" : ""}`}
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon Advertising Credentials List
                      </h3>
                      <div className="card-toolbar">
                        <button
                          type="button"
                          className="btn btn-primary px-7"
                          onClick={() => setAddNewAddModal(true)}
                        >
                          Add New
                        </button>
                        {/* <div id="amazon-root">
                          <a
                            id="LoginWithAmazon"
                            style={{
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              let options = {};
                              options.scope =
                                "advertising::campaign_management";
                              options.response_type = "code";
                              window.amazon.Login.authorize(
                                options,
                                `https://app.vfunnel.ai/callbackads?marketplace_id=ATVPDKIKX0DER&seller_name=${
                                  JSON.parse(localStorage.getItem("user"))
                                    ?.seller_name || "test"
                                }`
                              );
                            }}
                          >
                            <img
                              border="0"
                              alt="Login with Amazon"
                              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                              width="156"
                              height="32"
                            />
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="card-body py-2">
                      {listLoading ? (
                        <Loading />
                      ) : advertisingAPI?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th>#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-100px">Profile ID</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-100px">Client ID</th>
                                <th className="min-w-125px">Country Code</th>
                                <th className="min-w-125px">Currency Code</th>
                                <th
                                  style={{ maxWidth: "100px" }}
                                  // className="max-w-100px"
                                >
                                  Time Zone
                                </th>
                                <th
                                  style={{ maxWidth: "100px" }}
                                  // className="max-w-100px"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {advertisingAPI?.map((d, i) => {
                                const credential_details = d;
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      {credential_details?.seller_name || "-"}
                                    </td>
                                    <td>
                                      {credential_details?.profile_id || "-"}
                                    </td>
                                    <td>{d?.credential_type || "-"}</td>
                                    <td>
                                      {credential_details?.client_id || "-"}
                                    </td>
                                    <td>
                                      {credential_details?.country_code || "-"}
                                    </td>
                                    <td>
                                      {credential_details?.currency_code || "-"}
                                    </td>
                                    <td
                                      className="over2"
                                      style={{ maxWidth: "100px" }}
                                    >
                                      {credential_details?.time_zone || "-"}
                                    </td>
                                    <td>
                                      <div
                                        className="d-flex"
                                        id="userActionIcons"
                                      >
                                        <div className="Edit-Icon-Antd d-flex">
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              setEditModal(true);
                                              setSelectedRow(d);
                                              setModalType("ad");
                                            }}
                                          >
                                            <EditTwoTone
                                              style={{
                                                position: "relative",
                                                top: -3,
                                              }}
                                            />
                                          </Button>
                                        </div>
                                        <Popconfirm
                                          title={
                                            <div className="d-grid">
                                              <span>
                                                Are you sure you want to delete
                                                this record?
                                              </span>
                                              <span>
                                                If you delete this record all
                                                the logs and data will be
                                                removed which has been processed
                                                till date.
                                              </span>
                                            </div>
                                          }
                                          placement="left"
                                          cancelText="No"
                                          okText="Yes"
                                          onConfirm={() => {
                                            message.destroy();
                                            message.loading("Loading...", 0);
                                            UserDeleteCredentialsSPAction(
                                              d?.id,
                                              { seller_name: d?.seller_name }
                                            );
                                          }}
                                        >
                                          <div className="Delete-Icon-Antd d-flex">
                                            <Button
                                              type="dashed"
                                              className="ms-3"
                                            >
                                              <DeleteTwoTone
                                                style={{
                                                  position: "relative",
                                                  top: -3,
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </Popconfirm>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {/*end::Table*/}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      <div id="amazon-root"></div>
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          getList={() => {
            setListLoading(true);
            getUserCredentialsAction();
          }}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
      {addNewAddModal && (
        <AddNewAddModalView
          show={addNewAddModal}
          close={() => setAddNewAddModal(false)}
          {...props}
        />
      )}
      {addNewStatus && (
        <NewAddModal
          show={addNewStatus}
          onHide={() => {
            setAddNewStatus(false);
            setSelectedMarketplace({
              sellername: userData?.seller_name || "",
              email: userData?.email,
              region_name: "",
              marketplace_id: "",
            });
          }}
          setSelectedMarketplace={setSelectedMarketplace}
          selectedMarketplace={selectedMarketplace}
          {...props}
        />
      )}
      {editModal && (
        <SellerNameModal
          sellerName={"sellerName"}
          selectedRow={selectedRow}
          modalType={modalType}
          visible={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
}
