export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "USER_CREDENTIALS_LIST_SUCCESS":
    case "USER_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        UserCredentialsListResponse: action.updatePayload,
      };
    case "GENERATE_REFRESH_TOEKN_SUCCESS":
    case "GENERATE_REFRESH_TOEKN_ERROR":
      return {
        ...state,
        GenerateRefreshTokenResponse: action.updatePayload,
      };
    case "SAVE_ADVERTISTING_PROFILE_SUCCESS":
    case "SAVE_ADVERTISTING_PROFILE_ERROR":
      return {
        ...state,
        SaveAdProfileResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: {
          ...action.updatePayload,
          data: action.updatePayload?.data?.all_marketplaces,
          selected_marketplaces:
            action.updatePayload?.data?.selected_marketplaces?.length === 0
              ? []
              : Object?.entries(
                  action.updatePayload?.data?.selected_marketplaces || {}
                )?.map(([key, value]) => ({ name: value, id: key })),
        },
      };
    case "USER_SP_CREDENTIALS_CALLBACK_SUCCESS":
    case "USER_SP_CREDENTIALS_CALLBACK_ERROR":
      return {
        ...state,
        UserSPCredentialsCallbackResponse: action.updatePayload,
      };
    case "USER_CREDENTIALS_SP_SUCCESS":
    case "USER_CREDENTIALS_SP_ERROR":
      return {
        ...state,
        UserCredentialsSPResponse: action.updatePayload,
      };
    case "USER_DELETE_CREDENTIALS_SP_SUCCESS":
    case "USER_DELETE_CREDENTIALS_SP_ERROR":
      return {
        ...state,
        UserDeleteCredentialsSPResponse: action.updatePayload,
      };

    case "FAKE_ACTION_MARKETPLACE_CREDENTIALS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
