import {
  Avatar,
  Button,
  Divider,
  Empty,
  Image,
  Input,
  List,
  Segmented,
  Skeleton,
  Upload,
  message,
} from "antd";
import React, { useState, useEffect } from "react";

import {
  CaretRightOutlined,
  SearchOutlined,
  FileSearchOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { TableLoading } from "../../../../../../../../components/table-animation";
import Pagination from "../../../../../../../../components/pagination";
import { DefaultPerPage, EndPoint } from "../../../../../../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import TextArea from "antd/es/input/TextArea";
import Papa from "papaparse";
import Dragger from "antd/es/upload/Dragger";

const AddProduct = (props) => {
  const {
    GetProductsListAction,
    basicDetails,
    setBasicDetails,
    groupIndex,
    GetBidSuggestionsAction,
    GetKeywordsTargetingAction,
    fakeActionCampaignsManagementAnalytics,
    GetProductTargetingAction,
    CreateCampaignTargetingSuggestionsAction,
  } = props;

  const [searchASIN, setSearchASIN] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [typeFilter, setTypeFilter] = useState("search_product");
  const [enterASINs, setEnterASINs] = useState("");

  const GetProductsListResponse = useSelector(
    (state) => state.CampaignManagement.GetProductsListResponse || {}
  );

  useEffect(() => {
    if (GetProductsListResponse?.status === true) {
      if (GetProductsListResponse?.data?.pagination?.page === 1 && searchText) {
        setProductList([...GetProductsListResponse?.data?.records]);
      } else {
        setProductList([
          ...productList,
          ...GetProductsListResponse?.data?.records,
        ]);
      }

      setTotalPage(GetProductsListResponse?.data?.pagination?.totalCount || 0);
      setLoading(false);
      fakeActionCampaignsManagementAnalytics("GetProductsListResponse");
    } else if (GetProductsListResponse?.status === false) {
      setProductList([]);
      setLoading(false);
      fakeActionCampaignsManagementAnalytics("GetProductsListResponse");
    }
  }, [GetProductsListResponse]);

  const addAsinListCustom = (list) => {
    const updatedData = { ...basicDetails };
    list?.forEach((e) => {
      updatedData.ad_groups_data[groupIndex].product_ads.push({
        ...e,
        state: "ENABLED",
      });
    });

    if (basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0) {
      message.destroy();
      message.loading("Loading...", 0);
      GetBidSuggestionsAction({
        marketplace_id: basicDetails?.marketplace_id,
        asins: updatedData.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
        top_of_search_placement:
          basicDetails?.campaign_data?.top_of_search_placement,
        product_pages_placement:
          basicDetails?.campaign_data?.product_pages_placement,
        bidding_strategy: "AUTO_FOR_SALES",
      });
      CreateCampaignTargetingSuggestionsAction({
        marketplace_id: basicDetails?.marketplace_id,

        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
      GetProductTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
      GetKeywordsTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        bidding_strategy: "AUTO_FOR_SALES",
        sort_dimension: "CLICKS",
        limit: 30,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
    }

    // if (
    //   basicDetails?.campaign_data?.targeting_type === "AUTO" &&
    //   basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    // ) {
    //   message.destroy();
    //   message.loading("Loading...", 0);
    //   GetBidSuggestionsAction({
    //     marketplace_id: "A21TJRUUN4KGV",
    //     asins: updatedData.ad_groups_data[groupIndex].product_ads?.map(
    //       (d) => d?.asin1
    //     ),
    //     top_of_search_placement:
    //       basicDetails?.campaign_data?.top_of_search_placement,
    //     product_pages_placement:
    //       basicDetails?.campaign_data?.product_pages_placement,
    //     bidding_strategy: "AUTO_FOR_SALES",
    //   });
    // } else {
    //   if (
    //     basicDetails?.manualType === "product" &&
    //     basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    //   ) {
    //     message.destroy();
    //     message.loading("Loading...", 0);
    //     CreateCampaignTargetingSuggestionsAction({
    //       marketplace_id: basicDetails?.marketplace_id,

    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //     GetProductTargetingAction({
    //       marketplace_id: basicDetails?.marketplace_id,
    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //   }
    //   if (
    //     basicDetails?.manualType === "keyword" &&
    //     basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    //   ) {
    //     message.destroy();
    //     message.loading("Loading...", 0);
    //     GetKeywordsTargetingAction({
    //       marketplace_id: basicDetails?.marketplace_id,
    //       bidding_strategy: "AUTO_FOR_SALES",
    //       sort_dimension: "CLICKS",
    //       limit: 30,
    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //   }
    // }

    setBasicDetails({ ...updatedData });
  };
  const addAsinCustom = (obj) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].product_ads.push({
      ...obj,
      state: "ENABLED",
    });

    if (basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0) {
      message.destroy();
      message.loading("Loading...", 0);
      GetBidSuggestionsAction({
        marketplace_id: updatedData?.marketplace_id,
        asins: updatedData.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
        top_of_search_placement:
          basicDetails?.campaign_data?.top_of_search_placement,
        product_pages_placement:
          basicDetails?.campaign_data?.product_pages_placement,
        bidding_strategy: "AUTO_FOR_SALES",
      });
      CreateCampaignTargetingSuggestionsAction({
        marketplace_id: basicDetails?.marketplace_id,

        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
      GetProductTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
      GetKeywordsTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        bidding_strategy: "AUTO_FOR_SALES",
        sort_dimension: "CLICKS",
        limit: 30,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
    }

    // if (
    //   basicDetails?.campaign_data?.targeting_type === "AUTO" &&
    //   basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    // ) {
    //   message.destroy();
    //   message.loading("Loading...", 0);
    //   GetBidSuggestionsAction({
    //     marketplace_id: "A21TJRUUN4KGV",
    //     asins: updatedData.ad_groups_data[groupIndex].product_ads?.map(
    //       (d) => d?.asin1
    //     ),
    //     top_of_search_placement:
    //       basicDetails?.campaign_data?.top_of_search_placement,
    //     product_pages_placement:
    //       basicDetails?.campaign_data?.product_pages_placement,
    //     bidding_strategy: "AUTO_FOR_SALES",
    //   });
    // } else {
    //   if (
    //     basicDetails?.manualType === "product" &&
    //     basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    //   ) {
    //     message.destroy();
    //     message.loading("Loading...", 0);
    //     CreateCampaignTargetingSuggestionsAction({
    //       marketplace_id: basicDetails?.marketplace_id,

    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //     GetProductTargetingAction({
    //       marketplace_id: basicDetails?.marketplace_id,
    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //   }
    //   if (
    //     basicDetails?.manualType === "keyword" &&
    //     basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    //   ) {
    //     message.destroy();
    //     message.loading("Loading...", 0);
    //     GetKeywordsTargetingAction({
    //       marketplace_id: basicDetails?.marketplace_id,
    //       bidding_strategy: "AUTO_FOR_SALES",
    //       sort_dimension: "CLICKS",
    //       limit: 30,
    //       asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
    //         (d) => d?.asin1
    //       ),
    //     });
    //   }
    // }

    setBasicDetails({ ...updatedData });
  };

  const removeAll = (obj) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].product_ads = [];

    setBasicDetails({ ...updatedData });
  };

  const RemoveAsin = (productIndex) => {
    if (groupIndex < 0 || groupIndex >= basicDetails.ad_groups_data.length)
      return;

    const adGroup = basicDetails.ad_groups_data[groupIndex];

    if (productIndex < 0 || productIndex >= adGroup.product_ads.length) return;

    adGroup.product_ads.splice(productIndex, 1);
    setBasicDetails({ ...basicDetails });
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setPage(page + 1);

    GetProductsListAction({
      page: page + 1,
      perPage: pageSize,
      ...basicDetails,
      searchText: searchText,
      searchASIN: searchASIN,
    });
  };
  useEffect(() => {
    loadMoreData();
  }, []);

  const enterList = (data) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      body: null,
    };

    fetch(
      `${EndPoint}api/v1/campaigns/get-products?marketplace_id=${
        basicDetails?.marketplace_id || ""
      }&asin=${enterASINs || data || ""}&no_pagination=true`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const unicASIN = [];
        result?.data?.records?.forEach((e) => {
          const index = basicDetails?.ad_groups_data?.[
            groupIndex
          ]?.product_ads?.findIndex((r) => r?.asin1 === e?.asin1);

          if (index === -1) {
            unicASIN?.push(e);
          }
        });
        setEnterASINs("");
        addAsinListCustom(unicASIN);
      })
      .catch((error) => console.log("error", error));
  };
  const handleUpload = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        // 'results' contains the parsed CSV data
        enterList(results.data?.map((d) => d?.ASIN));

        // Perform actions with the parsed data
      },
      header: true, // Set this to true if your CSV has headers
      dynamicTyping: true, // Automatically convert string data to numbers, etc.
    });
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      handleUpload(file);
    }, 0);
  };
  const actionObj = {
    search_product: (
      <div className="filter-asin mt-4 mb-4">
        <Input
          placeholder="Search by Product name or ASIN"
          size="large"
          className="antd-border-add"
          addonBefore={<SearchOutlined />}
          onPressEnter={() => {
            setLoading(true);
            setPage(1);
            setPageSize(20);
            GetProductsListAction({
              page: page,
              perPage: pageSize,
              ...basicDetails,
              searchText: searchText,
              searchASIN: searchASIN,
            });
          }}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <small>Search for products you want to all asins.</small>
      </div>
    ),
    enter_list_product: (
      <div className="mt-5">
        <TextArea
          rows={4}
          cols={30}
          placeholder="Enter ASINs seprated by comma, space or new line."
          onChange={(e) => setEnterASINs(e.target.value)}
          value={enterASINs || null}
        />

        <div className="mt-3 text-end">
          <button
            onClick={() => {
              if (!enterASINs) {
                message.destroy();
                return message?.warning("Please Enter ASIN");
              }
              enterList();
            }}
            className="btn btn-dark fs-7"
          >
            Add
          </button>
        </div>
      </div>
    ),
    upload_product: (
      <div className="mt-5">
        <Dragger customRequest={customRequest} showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </div>
    ),
  };

  return (
    <div className="campaign-specifications">
      <div className="row">
        <div className="col-xl-6 col-md-12">
          <div className="add-products-title d-flex align-items-center justify-content-between">
            <h3>
              <b>Add Products</b>
            </h3>
            <div className="add-products-filters">
              <Segmented
                className="step-1-segment-view"
                onChange={(e) => setTypeFilter(e)}
                value={typeFilter}
                style={{ marginBottom: ".5rem" }}
                options={[
                  {
                    value: `search_product`,
                    label: "Search",
                    icon: <FileSearchOutlined />,
                  },
                  {
                    value: `enter_list_product`,
                    label: "Enter List",
                    icon: <FileDoneOutlined />,
                  },
                  {
                    value: `upload_product`,
                    label: "Upload",
                    icon: <CloudUploadOutlined />,
                  },
                ]}
              />
            </div>
          </div>
          {actionObj?.[typeFilter]}
          {typeFilter !== "enter_list_product" &&
            "upload_product" !== typeFilter && (
              <div className="product-body">
                <div
                  id="scrollableDiv"
                  style={{
                    height: 460,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={productList.length}
                    next={loadMoreData}
                    hasMore={productList.length < totalPage}
                    loader={
                      <Skeleton
                        avatar
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    }
                    endMessage={
                      <Divider plain>It is all, nothing more 🤐</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={productList}
                      renderItem={(d) => (
                        <List.Item>
                          <div className="list-card" style={{ width: "100%" }}>
                            <div className="details">
                              <Image
                                src={d?.image_url}
                                className="list-image"
                              />
                              <div className="list-details">
                                <div className="list-title">
                                  {d?.item_name || "-"}
                                </div>

                                <div className="list-details-info">
                                  <div>
                                    ASIN: <span>{d?.asin1 || "-"}</span>
                                  </div>
                                  <div>
                                    SKU: <span>{d?.seller_sku}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="list-action">
                              {basicDetails.ad_groups_data[
                                groupIndex
                              ].product_ads.findIndex(
                                (productAd) => productAd.id === d?.id
                              ) === -1 && (
                                <div className="action-remove">
                                  <button
                                    onClick={() => addAsinCustom(d)}
                                    type="button"
                                    className="ant-btn action-btn-add"
                                  >
                                    <span>Add</span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
                {/* {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3]}
                checkBoxVal={true}
                row={10}
                actions={[1, 2]}
              />
            ) : (
              <>
                {productList?.map((d, i) => (
                  <div className="list-card" key={i}>
                    <div className="details">
                      <Image src={d?.image_url} className="list-image" />
                      <div className="list-details">
                        <div className="list-title">{d?.item_name || "-"}</div>

                        <div className="list-details-info">
                          <div>
                            ASIN: <span>{d?.asin1 || "-"}</span>
                          </div>
                          <div>
                            SKU: <span>{d?.seller_sku}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-action">
                      {basicDetails.ad_groups_data[
                        groupIndex
                      ].product_ads.findIndex(
                        (productAd) => productAd.id === d?.id
                      ) === -1 && (
                        <div className="action-remove">
                          <button
                            onClick={() => addAsinCustom(d)}
                            type="button"
                            className="ant-btn action-btn-add"
                          >
                            <span>Add</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <Pagination
                  loading={loading || productList?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </>
            )} */}
              </div>
            )}
        </div>
        <div className="col-xl-6 col-md-12" style={{ position: "relative" }}>
          <div className="add-products-title  d-flex align-items-center justify-content-between">
            <h3>
              <b>Selected Products</b>
            </h3>
            {basicDetails?.ad_groups_data?.[groupIndex]?.product_ads?.length !==
              0 && (
              <a
                onClick={() => removeAll()}
                className="btn fs-7 btn-light-danger fw-bold btn-sm"
              >
                Remove All
              </a>
            )}
          </div>

          {(basicDetails?.ad_groups_data?.[groupIndex]?.product_ads || [])
            ?.length === 0 ? (
            <Empty
              style={{
                position: "absolute",
                inset: 0,
                margin: "auto",
                width: "fit-content",
                height: "fit-content",
              }}
            />
          ) : (
            <div className="product-body pt-5" style={{ position: "relative" }}>
              {(
                basicDetails?.ad_groups_data?.[groupIndex]?.product_ads || []
              )?.map((d, i) => (
                <div className="list-card" key={i} style={{ width: "100%" }}>
                  <div className="details">
                    <img src={d?.image_url} className="list-image" />
                    <div className="list-details">
                      <div className="list-title">{d?.item_name || "-"}</div>

                      <div className="list-details-info">
                        <div>
                          ASIN: <span>{d?.asin1 || "-"}</span>
                        </div>
                        <div>
                          SKU: <span>{d?.seller_sku || "-"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-action">
                    <div className="action-remove">
                      <span
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => {
                          RemoveAsin(i);
                        }}
                      >
                        <i className="ki-outline ki-trash fs-3 text-danger" />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
