export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_RULE_MANAGEMENT_LIST_SUCCESS":
    case "GET_RULE_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetRuleManagementListResponse: action.updatePayload,
      };
    case "GET_RULE_MANAGEMENT_DROPDOWN_SUCCESS":
    case "GET_RULE_MANAGEMENT_DROPDOWN_ERROR":
      return {
        ...state,
        GetRuleManagementDropdownResponse: action.updatePayload,
      };
    case "CREATE_NEW_RULE_SUCCESS":
    case "CREATE_NEW_RULE_ERROR":
      return {
        ...state,
        CreateNewRuleResponse: action.updatePayload,
      };
    case "UPDATE_RULE_SUCCESS":
    case "UPDATE_RULE_ERROR":
      return {
        ...state,
        UpdateRuleResponse: action.updatePayload,
      };
    case "DELETE_RULE_SUCCESS":
    case "DELETE_RULE_ERROR":
      return {
        ...state,
        DeleteRuleResponse: action.updatePayload,
      };
    case "GET_RECOMMENDED_EVENT_LIST_SUCCESS":
    case "GET_RECOMMENDED_EVENT_LIST_ERROR":
      return {
        ...state,
        GetRecommendedEventListResponse: action.updatePayload,
      };
    case "UPDATE_MASTER_RULE_SUCCESS":
    case "UPDATE_MASTER_RULE_ERROR":
      return {
        ...state,
        UpdateMasterRuleResponse: action.updatePayload,
      };
    case "UPDATE_STATUS_RULE_SUCCESS":
    case "UPDATE_STATUS_RULE_ERROR":
      return {
        ...state,
        UpdateRuleStatusResponse: action.updatePayload,
      };
    case "UPDATE_SUB_STATUS_RULE_SUCCESS":
    case "UPDATE_SUB_STATUS_RULE_ERROR":
      return {
        ...state,
        UpdateSubRuleStatusResponse: action.updatePayload,
      };
    case "RULE_MANAGEMENT_GET_APPLIED_RULE_LOG_SUCCESS":
    case "RULE_MANAGEMENT_GET_APPLIED_RULE_LOG_ERROR":
      return {
        ...state,
        RuleManagementGetAppliedRuleLogResponse: action.updatePayload,
      };
    case "FAKE_ACTION_RULE_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
