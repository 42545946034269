import { Segmented, message } from "antd";
import React, { useEffect, useState } from "react";
import Wrapper from "./style";
import CreationStrategy from "./lib/Campaigns Strategy";
import CreationList from "./lib/Campaign List";
import CampaignCreation from "./lib/Campaign Creation";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CampaignManagement = (props) => {
  const {
    fakeActionMarketplace,
    GetMarketplaceListAction,
    GetCampaignConfigAction,
    fakeActionCampaignsManagementAnalytics,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const [selectedModule, setSelectedModule] = useState("Campaign Strategy");
  const [strategyList, setStrategyList] = useState([]);
  const [strategyLoading, setStrategyLoading] = useState(true);

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);

  const [selectedMarketplaceList, setSelectedMarketplaceList] = useState(null);
  const [selectedMarketplaceStrategy, setSelectedMarketplaceStrategy] =
    useState(null);
  const [selectedMarketplaceCreate, setSelectedMarketplaceCreate] =
    useState(null);

  const [configsLoading, setConfigsLoading] = useState(true);
  const [configsData, setConfigsData] = useState({});

  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );
  const GetCampaignConfigResponse = useSelector(
    (state) => state.CampaignManagement.GetCampaignConfigResponse || {}
  );

  useEffect(() => {
    if (GetCampaignConfigResponse?.status === true) {
      setConfigsData(GetCampaignConfigResponse?.data || {});
      setConfigsLoading(false);
      fakeActionCampaignsManagementAnalytics("GetCampaignConfigResponse");
    } else if (GetCampaignConfigResponse?.status === false) {
      setConfigsData({});
      setConfigsLoading(false);
      fakeActionCampaignsManagementAnalytics("GetCampaignConfigResponse");
    }
  }, [GetCampaignConfigResponse]);

  useEffect(() => {
    if (
      !strategyLoading &&
      localStorage.getItem("CampaignData") &&
      selectedModule === "Campaign Strategy"
    ) {
      localStorage.removeItem("CampaignData");
      setSelectedModule("Campaign List");
    }
  }, [strategyLoading]);

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      if (GetMarketplaceListResponse?.selected_marketplaces?.length > 0) {
        setSelectedMarketplaceList(
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
            GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id
        );
        setSelectedMarketplaceStrategy(
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
            GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id
        );
        setSelectedMarketplaceCreate(
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
            GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id
        );
      }
      setMarketplaceList(GetMarketplaceListResponse?.selected_marketplaces);
      setMarketplaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setSelectedMarketplaceStrategy(null);
      setSelectedMarketplaceList(null);
      setSelectedMarketplaceCreate(null);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  const Modules = {
    "Campaign Strategy": (
      <CreationStrategy
        setStrategyList={setStrategyList}
        setStrategyLoading={setStrategyLoading}
        marketplaceLoading={marketplaceLoading}
        marketplaceList={marketplaceList}
        configsData={configsData}
        selectedMarketplaceStrategy={selectedMarketplaceStrategy}
        setSelectedMarketplaceStrategy={setSelectedMarketplaceStrategy}
        {...props}
      />
    ),
    "Campaign Creation": (
      <CampaignCreation
        strategyList={strategyList}
        strategyLoading={strategyLoading}
        marketplaceLoading={marketplaceLoading}
        marketplaceList={marketplaceList}
        configsData={configsData}
        configsLoading={configsLoading}
        selectedMarketplaceCreate={selectedMarketplaceCreate}
        setSelectedMarketplaceCreate={setSelectedMarketplaceCreate}
        {...props}
      />
    ),
    "Campaign List": (
      <CreationList
        marketplaceLoading={marketplaceLoading}
        marketplaceList={marketplaceList}
        configsData={configsData}
        configsLoading={configsLoading}
        selectedMarketplaceList={selectedMarketplaceList}
        setSelectedMarketplaceList={setSelectedMarketplaceList}
        {...props}
      />
    ),
  };

  useEffect(() => {
    setMarketplaceLoading(true);
    setMarketplaceList([]);
    GetMarketplaceListAction();

    setConfigsLoading(true);
    setConfigsData({});
    GetCampaignConfigAction();
    if (location?.search?.split("=")?.[1]) {
      setSelectedModule("Campaign List");
    }

    return () => {};
  }, []);
  return (
    <Wrapper className="p-10 ">
      <Segmented
        size="large"
        onChange={(e) => {
          history.push("/campaign-management");

          setSelectedModule(e);
        }}
        value={selectedModule}
        options={["Campaign Strategy", "Campaign Creation", "Campaign List"]}
        className="mb-5"
      />
      {Modules?.[selectedModule]}
    </Wrapper>
  );
};

export default CampaignManagement;
