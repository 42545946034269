import { Form, Input, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { Wrapper } from "./style";
import { GlobalContext } from "../../../commonContext";

function SignUp(props) {
  const { signUpUserAction, fakeActionAuth } = props;
  const [form] = Form.useForm();
  const contextVar = useContext(GlobalContext);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  const SignUpUserResponse = useSelector(
    (state) => state.Auth.SignUpUserResponse || {}
  );

  useEffect(() => {
    if (SignUpUserResponse?.status === true) {
      message.destroy();
      message.success(SignUpUserResponse?.message);
      setLoading(false);

      // login

      setLoading(false);
      localStorage.setItem(
        "user",
        JSON.stringify(SignUpUserResponse?.data?.user_data)
      );
      localStorage.setItem(
        "token",
        SignUpUserResponse?.data?.auth_token_data?.access_token
      );
      const userType = SignUpUserResponse?.data?.user_data?.user_type;

      contextVar?.updateCommonGlobalVal(
        "profile",
        SignUpUserResponse?.data?.user_data,
        {
          back: userType == 1,
        }
      );
      localStorage.setItem("userType", userType);
      setRedirect(`${userType == 1 ? "/manage-seller-users" : "/"}`);
      //end login
      fakeActionAuth("SignUpUserResponse");
      // window.location.assign("/login");
    } else if (SignUpUserResponse?.status === false) {
      message.destroy();
      message.error(SignUpUserResponse?.message);
      setLoading(false);
      fakeActionAuth("SignUpUserResponse");
    }
  }, [SignUpUserResponse]);

  const validateName = (_, value) => {
    const pattern = /^[A-Za-z\s]+$/; // Regular expression to match letters and spaces
    if (!value || pattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter only characters");
  };
  const validatePassword = (_, value) => {
    if (!value || (value.length >= 8 && value.length <= 16)) {
      return Promise.resolve();
    }
    return Promise.reject("Password must be between 8 and 16 characters.");
  };

  if (redirect || localStorage.getItem("token")) {
    return <Redirect to={redirect || "/"} />;
  }

  return (
    <Wrapper>
      <div
        style={{ height: "100%" }}
        className="d-flex flex-column flex-root"
        id="kt_app_root"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-light">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10 ">
                <div className="text-center mb-11 ">
                  <Link to="/">
                    <img
                      alt="Logo"
                      src="/assets/media/vfads.png"
                      className="h-60px h-lg-55px"
                    />
                  </Link>
                </div>
                <div className="form w-100" id="kt_sign_up_form">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                    <div className="text-gray-500 fw-semibold fs-6">
                      Your Social Campaigns
                    </div>
                  </div>
                  <Form
                    form={form}
                    name="register"
                    autoComplete="new-password"
                    layout="vertical"
                    style={{
                      maxWidth: 600,
                    }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="name"
                      label="Name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input your Name!",
                        },
                        {
                          validator: validateName,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      hasFeedback
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                      name="user_type"
                      label="User Type"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select User Type!",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: "Admin", value: 1 },
                          { label: "User", value: 2 },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password autoComplete="new-password" />
                    </Form.Item>

                    <Form.Item
                      name="confirm_password"
                      label="Confirm Password"
                      hasFeedback
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password autoComplete="new-password" />
                    </Form.Item>
                  </Form>

                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-primary"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            //   form.resetFields();
                            setLoading(true);
                            signUpUserAction(values);
                          })
                          .catch((info) => {});
                      }}
                    >
                      {loading ? (
                        <span className="">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Sign Up</span>
                      )}
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an Account?
                    <Link to="/login" className="link-primary fw-bold">
                      {" "}
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-white"
            style={{}}
          >
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img
                className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                src="/assets/media/login.svg"
                alt=""
              />
              <h1 className="d-none d-lg-block text-dark fs-2qx fw-bolder text-center mb-7">
                E-Commerce &amp; Digital Marketing Services
              </h1>
              <div className="d-none d-lg-block text-dark fs-4 text-center">
                We are a team of digital marketing experts &amp; business <br />
                entrepreneurs with know how strategy to grow companies in
                e-commerce
                <br /> and develop a strong presence in the market.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default SignUp;
