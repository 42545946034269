import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { nameObject } from "../../../core/lib";
import { Button, Dropdown } from "antd";
import { GlobalContext } from "../../../commonContext";
import Wrapper from "./style";

function Header() {
  const location = useLocation();
  const history = useHistory();

  const [current, setCurrent] = useState({
    name: "",
  });

  const contextValue = useContext(GlobalContext);
  const { back, profile } = contextValue?.data;
  const { user_type } = profile;

  const [themes, setThemes] = useState(localStorage.getItem("data-bs-theme"));

  const setThemeValue = (type) => {
    var defaultThemeMode = type;
    setThemes(type);
    localStorage.setItem("data-bs-theme", defaultThemeMode);
    var themeMode;
    if (document.documentElement) {
      if (document.documentElement.hasAttribute("data-theme-mode")) {
        themeMode = document.documentElement.getAttribute("data-theme-mode");
      } else {
        if (type) {
          themeMode = type;
        } else {
          themeMode = defaultThemeMode;
        }
      }
      if (themeMode === "system") {
        themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light";
      }
      document.documentElement.setAttribute("data-bs-theme", themeMode);
    }
  };

  useEffect(() => {
    if (nameObject[history.location.pathname]) {
      setCurrent(nameObject[history.location.pathname]);
    } else {
      if (
        Object.keys(nameObject).findIndex((d) =>
          d.includes("/" + history.location.pathname.split("/")[1])
        ) !== -1
      ) {
        return setCurrent(
          nameObject["/" + history.location.pathname.split("/")[1]]
        );
      }
      setCurrent({ name: "" });
    }
  }, [history?.location]);

  const items = [
    {
      key: "1",
      label: (
        <div className="menu-item px-3" onClick={() => setThemeValue("light")}>
          <a
            className={`menu-link p-0 ${themes === "light" ? "active" : ""}`}
            data-kt-element="mode"
            data-kt-value="light"
          >
            <span className="menu-icon" data-kt-element="icon">
              <i className="ki-duotone ki-night-day fs-2">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
              </i>
            </span>
            <span className="menu-title">Light</span>
          </a>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="menu-item px-3" onClick={() => setThemeValue("dark")}>
          <a
            className={`menu-link p-0 ${themes === "dark" ? "active" : ""}`}
            data-kt-element="mode"
            data-kt-value="dark"
          >
            <span className="menu-icon" data-kt-element="icon">
              <i className="ki-duotone ki-moon fs-2">
                <span className="path1" />
                <span className="path2" />
              </i>{" "}
            </span>
            <span className="menu-title">Dark</span>
          </a>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="menu-item px-3" onClick={() => setThemeValue("system")}>
          <a
            className={`menu-link p-0 ${themes === "system" ? "active" : ""}`}
            data-kt-element="mode"
            data-kt-value="system"
          >
            <span className="menu-icon" data-kt-element="icon">
              <i className="ki-duotone ki-screen fs-2">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
              </i>{" "}
            </span>
            <span className="menu-title">System</span>
          </a>
        </div>
      ),
    },
  ];

  const BackToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );

      localStorage.setItem("token", adminDataLocal.token);
      contextValue?.updateCommonGlobalVal(
        "profile",
        JSON.parse(adminDataLocal.data)
      );
      localStorage.removeItem("adminData");
      history.replace("/manage-seller-users");
    }
  };

  document.title = current?.name || "Vista";

  return (
    <Wrapper>
      <div
        id="kt_app_header"
        className="app-header"
        data-kt-sticky="true"
        data-kt-sticky-activate="{default: false, lg: true}"
        data-kt-sticky-name="app-header-sticky"
        data-kt-sticky-offset="{default: false, lg: '300px'}"
        style={{
          position: "sticky",
          zIndex: "100",
          top: "0",
          left: "0",
          right: "0",
        }}
      >
        <div
          className="app-container container-fluid d-flex flex-stack"
          id="kt_app_header_container"
        >
          <div
            className="d-flex align-items-center d-block d-lg-none ms-n3"
            title="Show sidebar menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px me-2"
              id="kt_app_sidebar_mobile_toggle"
            >
              <i className="ki-outline ki-abstract-14 fs-2" />
            </div>
            <a href="index.html">
              <img
                alt="Logo"
                src="/assets/media/vfads-icon.png"
                className="h-30px theme-light-show"
              />
              <img
                alt="Logo"
                src="/assets/media/vfads-icon.png"
                className="h-30px theme-dark-show"
              />
            </a>
          </div>
          <div
            className="d-flex flex-stack flex-lg-row-fluid"
            id="kt_app_header_wrapper"
          >
            <div
              className="page-title gap-4 me-3 mb-5 mb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
              data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_wrapper'}"
            >
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-2">
                <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                  <Link
                    to="/"
                    className="text-gray-700 text-hover-primary me-1"
                  >
                    <i className="ki-outline ki-home text-gray-200 fs-6" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <i className="ki-outline ki-right fs-7 text-gray-300 mx-n1" />
                </li>
                {current?.parent && (
                  <>
                    <li className="breadcrumb-item text-muted">
                      <a className="breadcrumb-item text-gray-200 fw-bold lh-1">
                        {current?.parent || ""}
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="ki-outline ki-right fs-7 text-gray-300 mx-n1" />
                    </li>
                  </>
                )}
                <li className="breadcrumb-item text-gray-200 fw-bold lh-1">
                  {current?.name || ""}
                </li>
                {/* <li className="breadcrumb-item text-gray-200 fw-bold lh-1">Dashboard</li>
                                <li className="breadcrumb-item">
                                    <i className="ki-outline ki-right fs-7 text-gray-300 mx-n1" />
                                </li> */}
                {/* <li className="breadcrumb-item text-gray-200">Overview</li> */}
              </ul>
              <h1 className="text-gray-100 fw-bolder m-0">
                {current?.name || ""}
              </h1>
            </div>
            <div className="d-none">
              <div className="app-navbar flex-shrink-0">
                {parseInt(user_type) === 2 && back && (
                  <Button
                    onClick={() => {
                      BackToAdmin();
                    }}
                    type="dashed"
                  >
                    Back to Admin
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              {parseInt(user_type) !== 1 && back && (
                <button
                  onClick={() => {
                    BackToAdmin();
                  }}
                  className="btn btn-dark fs-7 fw-bold"
                >
                  Back to Admin
                </button>
              )}
              <div
                style={{
                  padding: "30px 15px",
                  display: "flex",
                  justifyItems: "center",
                  alignContent: "space-evenly",
                  transition: "width 0.3s, 0.3s",
                  animation: ".4s linear",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "0px",
                  }}
                >
                  {1 === 1 ? (
                    <div className="profileText">
                      {profile?.name?.charAt(0)}
                    </div>
                  ) : (
                    <>
                      <div className="profileStatus" />
                    </>
                  )}
                </div>
                <div className="text-left ">
                  <span
                    style={{ color: "#FFF", fontWeight: 800 }}
                    className=" fs-4 user_name_title"
                  >
                    {profile?.name}
                  </span>
                  <span
                    className="profileMail  fw-bold d-block fs-7 mb-1 fw-normal"
                    style={{ color: "#f0f0f0" }}
                  >
                    {profile?.email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Header;
