import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SystemLog from "../../modules/pages/application-logs/system-log/SystemLog";
import {
  ApplicationLogsSystemLogAction,
  fakeActionUserSystemLog,
} from "../../redux/modules/application-log/system-log/index.action";

const mapStateToProps = (state) => ({
  GetApplicationLogSystemResponse:
    state?.SystemLog.GetApplicationLogSystemResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ApplicationLogsSystemLogAction,
      fakeActionUserSystemLog,
    },
    dispatch
  );

const SystemLog_ = connect(mapStateToProps, mapDispatchToProps)(SystemLog);

export default SystemLog_;
