import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CentralLog from "../../modules/pages/application-logs/central-log/CentralLog";
import {
  ApplicationLogsCentralLogAction,
  LogsFilterDataAction,
  fakeActionUserCentralLog,
} from "../../redux/modules/application-log/central-log/index.action";

const mapStateToProps = (state) => ({
  GetApplicationLogCentralResponse:
    state?.CentralLog.GetApplicationLogCentralResponse,
  GetApplicationLogCentralFiltersResponse:
    state?.CentralLog.GetApplicationLogCentralFiltersResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ApplicationLogsCentralLogAction,
      LogsFilterDataAction,
      fakeActionUserCentralLog,
    },
    dispatch
  );

const CentralLog_ = connect(mapStateToProps, mapDispatchToProps)(CentralLog);

export default CentralLog_;
