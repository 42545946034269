import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MarketplaceCredentials from "../../modules/pages/settings/marketplace-credentials";
import {
  getUserCredentialsAction,
  fakeActionMarketplace,
  GetMarketplaceListAction,
  SaveAdProfileAction,
  UserSPCredentialsCallbackAction,
  UserCredentialsSPAction,
  UserDeleteCredentialsSPAction,
  GenerateRefreshTokenAction,
} from "../../redux/modules/marketplace-credentials/index.action";

const mapStateToProps = (state) => ({
  UserCredentialsListResponse:
    state.MarketplaceCredential.UserCredentialsListResponse,
  GenerateRefreshTokenResponse:
    state.MarketplaceCredential.GenerateRefreshTokenResponse,
  SaveAdProfileResponse: state.MarketplaceCredential.SaveAdProfileResponse,
  GetMarketplaceListResponse:
    state.MarketplaceCredential.GetMarketplaceListResponse,
  UserCredentialsSPResponse:
    state.MarketplaceCredential.UserCredentialsSPResponse,
  UserDeleteCredentialsSPResponse:
    state.MarketplaceCredential.UserDeleteCredentialsSPResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserCredentialsAction,
      fakeActionMarketplace,
      SaveAdProfileAction,
      UserSPCredentialsCallbackAction,
      GetMarketplaceListAction,
      UserDeleteCredentialsSPAction,
      UserCredentialsSPAction,
      GenerateRefreshTokenAction,
    },
    dispatch
  );

const MarketplaceCredentials_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceCredentials);

export default MarketplaceCredentials_;
