import styled from "styled-components";

const Wrapper = styled.div`
  .over2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 55px;
  }
`;

export default Wrapper;
