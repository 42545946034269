import { InputNumber, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import Wrapper from "../style";
import { useSelector } from "react-redux";

const AutoBid = (props) => {
  const {
    basicDetails,
    groupIndex,
    setBasicDetails,

    fakeActionCampaignsManagementAnalytics,
  } = props;
  const [selectedBidType, setSelectedBidType] = useState("Suggested Bid");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const GetBidSuggestionResponse = useSelector(
    (state) => state.CampaignManagement.GetBidSuggestionResponse || {}
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateProductTargeting = () => {
    const makeData = [];
    data?.forEach((v) => {
      const index = selectedRowKeys?.findIndex(
        (w) => w?.match_type === v?.match_type
      );
      makeData?.push({
        match_type: v?.match_type,
        bid: v?.bid,
        state: index !== -1 ? "ENABLED" : "PAUSED",
      });
    });

    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].product_targeting = makeData;
    setBasicDetails(updatedData);
  };

  useEffect(() => {
    updateProductTargeting();
  }, [selectedRowKeys]);

  const updateSuggestedBidByType = (targetingType, newSuggestedBid) => {
    setData((prev) => {
      return prev?.map((d) => ({
        ...d,
        bid: d?.match_type === targetingType ? newSuggestedBid : d?.bid,
      }));
    });
    setSelectedRowKeys((prev) => {
      return prev?.map((d) => ({
        ...d,
        bid: d?.match_type === targetingType ? newSuggestedBid : d?.bid,
      }));
    });
  };

  const columns = [
    {
      title: "Targeting Group",
      dataIndex: "match_type",

      key: "match_type",
    },
    {
      title: "Set Bid",
      dataIndex: "bid",
      align: "center",
      key: "bid",
      render: (text, record) => (
        <InputNumber
          disabled={selectedBidType === "Suggested Bid"}
          value={text}
          onChange={(e) => {
            updateSuggestedBidByType(record?.match_type, e);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (GetBidSuggestionResponse?.status === true) {
      const makeData =
        GetBidSuggestionResponse?.data?.bids_by_targeting_group?.map((d) => ({
          match_type: d?.targeting_expression_type
            ?.split(" ")
            ?.join("_")
            ?.toUpperCase(),
          bid: d?.suggested_bid,
          state: "ENABLED",
        }));
      setData(makeData);
      setSelectedRowKeys(makeData);
      setLoading(false);
      message.destroy();
      fakeActionCampaignsManagementAnalytics("GetBidSuggestionResponse");
    } else if (GetBidSuggestionResponse?.status === false) {
      setLoading(false);
      setData([]);
      message.destroy();
      fakeActionCampaignsManagementAnalytics("GetBidSuggestionResponse");
    }
  }, [GetBidSuggestionResponse]);

  const onSelectChange = (newSelectedRowKeys, row) => {
    setSelectedRowKeys(row);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys

      ?.filter((d) => d?.state === "ENABLED")
      ?.map((d) => d?.match_type),
    onChange: onSelectChange,
  };

  return (
    <Wrapper className="campaign-specifications mt-3 px-10">
      <div className="row">
        <b>Set default bid</b>
        <div className="d-grid mt-5">
          <span>Default Bid</span>
          <Select
            onChange={(e) => setSelectedBidType(e)}
            value={selectedBidType}
            options={["Suggested Bid", "Custom Bid"]?.map((d) => {
              return { label: d, value: d };
            })}
            placeholder="Select Bid"
            style={{ width: "250px" }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <span>Set bids by targeting group</span>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          rowSelection={rowSelection}
          rowKey="match_type"
        />
      </div>
    </Wrapper>
  );
};

export default AutoBid;
