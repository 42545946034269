import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { DefaultPerPage, timeSince } from "../../../../config";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import { Input, Select, Table, Tag } from "antd";
import NoData from "../../../../components/no-data";
import Pagination from "../../../../components/pagination";
import ASINTooltip from "../../../../components/tooltip";
import moment from "moment";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../commonContext";
function capitalizeFirstLetter(str) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

function CentralLog(props) {
  const {
    ApplicationLogsCentralLogAction,
    LogsFilterDataAction,
    fakeActionUserCentralLog,
  } = props;
  const contextVar = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [data, setData] = useState([]);

  const [filtersData, setFiltersData] = useState({});
  const [filterLoading, setFilterLoading] = useState(true);

  const [filters, setFilters] = useState({
    event_name: null,
    event_type: null,
    event_status: null,
    marketplace: null,
    search_text: null,
  });

  const GetApplicationLogCentralResponse = useSelector(
    (state) => state.CentralLog.GetApplicationLogCentralResponse || {}
  );

  const GetApplicationLogCentralFiltersResponse = useSelector(
    (state) => state.CentralLog.GetApplicationLogCentralFiltersResponse || {}
  );

  useEffect(() => {
    if (GetApplicationLogCentralFiltersResponse?.status === true) {
      setFiltersData(GetApplicationLogCentralFiltersResponse?.data);
      setFilterLoading(false);
      fakeActionUserCentralLog("GetApplicationLogCentralFiltersResponse");
    } else if (GetApplicationLogCentralFiltersResponse?.status === false) {
      setFiltersData({});
      setFilterLoading(false);
      fakeActionUserCentralLog("GetApplicationLogCentralFiltersResponse");
    }
  }, [GetApplicationLogCentralFiltersResponse]);

  useEffect(() => {
    if (GetApplicationLogCentralResponse.status) {
      setLoading(false);
      setData(GetApplicationLogCentralResponse.data.records);
      setTotalPage(GetApplicationLogCentralResponse.data.pagination.totalCount);
      fakeActionUserCentralLog("GetApplicationLogCentralResponse");
    } else if (GetApplicationLogCentralResponse.status === false) {
      setLoading(false);
      fakeActionUserCentralLog("GetApplicationLogCentralResponse");
    }
  }, [GetApplicationLogCentralResponse]);
  useEffect(() => {
    setFilterLoading(true);
    LogsFilterDataAction();
  }, []);

  useEffect(() => {
    setLoading(true);
    ApplicationLogsCentralLogAction({
      page: page,
      perPage: pageSize,
      ...filters,
    });

    return () => {};
  }, [filters]);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    ApplicationLogsCentralLogAction({
      page: e,
      perPage: pageSize,
      ...filters,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    ApplicationLogsCentralLogAction({
      page: 1,
      perPage: e,
      ...filters,
    });
  };
  const checkDateValidation = (date) => {
    if (moment(date).isValid()) {
      return date;
    }
    return false;
  };

  const timeSince_ = (date, item) => {
    const units = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    const seconds = Math.floor(
      (new Date(
        moment(new Date())
          .tz(
            contextVar?.data?.timezone?.[item?.marketplace_id] || "US/Central"
          )
          .format("MMM DD, YYYY hh:mm A")
      ) -
        date * 1000) /
        1000
    );

    for (let i = 0; i < units.length; i++) {
      const interval = seconds / units[i].seconds;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          units[i].label +
          (interval > 1 ? "s" : "") +
          " ago"
        );
      }
    }

    return "just now";
  };

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log created",
      width: 250,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz(
                  contextVar?.data?.timezone?.[item?.marketplace_id] ||
                    "US/Central"
                )
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              (
              {timeSince_(
                Date.parse(
                  moment(new Date(item.created_at * 1000))
                    .tz(
                      contextVar?.data?.timezone?.[item?.marketplace_id] ||
                        "US/Central"
                    )
                    .format("MMM DD, YYYY hh:mm A")
                ) / 1000,
                item
              )}
              )
            </span>{" "}
            -{" "}
            <Tag color="geekblue">
              {contextVar?.data?.timezone?.[item?.marketplace_id]}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      width: 200,
      render: (item) => {
        return item?.event_date || "-";
      },
    },
    {
      title: "Event Name",
      width: 180,
      // ellipsis: false,
      render: (item) => {
        return (
          <ASINTooltip rule>
            <b>{item?.event_name || "N/A"}</b>
          </ASINTooltip>
        );
      },
    },
    {
      title: "Event Type",
      width: 150,
      render: (item) => {
        return <b>{item?.event_type || "N/A"}</b>;
      },
    },
    {
      title: "Event Status",
      width: 150,
      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "RULE_APPLIED"
                ? "success"
                : item.event_status === "RULE_NOT_APPLIED"
                ? "warning"
                : item.event_status === "SCHEDULED"
                ? "blue"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
            icon={
              item.event_status === "PENDING" ? (
                <ClockCircleOutlined />
              ) : item.event_status === "IN_PROGRESS" ? (
                <SyncOutlined spin />
              ) : item.event_status === "DONE" ||
                item.event_status === "RULE_APPLIED" ||
                item.event_status === "SCHEDULED" ? (
                <CheckCircleOutlined />
              ) : item.event_status === "FAILED" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "FATAL" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "CANCELLED" ? (
                <CloseCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",
      width: 120,
      render: (item) => {
        return (
          <>
            {item?.priority == 1 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#ff0000" }}
                ></div>
                High
              </div>
            ) : item?.priority == 2 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#faad14" }}
                ></div>
                Medium
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#52c41a" }}
                ></div>
                Low
              </div>
            )}
          </>
          // <>
          //   {item?.priority == 1 ? (
          //     <Tag color="error">High</Tag>
          //   ) : item?.priority == 2 ? (
          //     <Tag color="warning">Medium</Tag>
          //   ) : (
          //     <Tag color="success">Low</Tag>
          //   )}
          // </>
        );
      },
    },
    {
      title: "Profile ID",
      width: 200,
      render: (item) => {
        return <b>{item?.profile_id || "N/A"}</b>;
      },
    },
    {
      title: "Marketplace",
      width: 200,
      render: (item) => {
        return <b>{item?.marketplace || "N/A"}</b>;
      },
    },
    {
      title: "Updated at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz(
                  contextVar?.data?.timezone?.[item?.marketplace_id] ||
                    "US/Central"
                )
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              {timeSince_(
                Date.parse(
                  moment(new Date(item.updated_at * 1000))
                    .tz(
                      contextVar?.data?.timezone?.[item?.marketplace_id] ||
                        "US/Central"
                    )
                    .format("MMM DD, YYYY hh:mm A")
                ) / 1000,
                item
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      width: 200,
      render: (d) => {
        return (
          <Tag
            color={d.status === 0 ? "processing" : "success"}
            icon={
              (d.status === 0) === "processing" ? (
                <ClockCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            }
          >
            {d.status === 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed mt-3">
                    <div className="d-flex flex-stack flex-wrap gap-4">
                      <div className="position-relative">
                        <Input
                          placeholder="Search..."
                          className="w-200px"
                          size="large"
                          onPressEnter={(e) =>
                            setFilters({
                              ...filters,
                              search_text: e.target.value,
                            })
                          }
                          defaultValue={filters?.search_text}
                        />
                      </div>
                      <div className="position-relative">
                        <Select
                          placeholder="Select Marketplace"
                          className="w-200px"
                          size="large"
                          loading={filterLoading}
                          onChange={(e) =>
                            setFilters({ ...filters, marketplace: e })
                          }
                          value={filters?.marketplace}
                          allowClear
                          options={Object?.values(
                            filtersData?.marketplace_list || {}
                          )?.map((d) => {
                            return {
                              label: capitalizeFirstLetter(
                                d?.toLowerCase()?.split("_")?.join(" ")
                              ),
                              value: d,
                            };
                          })}
                        />
                      </div>
                      <div className="position-relative">
                        <Select
                          placeholder="Select Event Name"
                          className="w-200px"
                          size="large"
                          onChange={(e) =>
                            setFilters({ ...filters, event_name: e })
                          }
                          value={filters?.event_name}
                          loading={filterLoading}
                          allowClear
                          options={Object?.values(
                            filtersData?.event_name_list || {}
                          )?.map((d) => {
                            return {
                              label: capitalizeFirstLetter(
                                d?.split("_")?.join(" ")?.toLowerCase()
                              ),
                              value: d,
                            };
                          })}
                        />
                      </div>
                      <div className="position-relative">
                        <Select
                          placeholder="Select Event Type"
                          className="w-200px"
                          size="large"
                          loading={filterLoading}
                          allowClear
                          onChange={(e) =>
                            setFilters({ ...filters, event_type: e })
                          }
                          value={filters?.event_type}
                          options={Object?.values(
                            filtersData?.event_type_list || {}
                          )?.map((d) => {
                            return {
                              label: capitalizeFirstLetter(
                                d?.toLowerCase()?.split("_")?.join(" ")
                              ),
                              value: d,
                            };
                          })}
                        />
                      </div>
                      <div className="position-relative">
                        <Select
                          placeholder="Select Event Status"
                          className="w-200px"
                          size="large"
                          loading={filterLoading}
                          onChange={(e) =>
                            setFilters({ ...filters, event_status: e })
                          }
                          value={filters?.event_status}
                          allowClear
                          options={Object?.values(
                            filtersData?.event_status_list || {}
                          )?.map((d) => {
                            return {
                              label: capitalizeFirstLetter(
                                d?.toLowerCase()?.split("_")?.join(" ")
                              ),
                              value: d,
                            };
                          })}
                        />
                      </div>
                    </div>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      {loading ? (
                        <Loading />
                      ) : data?.length != 0 ? (
                        <Table
                          columns={columns}
                          dataSource={data}
                          rowKey="key"
                          loading={loading}
                          pagination={false}
                        />
                      ) : (
                        <NoData />
                      )}
                    </div>
                    <Pagination
                      loading={loading || data?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CentralLog;
