import { styled } from "styled-components";

const Wrapper = styled.div`
  .ant-breadcrumb {
    li:first-child {
      a {
        color: var(--bs-text-gray-200) !important;
        font-weight: 600 !important;
        font-size: 0.95rem !important;
      }
    }
    li {
      .ant-breadcrumb-link {
        color: var(--bs-text-gray-200);
        font-weight: 500 !important;
        font-size: 0.95rem !important;
      }
    }
  }
  .profileText {
    background: linear-gradient(45deg, #ffffff75, #0597d9);
    width: 50px;
    height: 50px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-shadow: 2px 2px 9px rgb(97 187 237);
    font-weight: 700;
    color: #fff;
    margin-right: 10px;
  }
`;

export default Wrapper;
