import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Popconfirm, Table } from "antd";
import { TableLoading } from "../../../../../../components/table-animation";
import Icons from "../../../../../../components/icons";
import UpdateAdGroup from "./edit-adGroup";

const AdGroup = (props) => {
  const { GetAdGroupListAction, fakeActionCampaignsManagementAnalytics } =
    props;
  const location = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const GetAdGroupListResponse = useSelector(
    (state) => state.CampaignManagement.GetAdGroupListResponse || {}
  );

  const columns = [
    {
      title: "Ad group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
    },
    {
      title: "Ad group ID",
      dataIndex: "ad_group_id",
      key: "adGroupID",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Targeting Type",
      dataIndex: "targeting_type",
      key: "targeting_type",
    },
    {
      title: "Default Bid",
      dataIndex: "default_bid",
      key: "default_bid",
    },
    {
      title: "Action",

      render: (text) => {
        return (
          <div className="d-flex">
            <div
              className="Edit-Icon-Antd d-flex"
              onClick={() => {
                setSelectedRow(text);
                setEditModal(true);
              }}
            >
              <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3">
                <i className="ki-outline ki-pencil fs-2 text-primary" />
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (GetAdGroupListResponse?.status === true) {
      setList(GetAdGroupListResponse?.data?.records || []);
      setLoading(false);
      fakeActionCampaignsManagementAnalytics("GetAdGroupListResponse");
    } else if (GetAdGroupListResponse?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionCampaignsManagementAnalytics("GetAdGroupListResponse");
    }
  }, [GetAdGroupListResponse]);

  useEffect(() => {
    if (location?.search?.split("=")?.[1]) {
      setLoading(true);
      setList([]);
      GetAdGroupListAction(location?.search?.split("=")?.[1]);
    }
    return () => {};
  }, []);

  const urlText = location?.search?.split("=")?.[2];

  // Decoding the URL
  const decodedText = decodeURIComponent(urlText);

  // Making adjustments (for instance, replacing the hyphen with an underscore)
  const adjustedText = decodedText.replace("-", "_");

  console.log(adjustedText);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <Link
              to="/campaign-management"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
            >
              <Icons type="left-arrow" />
            </Link>
            &nbsp;&nbsp;<b>{adjustedText}</b>
          </div>
          <div className="card-toolbar"></div>
        </div>
        <div className="card-body  ">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <Table
              dataSource={list}
              pagination={false}
              scroll={{ x: "max-content" }}
              columns={columns}
            />
          )}
        </div>
      </div>
      <UpdateAdGroup
        show={editModal}
        onClose={() => setEditModal(false)}
        data={selectedRow}
        getTable={() => {
          if (location?.search?.split("=")?.[1]) {
            setLoading(true);
            setList([]);
            GetAdGroupListAction(location?.search?.split("=")?.[1]);
          }
        }}
        {...props}
      />
    </>
  );
};

export default AdGroup;
